import React, { createContext, useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

// Create context
export const SubscriptionContext = createContext();

export function SubscriptionProvider({ children, subscriptionId }) {
  const [subscription, setSubscription] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const api = useAxiosPrivate();

  useEffect(() => {
    if (subscriptionId) {
      api
        .get(`/public/subscriptions/${subscriptionId}`)
        .then((response) => {
          setSubscription(response.data);
          setError(null);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              setError("Subscription not found");
            } else if (err.response.status === 401) {
              setError("Unauthorized access");
            } else {
              setError("An unexpected error occurred");
            }
          } else {
            setError("An unexpected error occurred");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [subscriptionId]);

  const debouncedUpdateServer = useDebouncedCallback((updatedSubscription) => {
    api.put(`/public/subscriptions/${subscriptionId}/config`, updatedSubscription.subConfig).catch((err) => {
      console.error("Failed to update subscription:", err);
    });
  }, 1250);

  const updateSubConfig = (newConfig) => {
    const updatedSubscription = { ...subscription, subConfig: { ...subscription.subConfig, ...newConfig } };

    setSubscription((prevSubscription) => ({
      ...prevSubscription,
      subConfig: updatedSubscription.subConfig,
    }));

    debouncedUpdateServer(updatedSubscription);
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        updateSubConfig,
        error,
        loading,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}

import classNames from "classnames";
import { format } from "date-fns";
import { Bitcoin, CreditCard, Wallet } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { buttonVariants } from "../../../../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../../../components/ui/card";
import LoadingState from "../../../../components/ui/loading-state";
import Separator from "../../../../components/ui/separator";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

function AdminInvoiceDetailPageView({ data }) {
  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-3xl font-bold tracking-tight">Invoice details</h2>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>{data.title}</CardTitle>
        </CardHeader>
        <CardContent className="space-y-3">
          <div className="text-lg font-medium">Summary</div>
          <Separator />
          <div className="space-y-3">
            <div className="flex flex-wrap items-center gap-1">
              <div className="min-w-[160px]">
                <span>User</span>
              </div>
              <div className="flex-1">
                <span className="text-muted-foreground">{data.user.username}</span>
              </div>
            </div>
            <div className="flex flex-wrap items-start gap-1">
              <div className="min-w-[160px]">
                <span>Type</span>
              </div>
              <div className="flex-1">
                <span className="text-muted-foreground">
                  {data.topup && "Balance top-up"}
                  {data.renew && "Subscription renew"}
                  {!data.topup && !data.renew && "Subscription purchase"}
                </span>{" "}
                {data.subscription && (
                  <div>
                    <Link
                      to={`/subscriptions/${data.subscription._id}`}
                      classNames={classNames(buttonVariants({ variant: "link" }), "p-0")}
                    >
                      View subscription
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-wrap items-center gap-1">
              <div className="min-w-[160px]">
                <span>Gateway</span>
              </div>
              <div className="flex-1">
                <div className="text-muted-foreground">
                  {data.paymentProcessor === "cryptocurrency" && (
                    <span className="inline-flex items-center">
                      <Bitcoin className="w-3 h-3 mr-1" />
                      Cryptocurrency
                    </span>
                  )}
                  {data.paymentProcessor === "balance" && (
                    <span className="inline-flex items-center">
                      <Wallet className="w-3 h-3 mr-1" />
                      Balance
                    </span>
                  )}
                  {data.paymentProcessor === "card" && (
                    <span className="inline-flex items-center">
                      <CreditCard className="w-3 h-3 mr-1" />
                      Card
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center gap-1">
              <div className="min-w-[160px]">
                <span>Created at</span>
              </div>
              <div className="flex-1">
                <span className="text-muted-foreground">{format(new Date(data.createdAt), "pppp")}</span>
              </div>
            </div>
            {data.paid && (
              <div className="flex flex-wrap items-center gap-1">
                <div className="min-w-[160px]">
                  <span>Paid at</span>
                </div>
                <div className="flex-1">
                  <span className="text-muted-foreground">{format(new Date(data.paidDate), "pppp")}</span>
                </div>
              </div>
            )}
            {data.campaign && (
              <div className="flex flex-wrap items-center gap-1">
                <div className="min-w-[160px]">
                  <span>Campaign</span>
                </div>
                <div className="flex-1">
                  <Link
                    to={`/campaigns/${data.campaign._id}`}
                    className={classNames(buttonVariants({ variant: "link" }), "px-0")}
                  >
                    {data.campaign.name}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

function AdminInvoiceDetailPage() {
  const { invoiceId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState();

  const api = useAxiosPrivate();

  const fetchData = () => {
    api
      .get(`/admin/invoices/${invoiceId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? <LoadingState /> : data ? <AdminInvoiceDetailPageView data={data} /> : <div>Error</div>;
}

export default AdminInvoiceDetailPage;

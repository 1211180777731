import clsx from "clsx";
import { formatDistanceStrict } from "date-fns";
import { twMerge } from "tailwind-merge";

export const generateCheckoutReference = () => {
  // Example using a UUID or you can use your own logic
  return `checkout_${Math.random().toString(36).slice(2, 9)}`;
};

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

export function calculateDiscountPercentage(originalPrice, discountedPrice) {
  if (originalPrice <= 0 || discountedPrice < 0) {
    throw new Error("Prices must be positive values.");
  }

  if (discountedPrice < 1) {
    discountedPrice *= 100;
    originalPrice *= 100;
  }

  const discountAmount = originalPrice - discountedPrice;
  const discountPercentage = (discountAmount / originalPrice) * 100;

  return discountPercentage;
}

export function bytesToGB(bytes) {
  if (typeof bytes !== "number" || Number.isNaN(bytes)) {
    return "Unknown";
  }
  const gigabytes = bytes / (1024 * 1024 * 1024);
  return gigabytes.toFixed(2);
}

export function capitalize(str) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes <= 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const isNumber = (v) => typeof v === "number" || (typeof v === "string" && Number.isFinite(+v));

export const secondsToDays = (seconds) => {
  if (!isNumber(seconds) || !Number.isInteger(seconds)) {
    throw new TypeError("Input must be an integer representing seconds");
  }

  const milliseconds = seconds * 1000;

  return formatDistanceStrict(0, milliseconds, { unit: "day" });
};
export const generateProductName = (category, type) => {
  if (typeof category !== "string" || typeof type !== "string") {
    throw new TypeError("Both category and type must be strings");
  }

  const categoryMap = {
    gb: "ROTATING",
    ip: "STATIC",
  };

  const formattedCategory = categoryMap[category.toLowerCase()];
  if (!formattedCategory) {
    throw new Error("Invalid category");
  }

  let formattedType = type.toUpperCase();
  const productBase = "Proxies (IPv4)";

  // HARDCODE
  if (formattedType === "STATIC") {
    formattedType = "ISP";
  }
  return `${formattedCategory} ${formattedType} ${productBase}`;
};

import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { addDays } from "date-fns";
import Icon from "../../../ui/icon";
import { buttonVariants } from "../../../ui/button";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "../../../ui/use-toast";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "../../../ui/dialog";
import LoadingState from "../../../ui/loading-state";
import Label from "../../../ui/label";
import Input from "../../../ui/input";
import StyledDatepicker from "../../../ui/datepicker";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/select";
import CheckBox from "../../../ui/checkbox";

function AdminExtendSubscriptionModal({ open, onOpenChange, data, callbackFn }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [plans, setPlans] = useState([]);

  const { watch, control, register, handleSubmit } = useForm({
    defaultValues: {
      renewalDate: addDays(new Date(), 30),
      createInvoice: false,
    },
  });

  const api = useAxiosPrivate();

  const onSubmit = (formData) => {
    setIsLoading(true);
    api
      .post(`/admin/subscriptions/${data._id}/extend`, formData)
      .then(() => {
        toast({
          title: "Subscription added successfully.",
          variant: "positive",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Could not edit subscription.",
          description: err.response?.data.message || "",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
        onOpenChange(false);
        callbackFn();
      });
  };

  useEffect(() => {
    const fetchPlansData = () => {
      setIsLoadingPlans(true);
      api
        .get("/admin/plans")
        .then((res) => {
          setPlans(res.data.payload.plans);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoadingPlans(false);
        });
    };

    fetchPlansData();
  }, []);

  const createInvoice = watch("createInvoice", false);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogTitle>
          Extend subscription <span className="text-primary">{data.authUser}</span>
        </DialogTitle>
        <DialogDescription>
          {isLoadingPlans ? (
            <div className="grid place-items-center py-32">
              <LoadingState />
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div className="space-y-2">
                  <Label>Bandwidth</Label>
                  <Input placeholder="Empty for plan default" {...register("bandwidthBytes")} />
                </div>
                <div className="space-y-2">
                  <Label>Renewal Date</Label>
                  <Controller
                    control={control}
                    name="renewalDate"
                    render={({ field }) => <StyledDatepicker mode="single" onValueChange={field.onChange} {...field} />}
                  />
                </div>
                <div className="space-y-2">
                  <Label>Plan (opt.)</Label>
                  <Controller
                    control={control}
                    name="plan"
                    render={({ field }) => (
                      <Select disabled={isLoadingPlans} onValueChange={field.onChange} {...field}>
                        <SelectTrigger>
                          <SelectValue placeholder="None" />
                        </SelectTrigger>
                        <SelectContent>
                          {plans
                            .filter((item) => item.type === data.plan.type)
                            .map((plan) => (
                              <SelectItem key={plan._id} value={plan._id}>
                                {plan.title} {plan.bandwidth}
                                GB
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <Label>Invoice amount</Label>
                  <Input
                    type="number"
                    placeholder={createInvoice && "Empty for default price"}
                    {...register("customInvoiceAmount")}
                  />
                </div>
                {createInvoice && (
                  <div className="space-y-2 animate-in fade-in">
                    <Label>Payment method</Label>
                    <Controller
                      control={control}
                      name="invoicePaymentProcessor"
                      render={({ field }) => (
                        <Select disabled={isLoadingPlans} onValueChange={field.onChange} {...field}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select one" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="cryptocurrency">Crypto</SelectItem>
                            <SelectItem value="card">Card</SelectItem>
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <div className="flex items-center gap-1.5">
                    <Controller
                      control={control}
                      name="createInvoice"
                      render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                    />
                    Bill with an invoice
                  </div>
                </div>
                <div className="flex items-center justify-end gap-3">
                  <button
                    type="button"
                    className={buttonVariants({ variant: "outline" })}
                    onClick={(event) => {
                      onOpenChange(false);
                      event.preventDefault();
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={buttonVariants()}>
                    {isLoading && <Icon name="Loader" className="mr-2 h-4 w-4 animate-spin" />}
                    Extend
                  </button>
                </div>
              </div>
            </form>
          )}
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

export default AdminExtendSubscriptionModal;

import { useEffect, useState } from "react";
import DataAvailableCard from "../../data-available-card";
import DashboardHeader from "./dashboard-header";
import BalanceCard from "./balance-card";
import AttentionRequiredAccordion from "./attention-required-accordion";
import SubscriptionsFilter from "./subscriptions-filter";
import SubscriptionsList from "./subscriptions-list";

function UserDashboardView({ data }) {
  const [subscriptionsFilter, setSubscriptionsFilter] = useState("active");
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);

  const attentionRequiredSubs = [...data.expiringSoon, ...data.recentlyExpired];

  useEffect(() => {
    if (subscriptionsFilter === "active") {
      setFilteredSubscriptions(data.activeSubscriptions);
    } else if (subscriptionsFilter === "expired") {
      setFilteredSubscriptions(data.expiredSubscriptions);
    } else {
      setFilteredSubscriptions([...data.activeSubscriptions, ...data.expiredSubscriptions]);
    }
  }, [subscriptionsFilter]);

  return (
    <div className="space-y-3">
      <DashboardHeader />
      <div className="grid lg:grid-cols-2 gap-3">
        <BalanceCard />
        {!!data.userStatistics?.totalGBActive && (
          <DataAvailableCard remaining={data.userStatistics.totalGBLeft} total={data.userStatistics.totalGBActive} />
        )}
      </div>
      <div>
        {attentionRequiredSubs.length ? (
          <AttentionRequiredAccordion attentionRequiredSubs={attentionRequiredSubs} />
        ) : null}
        <SubscriptionsFilter
          subscriptionsFilter={subscriptionsFilter}
          setSubscriptionsFilter={setSubscriptionsFilter}
        />
        <SubscriptionsList
          filteredSubscriptions={filteredSubscriptions}
          activeSubscriptions={data.activeSubscriptions}
          expiredSubscriptions={data.expiredSubscriptions}
        />
      </div>
    </div>
  );
}

export default UserDashboardView;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import Header from "../components/header";
import Sidebar from "../components/sidebar";
import { ThemeProvider } from "../components/theme-provider";
import Toaster from "../components/ui/toaster";
import { TooltipProvider } from "../components/ui/tooltip";
import menuConfig from "../config/navigation";
import { update } from "@intercom/messenger-js-sdk";

function AdminDashboardLayout() {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    update({
      hide_default_launcher: false,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>thunderproxy - Dashboard</title>
      </Helmet>
      <ThemeProvider>
        <div className="h-screen">
          <Header menuConfig={menuConfig.admin} />
          <div className="lg:flex">
            <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col bg-popover">
              <Sidebar menuConfig={menuConfig.admin} />
            </div>
            <div className="flex flex-col flex-1 h-screen lg:pl-64 pt-[63px]">
              <div>
                <TooltipProvider delayDuration={50}>
                  <div className="lg:max-w-7xl mx-auto px-4 py-6 space-y-6">
                    <Outlet />
                  </div>
                </TooltipProvider>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
      <Toaster />
    </>
  );
}
export default AdminDashboardLayout;

import { show, showArticle } from "@intercom/messenger-js-sdk";
import classNames from "classnames";
import { format } from "date-fns";
import { Link, LoaderIcon, SparklesIcon, UserIcon } from "lucide-react";
import { useContext, useState } from "react";
import { Trans } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { formatBytes } from "../../lib/utils";
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { CopyButton } from "../ui/copy-button";
import { SubscriptionContext } from "../ui/subscription-provider";
import Switch from "../ui/switch";
import { toast } from "../ui/use-toast";

export function SubscriptionPageViewHeader() {
  const { auth: user } = useAuth();
  const { subscription } = useContext(SubscriptionContext);
  const { subInfo, plan } = subscription;
  const [autoTopUp, setAutoTopUp] = useState(subInfo.isAutoTopUp);
  const [isSavingAutoTopup, setIsSavingAutoTopup] = useState(false);

  const api = useAxiosPrivate();

  const handleAutoTopUpChange = () => {
    setIsSavingAutoTopup(true);
    api
      .post(`/public/subscriptions/${subInfo._id}/autotopup`, {
        enabled: !autoTopUp,
      })
      .then(() => {
        toast({
          title: !autoTopUp ? "✔️ Auto top up enabled." : "✔️ Auto top up disabled.",
          description: !autoTopUp
            ? "We will try to bill your balance and automatically renew the package once it expires."
            : "Auto top up has been disabled for this subscription.",
          variant: "positive",
        });
        setAutoTopUp(!autoTopUp);
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "❌ Error when modifying auto top up settings.",
          description: err.response?.data.message,
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsSavingAutoTopup(false);
      });
  };

  return (
    <div className="space-y-3 lg:space-y-6">
      <div className="flex items-center justify-between">
        <div className="space-y-3">
          <h1 className="text-2xl font-semibold">
            <Trans i18nKey="dashboard.user.generator.heading.title" />
          </h1>
          <div className="flex items-center gap-1.5">
            <div>Subscription ID:</div>
            <div className="cursor-default inline-flex items-center text-muted-foreground text-xs font-medium rounded bg-muted px-2 py-1">
              #<span>{subInfo._id}</span>
            </div>
            <CopyButton value={subInfo._id} />
            {(user?.role === "Administrator" || user?.manager) && (
              <Link to={`/users/${subInfo.user}`} className="text-muted-foreground hover:text-primary/95 transition">
                <UserIcon className="w-4 h-4" />
              </Link>
            )}
          </div>
        </div>
        {!subInfo.deleted
          ? (subInfo.type === "ip" || (subInfo.type === "gb" && plan.enabled)) && (
              <div className="flex gap-3 items-center">
                <div className={classNames("font-medium", autoTopUp && "text-emerald-500")}>
                  <Trans i18nKey="globals.autotopup" />
                </div>
                {isSavingAutoTopup ? (
                  <LoaderIcon className="w-4 h-4 animate-spin" />
                ) : (
                  <Switch checked={autoTopUp} onCheckedChange={handleAutoTopUpChange} />
                )}
              </div>
            )
          : null}
      </div>
      <div className="grid gap-6 lg:grid-cols-2">
        <Card>
          <CardHeader>
            <div className="flex items-center justify-between">
              <CardTitle>Information about your subscription</CardTitle>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    show();
                  }}
                  className="text-sm text-primary underline-offset-4 hover:underline cursor-pointer"
                >
                  Need help?
                </button>
              </div>
            </div>
          </CardHeader>
          <CardContent className="space-y-3">
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="font-medium">Type</h3>
                  {subInfo.type === "gb" && <p className="uppercase">{plan.type}</p>}
                  {/* HARDCODE */}
                  {subInfo.type === "ip" && <p className="uppercase">ISP</p>}
                </div>
                <div>
                  <h3 className="font-medium">Expiry Date</h3>
                  <p>{format(new Date(subInfo.renewalDate), "dd MMM yyyy HH:mm")}</p>
                </div>
              </div>
            </div>
            <div className="border-t pt-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {subInfo.type === "gb" && (
                  <div>
                    <h3 className="font-medium">Remaining bandwidth</h3>
                    <p>{formatBytes(subInfo.bandwidthLeft)}</p>
                  </div>
                )}
                {subInfo.type === "gb" && (
                  <div>
                    <h3 className="font-medium">Bandwidth purchased</h3>
                    <p>{formatBytes(plan.bandwidthBytes)}</p>
                  </div>
                )}
                {subInfo.type === "ip" && (
                  <div>
                    <h3 className="font-medium">Amount of IPs</h3>
                    <p>{subInfo.quantity}</p>
                  </div>
                )}
                {subInfo.type === "ip" && (
                  <div>
                    <h3 className="font-medium">Country</h3>
                    <p>{subInfo.country}</p>
                  </div>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
        {subInfo.type === "gb" ? (
          <Card className="bg-gradient-to-br from-card to-primary/50">
            <div className="p-6 flex flex-col md:flex-row gap-8">
              <div className="flex-1 space-y-6">
                <div className="space-y-4">
                  <div className="flex items-center gap-2">
                    <div className="inline-flex items-center gap-1.5 text-sm font-medium text-primary bg-primary/10 rounded-full px-3 py-1">
                      <SparklesIcon className="w-4 h-4" />
                      HOW-TO
                    </div>
                  </div>
                  <div className="text-sm text-muted-foreground">Updated Last September</div>
                  <h2 className="text-xl font-semibold">Get started with our Product</h2>
                  <div className="space-y-4">
                    <Button onClick={() => showArticle(9809024)}>Read our Guide →</Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ) : null}
      </div>
    </div>
  );
}

import React from "react";
import Logo from "../assets/images/logo_v2.svg";

function LoadingScreen() {
  return (
    <div className="flex w-full h-screen justify-center items-center cursor-default bg-background ">
      <div className="flex flex-col gap-6">
        <div className="hidden md:flex justify-center gap-1 items-center border-bottom border-white -mt-1">
          <img src={Logo} className="object-contain h-7 -mb-1" alt="Thunder" />
          <span className="leading-5 text-foreground font-semibold text-2xl">thunderproxy</span>
        </div>
        <div className="animate-pulse text-center text-xl font-medium">Loading...</div>
      </div>
    </div>
  );
}

export default LoadingScreen;

import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { buttonVariants } from "../../components/ui/button";
import Input from "../../components/ui/input";
import { toast } from "../../components/ui/use-toast";
import axios from "../../config/axios";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";

const newPasswordSchema = z
  .object({
    password: z
      .string()
      .min(8)
      .regex(/^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\-]).{8,16}$/, {
        message:
          "The password must be 8-16 characters long and contain at least one uppercase letter and one special character.",
      }),
    confirmPassword: z
      .string()
      .min(8)
      .regex(/^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\-]).{8,16}$/),
  })
  .refine(
    (data) => {
      return data.password === data.confirmPassword;
    },
    {
      message: "The confirm password and new password must match.",
      path: ["confirmPassword"],
    },
  );

function ResetPassword() {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm({
    resolver: zodResolver(newPasswordSchema),
    criteriaMode: "all",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const api = axios;

  const onSubmit = async (formData) => {
    setIsLoading(true);
    api
      .patch("/auth/forgot-password", {
        newPassword: formData.password,
        confirmPassword: formData.confirmPassword,
        resetPasswordToken: token,
      })
      .then(() => {
        toast({
          title: "Password reset successfully!",
          description: "Please login with your new password",
          variant: "positive",
        });
        navigate("/auth/login");
      })
      .catch((err) => {
        if (err.response) {
          toast({ title: "Error while updating password", description: err.response?.data.message });
        }
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log(form.formState.errors);
  }, [form.formState.errors]);

  return (
    <div className="flex flex-col items-center justify-center sm:w-[360px] md:h-screen lg:py-0 mx-auto">
      <Link to="/" className="flex items-center mb-6 text-2xl font-semibold gap-3">
        <img
          className={classNames("mx-auto h-8 w-auto", isLoading && "animate-ping")}
          src="/images/logo.svg"
          alt="thunderproxy"
        />
        thunderproxy
      </Link>
      <div className="w-full border rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl">
            <Trans i18nKey="auth.reset.heading" />
          </h1>
          <p className="text-sm">Input a new password.</p>
          <Form {...form}>
            <form className="space-y-4 md:space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New password</FormLabel>
                    <FormControl>
                      <Input type="password" placeholder="••••••••" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Confirm password</FormLabel>
                    <FormControl>
                      <Input type="password" placeholder="••••••••" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <button type="submit" className={buttonVariants({ width: "full" })}>
                <Trans i18nKey="auth.reset.action" />
              </button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

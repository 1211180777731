import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function RequireAuth({ adminOnly }) {
  const { auth } = useAuth();
  const location = useLocation();

  return (
    // eslint-disable-next-line no-undef
    !auth?.accessToken ? (
      <Navigate to="/auth/login" state={{ from: location }} replace />
    ) : adminOnly ? (
      auth?.user.role !== "Administrator" ? (
        <Navigate to="/404" state={{ from: location }} replace />
      ) : (
        <Outlet />
      )
    ) : (
      <Outlet />
    )
  );
}

export default RequireAuth;

import { zodResolver } from "@hookform/resolvers/zod";
import { ExternalLinkIcon, Loader2Icon, PlusIcon, SaveIcon, TrashIcon } from "lucide-react";
import { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as z from "zod";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { generateSubscriptionExampleCurl } from "../../lib/subscription";
import { Button, buttonVariants } from "../ui/button";
import { CardDescription, CardTitle } from "../ui/card";
import CopyOnClickInput from "../ui/copy-on-click-input";
import Input from "../ui/input";
import Label from "../ui/label";
import { SubscriptionContext } from "../ui/subscription-provider";
import { toast } from "../ui/use-toast";
import { GeotargetingConfiguratorView } from "./geotargeting-configurator-view";
import { RotationConfiguratorView } from "./rotation-configurator-view";
import { SubscriptionExampleCurlBox } from "./subscription-example-curl-box";

const ipSchema = z
  .string()
  .regex(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    "Invalid IP address",
  );

const createSchema = (maxLength) =>
  z.object({
    ips: z.array(ipSchema).max(maxLength, `You can only upload up to ${maxLength} IPs`),
  });

export function IPAuthView() {
  const { subscription, updateSubConfig } = useContext(SubscriptionContext);
  const { subConfig, authentication, ipAuth, geoTargeting } = subscription;
  const [showResolvedHost, setShowResolvedHost] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const host = showResolvedHost ? authentication.resolvedHost : authentication.host;

  const schema = createSchema(ipAuth.maxIps);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      ips: ipAuth.ips || [],
    },
  });

  const sources = watch("ips");
  const [ipAuthEnabled, setIpAuthEnabled] = useState(ipAuth.ips.length > 0);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "ips",
  });

  const api = useAxiosPrivate();

  const onSubmit = (data) => {
    setIsSaving(true);
    api
      .put(`/public/subscriptions/${subscription.subInfo._id}/trusted-sources`, data)
      .then(() => {
        if (data.ips.length > 0) {
          setIpAuthEnabled(true);
        } else {
          setIpAuthEnabled(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "An error occurred",
          description: err.response?.data?.error,
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div>
      <div>
        <CardTitle>IP Auth</CardTitle>
        <CardDescription>
          For software that supports a direct connection.{" "}
          <a
            className={buttonVariants({ variant: "link" })}
            href="https://help.thunderproxy.com/en/articles/9809024-how-to-use-rotating-proxies#h_a588b3759c"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </CardDescription>
      </div>
      <div className="space-y-3">
        {geoTargeting && <GeotargetingConfiguratorView value={subConfig} onValueChange={updateSubConfig} />}
        {subConfig.rotation && <RotationConfiguratorView value={subConfig} onValueChange={updateSubConfig} ipAuth />}
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-3">
          <div className="flex items-center justify-between">
            <div className="text-lg font-semibold">
              Trusted sources (Max:
              {ipAuth.maxIps})
            </div>
            <Button
              disabled={sources.length === ipAuth.maxIps}
              type="button"
              variant="outline"
              onClick={() => append("")}
            >
              <PlusIcon className="w-3 h-3 mr-2" />
              Add
            </Button>
          </div>

          {fields.map((field, index) => (
            <div className="flex items-center gap-1" key={field.id}>
              <Controller
                name={`ips[${index}]`}
                control={control}
                render={({ field }) => (
                  <Input
                    autoComplete="off"
                    {...field}
                    className={errors.ips && errors.ips[index] ? "text-destructive" : ""}
                    placeholder="Enter IP address"
                  />
                )}
              />
              <Button type="button" variant="outline" size="icon" onClick={() => remove(index)}>
                <TrashIcon className="w-3 h-3" />
              </Button>
            </div>
          ))}
          {sources.length === 0 && (
            <div className="text-center text-sm text-muted-foreground py-6">No IPs are currently added</div>
          )}
          <div className="flex items-center justify-between">
            {errors.ips ? (
              <p className="text-destructive text-xs truncate">One or more sources is not a valid IP address.</p>
            ) : (
              <span className="text-xs text-muted-foreground">Current IP: {ipAuth.currentIp}</span>
            )}
            <Button disabled={isSaving} type="submit">
              {isSaving ? <Loader2Icon className="animate-spin w-3 h-3 mr-2" /> : <SaveIcon className="w-3 h-3 mr-2" />}
              Save
            </Button>
          </div>
        </form>
        {ipAuthEnabled && (
          <div className="space-y-3">
            <div className="space-y-1">
              <Label>Protocol</Label>
              <div>HTTP</div>
            </div>
            <div className="space-y-1">
              <Label>
                Host{" "}
                <Button
                  className="focus:outline-none"
                  onClick={() => setShowResolvedHost(!showResolvedHost)}
                  variant="link"
                >
                  Show IP
                </Button>
              </Label>
              <CopyOnClickInput value={host} />
            </div>
            <div className="space-y-1">
              <Label>Port</Label>
              <CopyOnClickInput value={authentication.port} />
            </div>
            <div className="flex items-center justify-between">
              <CardTitle>Example cURL</CardTitle>
              <button
                type="button"
                className="flex text-sm items-center text-primary underline-offset-4 hover:underline cursor-pointer"
              >
                What&apos;s cURL?
                <ExternalLinkIcon className="ml-2 w-3 h-3" />
              </button>
            </div>
            <SubscriptionExampleCurlBox
              curlString={generateSubscriptionExampleCurl("ipauth", host, authentication.port)}
            />
          </div>
        )}
      </div>
      <div className="text-xs text-center text-muted-foreground w-full mt-3">
        Your changes are automatically synchronized with our servers every few moments.
      </div>
    </div>
  );
}

import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { Button } from "../../ui/button";

function DashboardHeader() {
  const { auth } = useAuth();

  return (
    <div className="flex items-center justify-between">
      <div>
        <div className="inline-flex items-center text-2xl font-semibold">
          <Trans i18nKey="dashboard.user.homepage.heading.title" />
          <Link to="/profile">
            <span className="ml-1 text-primary hover:text-primary/90 transition">{auth?.user.username}</span>
          </Link>
        </div>
        <div className="text-muted-foreground text-sm">
          <Trans i18nKey="dashboard.user.homepage.heading.caption" />
          {auth?.user.createdAt ? new Date(auth.user.createdAt).getFullYear() : "2023"}
        </div>
      </div>
      <Link to="/store" className="hidden lg:block">
        <Button variant="link" className="px-0">
          Buy proxies
        </Button>
      </Link>
    </div>
  );
}

export default DashboardHeader;

import { ArrowLeft, CopyIcon } from "lucide-react";
import React from "react";
import Switch from "../ui/switch";
import { Skeleton } from "../ui/skeleton";

const SubscriptionSkeleton = () => {
  return (
    <div>
      {/* Header */}
      <div className="flex items-center mb-6">
        <button className="flex items-center gap-2 text-sm text-muted-foreground hover:text-white transition-colors">
          <ArrowLeft className="h-4 w-4" />
          Back
        </button>
      </div>

      {/* Title Section */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-semibold">Subscription management</h1>
        <div className="flex items-center gap-2">
          <span className="text-sm">Auto top-up</span>
          <Switch disabled />
        </div>
      </div>

      {/* Subscription ID */}
      <div className="mb-8">
        <div className="flex items-center gap-2 text-sm text-muted-foreground">
          <span>Subscription ID:</span>
          <div className="flex items-center gap-2 bg-background/95 rounded px-2 py-1">
            <Skeleton className="h-4 w-[200px] " />
            <CopyIcon className="h-4 w-4 cursor-pointer hover:text-white transition-colors" />
          </div>
        </div>
      </div>

      {/* Main Content Card */}
      <div className="border rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-medium">Information about your subscription</h2>
          <button disabled className="text-primary text-sm">
            Need help?
          </button>
        </div>

        <div className="grid grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="space-y-6">
            <div>
              <div className="text-sm text-muted-foreground mb-1">Type</div>
              <Skeleton className="h-6 w-16" />
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            <div>
              <div className="text-sm text-muted-foreground mb-1">Expiry Date</div>
              <Skeleton className="h-6 w-36" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSkeleton;

import { update } from "@intercom/messenger-js-sdk";
import axios from "../config/axios";
import useAuth from "./useAuth";

const useRefresh = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get("/auth/me", {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    // Update auth state with the user data and banners
    setAuth((prev) => ({
      ...prev,
      user: response.data.payload.user,
      accessToken: localStorage.getItem("accessToken"),
      banner: response.data.payload.banner,
      sidebarBanner: response.data.payload.sidebarBanner,
    }));

    // Push user data to the GTM data layer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "user_refresh",
      user_id: response.data.payload.user.id,
      email: response.data.payload.user.email,
    });

    update({ user_id: response?.data.payload.user.id, email: response?.data.payload.user.email });

    return response.data.payload.accessToken;
  };

  return refresh;
};

export default useRefresh;

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useRefresh from "../hooks/useRefresh";
import LoadingScreen from "./Loading";

function PersistLogin() {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefresh();
  const { auth } = useAuth();

  // eslint-disable-next-line no-undef
  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    let isMounted = true;

    const verifyLogin = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    if (!auth?.accessToken && !!accessToken) {
      verifyLogin();
    } else {
      setIsLoading(false);
    }

    return () => (isMounted = false);
  }, []);

  return !accessToken ? <Outlet /> : isLoading ? <LoadingScreen /> : <Outlet />;
}

export default PersistLogin;

import { AlertTriangle, AlertTriangleIcon } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DataAvailableCard from "../data-available-card";
import { PlanRenewExtendModal } from "../modals/plan-renew-extend";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import Breadcrumb from "../ui/breadcrumb";
import { Button } from "../ui/button";
import { SubscriptionContext } from "../ui/subscription-provider";
import {
  SubscriptionDeletedAlert,
  SubscriptionIsErrorAlert,
  SubscriptionNotYetActiveAlert,
} from "./subscription-alerts";
import SubscriptionAPIAccordion from "./subscription-api-accordion";
import SubscriptionBandwidthConsumption from "./subscription-bandwidth-consumption";
import { SubscriptionExportView } from "./subscription-export-view";
import { SubscriptionPageViewHeader } from "./subscription-header";
import { SubscriptionQuickAccessView } from "./subscription-quick-access-view";
import SubscriptionSkeleton from "./subscription-skeleton";
import SubscriptionStaticProxylist from "./subscription-static-proxy-list";

function SubscriptionNotYetActivePage() {
  const { subscription } = useContext(SubscriptionContext);
  const { subInfo } = subscription;

  const api = useAxiosPrivate();

  useEffect(() => {
    const fetchData = async () => {
      api
        .get(`/public/subscriptions/${subInfo._id}`)
        .then((res) => {
          if (res.data.subInfo.active) {
            window.location.reload();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchData();

    const interval = setInterval(fetchData, 10000); // 10000ms = 10 seconds

    return () => clearInterval(interval);
  }, []);

  return <SubscriptionNotYetActiveAlert />;
}

export function SubscriptionDetails() {
  const [showPlanTopupModal, setShowPlanTopupModal] = useState(false);

  const { subscription, error, loading } = useContext(SubscriptionContext);

  if (loading) {
    return <SubscriptionSkeleton />;
  }

  if (error) {
    return (
      <div>
        Error:
        {error}
      </div>
    );
  }

  const { subInfo, plan } = subscription;

  const traslatedSubObject = {
    _id: subInfo._id,
    type: subInfo.type,
    plan,
    quantity: subInfo.quantity,
    externalProvider: subInfo.pricingData,
    externalProviderCountry: subInfo.countryData,
    duration: subInfo.duration,
  };

  if (subscription.subInfo.deleted && subscription.subInfo.type === "gb") {
    return (
      <>
        <div className="space-y-3 lg:space-y-6 animate-in fade-in">
          <Breadcrumb />
          <SubscriptionDeletedAlert data={subscription.subInfo} />
          <SubscriptionPageViewHeader />
          <div className="py-32">
            <div className="flex flex-col items-center gap-6">
              <AlertTriangle className="w-16 h-16 text-primary" />
              <div className="text-3xl font-semibold tracking-tight">
                Your subscription is expired and needs renewal.
              </div>
              <Button onClick={() => setShowPlanTopupModal(true)}>Renew/Extend</Button>
            </div>
          </div>
        </div>
        {traslatedSubObject.type === "gb" && (
          <DataAvailableCard
            remaining={traslatedSubObject.bandwidthLeft}
            total={traslatedSubObject.plan.bandwidthBytes}
          />
        )}
        {traslatedSubObject.type === "gb" && <SubscriptionBandwidthConsumption data={subscription.bandwidthUsage} />}
        <PlanRenewExtendModal open={showPlanTopupModal} onOpenChange={setShowPlanTopupModal} />
      </>
    );
  }

  if (subscription.subInfo.isError) {
    return (
      <div className="space-y-3 lg:space-y-6 animate-in fade-in">
        <Breadcrumb />
        <SubscriptionIsErrorAlert data={subscription.subInfo} />
      </div>
    );
  }

  if (subscription.subInfo.active === false) {
    return (
      <div className="space-y-3 lg:space-y-6 animate-in fade-in">
        <Breadcrumb />
        <SubscriptionNotYetActivePage />
      </div>
    );
  }

  return (
    <div className="lg:space-y-6 space-y-3 animate-in fade-in">
      <Breadcrumb />
      <SubscriptionPageViewHeader />
      {subscription.subInfo.type === "ip" && !subscription.subInfo.deleted && (
        <Alert>
          <AlertTriangleIcon className="h-4 w-4" />
          <AlertTitle>Attention: Once this subscription expires, you&apos;ll lose access to these IPs.</AlertTitle>
          <AlertDescription>To avoid any interruptions, renew your subscription before it expires.</AlertDescription>
          <Button onClick={() => setShowPlanTopupModal(true)} variant="link" className="outline-none">
            Renew now
          </Button>
          <PlanRenewExtendModal open={showPlanTopupModal} onOpenChange={setShowPlanTopupModal} />
        </Alert>
      )}
      {subscription.subInfo.type === "gb" && (
        <DataAvailableCard remaining={subscription.subInfo.bandwidthLeft} total={subscription.subInfo.quantity} />
      )}
      {subscription.subInfo.type === "gb" && <SubscriptionBandwidthConsumption data={subscription.bandwidthUsage} />}
      {subscription.subInfo.type === "gb" && (
        <div className="grid lg:grid-cols-2 gap-6">
          <div>
            <SubscriptionQuickAccessView />
          </div>
          <div className="space-y-6">
            <SubscriptionExportView />
            <SubscriptionAPIAccordion />
          </div>
        </div>
      )}
      {subscription.subInfo.type === "ip" && (
        <div>
          <SubscriptionStaticProxylist />
        </div>
      )}
    </div>
  );
}

import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Icon from "../components/ui/icon";
import LoadingState from "../components/ui/loading-state";
import { toast } from "../components/ui/use-toast";
import { planAliases } from "../config";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { bytesToGB } from "../lib/utils";
import { formatCurrency } from "../utils/Utils";

export default function PaymentSuccess() {
  const [isLoading, setIsLoading] = useState(true);
  const [invoice, setInvoice] = useState();
  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get("invoiceId");
  const navigate = useNavigate();
  const api = useAxiosPrivate();

  const fetchData = () => {
    api
      .get(`/public/invoices/${invoiceId}`)
      .then((res) => {
        setInvoice(res.data.payload);
      })
      .catch((err) => {
        navigate("/");
        toast({
          title: "Error when retrieving invoice",
        });
        console.log(err.response?.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!invoiceId) {
      navigate("/404");
    }
    fetchData();
  }, []);

  return isLoading ? (
    <div className="py-12">
      <LoadingState />
    </div>
  ) : invoice?.paid ? (
    <div className="space-y-3">
      <div className="grid min-h-full place-items-center px-6 py-12 lg:px-8">
        <div className="flex flex-col items-center gap-3">
          <div className="grid place-items-center p-3 bg-green-700/50 rounded-full">
            <Icon name="Check" className="w-12 h-12" />
          </div>
          <h1 className="mt-4 text-3xl font-semibold tracking-tight sm:text-5xl">Payment successful!</h1>
          <p className="mt-6 text-base leading-7 text-muted-foreground">
            Thank you for your business with us. Here&apos;s the details about our transaction:
          </p>
          <div className="rounded bg-popover w-[500px] px-4 py-2">
            <div className="flex justify-between items-center">
              <div className="font-medium">Invoice ID</div>
              <div>{invoice._id}</div>
            </div>
            <div className="flex justify-between items-center">
              <div className="font-medium">Created at</div>
              <div>{format(new Date(invoice.createdAt), "dd MMM yyyy, HH:mm")}</div>
            </div>
            <div className="flex justify-between items-center">
              <div className="font-medium">Amount</div>
              <div>{formatCurrency.format(invoice.price)}</div>
            </div>
            <div className="flex justify-between items-center">
              <div className="font-medium">Paid via</div>
              <div className="uppercase">{invoice.paymentProcessor}</div>
            </div>
            <div className="flex justify-between items-center">
              <div className="font-medium">Description</div>
              <div>
                {invoice.topup && <span>Wallet top-up</span>}
                {invoice.renew && (
                  <span>
                    Renewal of
                    <span className="text-muted-foreground ml-1">
                      <Link
                        className="underline hover:text-blue-400"
                        to={`/subscriptions/${invoice.subscription?._id}`}
                      >
                        {invoice.plan?.bandwidth} GB of
                        {planAliases(invoice.plan.type)}
                      </Link>
                    </span>
                  </span>
                )}
                {!invoice.topup && !invoice.renew && (
                  <span>
                    <span className="text-muted-foreground font-medium ml-1">
                      {invoice.subscription ? (
                        <Link
                          className="underline hover:text-blue-400"
                          to={`/subscriptions/${invoice.subscription?._id}`}
                        >
                          {invoice.plan?.bandwidth}
                          GB of
                          {planAliases(invoice.plan?.type)}
                        </Link>
                      ) : (
                        `${invoice.plan?.bandwidth}GB of ${planAliases(invoice.plan?.type)}`
                      )}
                    </span>{" "}
                    {invoice.subscription &&
                      `[${invoice.subscription?.bandwidthLeft > 0 ? bytesToGB(invoice.subscription.bandwidthLeft) : "0"} / ${invoice.plan?.bandwidth.toFixed(2)}GB]`}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <Link to="/" className="text-blue-500 hover:text-blue-500/95 border-b border-dashed">
              Home
            </Link>
            {(invoice.renew || !invoice.topup) && (
              <Link
                to={`/subscriptions/${invoice.subscription?._id}`}
                className="text-blue-500 hover:text-blue-500/95 border-b border-dashed"
              >
                View my subscription
              </Link>
            )}
            <a
              id="intercomContact"
              className="text-blue-500 hover:text-blue-500/95 border-b border-dashed cursor-pointer"
            >
              Contact support
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
          It seems like we had some trouble getting your payment.
        </h1>
        <p className="mt-6 text-base leading-7">We&apos;ll help you with this.</p>
        <a href="intercomContact" className="text-blue-500 hover:text-blue-500/95 border-b border-dashed">
          Contact support
        </a>
      </div>
    </div>
  );
}

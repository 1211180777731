import * as RadioGroup from "@radix-ui/react-radio-group";
import {
  ArrowRightIcon,
  BanIcon,
  BitcoinIcon,
  CheckIcon,
  CreditCardIcon,
  Loader,
  LoaderIcon,
  WalletIcon,
  XIcon,
} from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { calculateUnitPrice } from "../../lib/store";
import { calculateDiscountPercentage, cn, generateProductName, secondsToDays } from "../../lib/utils";
import { formatCurrency } from "../../utils/Utils";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { Badge } from "../ui/badge";
import { Button, buttonVariants } from "../ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import Input from "../ui/input";
import Label from "../ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { SubscriptionContext } from "../ui/subscription-provider";
import { toast } from "../ui/use-toast";
import { addSeconds, format } from "date-fns";
import CheckBox from "../ui/checkbox";
import { Link } from "react-router-dom";

const CouponInput = ({ setCoupon, setDiscount }) => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [debouncedCoupon] = useDebounce(search, 300);

  const api = useAxiosPrivate();

  // Fetch discount when debounced coupon value changes
  useEffect(() => {
    const fetchDiscountByCoupon = async () => {
      setIsLoading(true);
      setIsValid(false);
      api
        .get(`/public/coupons/${debouncedCoupon}`)
        .then((res) => {
          if (res.data.payload.discount) {
            setIsValid(true);
            setDiscount(res.data.payload.discount);
            setCoupon(debouncedCoupon.toUpperCase());
          }
        })
        .catch(() => {
          setIsValid(false);
          setDiscount(0);
          setCoupon("");
        })
        .finally(() => setIsLoading(false));
    };

    if (debouncedCoupon) {
      fetchDiscountByCoupon();
    } else {
      setDiscount(0);
      setCoupon("");
    }
  }, [debouncedCoupon]);

  return (
    <div className="flex items-center justify-between">
      <div className="text-sm font-medium flex-shrink-0">Coupon (optional):</div>
      <div className="flex items-center gap-1">
        {debouncedCoupon && isLoading ? <LoaderIcon className="w-4 h-4 animate-spin" /> : null}
        {!isLoading && isValid && !!debouncedCoupon ? <CheckIcon className="w-4 h-4 text-green-600" /> : null}
        {!isLoading && !isValid && !!debouncedCoupon ? <XIcon className="w-4 h-4 text-red-600" /> : null}

        <Input
          className={cn("w-48", isValid && "border-green-600")}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
};

const PaymentProcessorSelect = ({ value, onValueChange, finalTotal }) => {
  const { auth } = useAuth();
  const { user } = auth;
  const {
    balancePaymentProcessorEnabled,
    cardPaymentProcessorEnabled,
    cryptoPaymentProcessorEnabled,
    cardMaximum,
    cardMinimum,
  } = user;

  const paymentProcessors = [
    {
      id: "cryptocurrency",
      icon: BitcoinIcon,
      label: "Crypto",
      description:
        "Bitcoin (BTC), Ethereum (ETH), USD Coin (USDC), Dogecoin (DOGE), Litecoin (LTC), Bitcoin Cash (BCH), Tether (USDT). Polygon: MATIC, USDC",
      disabled: false,
    },
    {
      id: "card",
      icon: CreditCardIcon,
      label: "Credit Cards",
      description: "Worldwide VISA and Mastercard (excluding CIS)",
      disabled: false,
    },
    { id: "balance", icon: WalletIcon, label: "Balance", disabled: false },
  ];

  if (finalTotal < cardMinimum || finalTotal > cardMaximum) {
    paymentProcessors.find((a) => a.id === "card").disabled = true;
  }

  if (finalTotal > user.balance) {
    paymentProcessors.find((a) => a.id === "balance").disabled = true;
  }

  if (!balancePaymentProcessorEnabled) {
    paymentProcessors.splice(
      paymentProcessors.findIndex((a) => a.id === "balance"),
      1,
    );
  }

  if (!cardPaymentProcessorEnabled) {
    paymentProcessors.splice(
      paymentProcessors.findIndex((a) => a.id === "card"),
      1,
    );
  }

  if (!cryptoPaymentProcessorEnabled) {
    paymentProcessors.splice(
      paymentProcessors.findIndex((a) => a.id === "cryptocurrency"),
      1,
    );
  }

  return (
    <div className="space-y-2">
      <Label>Select a Payment Method</Label>
      <RadioGroup.Root className="flex flex-col rounded" value={value} onValueChange={onValueChange}>
        {paymentProcessors.map((processor) => (
          <RadioGroup.Item
            className={cn(
              "space-y-1 text-start p-2 border first:rounded-t last:rounded-b",
              "data-[state=checked]:border-primary data-[state=checked]:text-primary",
              "data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50",
            )}
            value={processor.id}
            key={processor.id}
            disabled={processor.disabled}
          >
            <div className="text-sm flex gap-1 items-center">
              {processor.icon && <processor.icon className="w-3 h-3" />}
              <span className="font-medium">{processor.label}</span>
            </div>
            <p className="text-xs text-muted-foreground">
              {processor.id === "balance"
                ? user.balance > finalTotal
                  ? `Remaining balance after purchase: ${formatCurrency.format(user.balance - finalTotal)}`
                  : "Insufficient balance"
                : processor.description}
            </p>
          </RadioGroup.Item>
        ))}
      </RadioGroup.Root>
    </div>
  );
};

const CheckoutOverview = ({ price, onCouponChange, onPaymentProcessorChange, couponDisabled = false }) => {
  const [vatRate] = useState(0.21); // 20% VAT rate
  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState("");

  const [paymentProcessor, setPaymentProcessor] = useState("");

  // Calculate Subtotal (pre-VAT) and VAT amount based on the final price
  const savings = parseFloat(price) * (discount / 100); // Amount saved with the discount
  const subtotal = (parseFloat(price) - savings) / (1 + vatRate) || 0;
  const vatAmount = subtotal * vatRate;
  const finalTotal = parseFloat(price) * (1 - discount / 100) || 0;

  useEffect(() => {
    onCouponChange(coupon);
    onPaymentProcessorChange(paymentProcessor);
  }, [coupon, paymentProcessor]);

  return (
    <div className="space-y-3">
      <div className="space-y-2">
        <Label>Price summary</Label>
        <div className="space-y-1 text-sm rounded border p-2">
          <div className="flex justify-between">
            <span>Subtotal</span>
            <span>{formatCurrency.format(subtotal)}</span>
          </div>
          <div className="flex justify-between">
            <span>VAT (21%)</span>
            <span>{formatCurrency.format(vatAmount)}</span>
          </div>
          {discount > 0 ? (
            <div className="flex justify-between text-green-600">
              <span>Discount</span>
              <span>- {formatCurrency.format(savings)}</span>
            </div>
          ) : null}
          <hr className="bg-muted mb-1" />
          <div className="flex justify-between">
            <span>Total</span>
            <span>{formatCurrency.format(finalTotal)}</span>
          </div>
        </div>
      </div>
      {coupon ? (
        <Alert variant="info">
          <AlertDescription>Coupon &apos;{coupon}&apos; applied.</AlertDescription>
        </Alert>
      ) : null}
      {!couponDisabled ? <CouponInput setCoupon={setCoupon} setDiscount={setDiscount} /> : null}
      <PaymentProcessorSelect value={paymentProcessor} onValueChange={setPaymentProcessor} finalTotal={finalTotal} />
    </div>
  );
};

const StaticRenew = ({ options, onOpenChange }) => {
  const { subscription } = useContext(SubscriptionContext);
  const { subInfo } = subscription;

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState("overview"); // can be 'overview' or 'checkout'
  const form = useForm({ defaultValues: {} });

  const [duration, setDuration] = useState(subInfo.duration.toString());

  const api = useAxiosPrivate();

  const paymentMethod = form.watch("paymentMethod");

  const [hasAcceptedTos, setHasAcceptedTos] = useState(false);

  const handleCancel = () => {
    // TODO
    setHasAcceptedTos(false);
    onOpenChange(false);
  };

  const handleCheckout = (values) => {
    setIsLoading(true);
    values.duration = duration;
    api
      .post(`/public/subscriptions/${subInfo._id}/renew`, values)
      .then((res) => {
        if (res.data.redirectUrl) window.location.href = res.data.redirectUrl;
        onOpenChange(false);
      })
      .catch((err) => {
        console.error(err);
        toast({ title: "Could not renew subscription", variant: "destructive" });
      })
      .finally(() => setIsLoading(false));
  };

  const getQuantityDiscountPercentage = (quantity) => {
    const bracket = options.quantityDiscount.find((item) => quantity >= item.min && quantity <= item.max);
    return bracket?.discount || 0;
  };

  const getDurationMultiplier = (durationInSeconds) => {
    const duration = parseInt(durationInSeconds, 10);
    if (Number.isNaN(duration)) {
      return 0;
    }
    const durationItem = options.durationPricing.find((item) => item.durationInSeconds === duration);
    return durationItem?.multiplier || 0;
  };

  const calculatePrice =
    options.countryData.unitPrice *
    subInfo.quantity *
    getDurationMultiplier(duration) *
    (1 - getQuantityDiscountPercentage(subInfo.quantity) / 100);

  return (
    <form onSubmit={form.handleSubmit(handleCheckout)} className="space-y-3">
      {page === "overview" ? (
        <div className="space-y-3">
          <div className="space-y-2">
            <Label>Select Duration</Label>
            <div className="text-xs text-muted-foreground">
              This amount will be added to your current expiration date.
            </div>
            <Select value={duration} onValueChange={setDuration}>
              <SelectTrigger>
                <SelectValue placeholder="Select a duration" />
              </SelectTrigger>
              <SelectContent>
                {options.durationPricing.map((item) => (
                  <SelectItem key={item.id} value={`${item.durationInSeconds}`}>
                    {secondsToDays(item.durationInSeconds)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {duration ? (
              <div className="rounded border space-y-1 p-2">
                <div className="flex items-center justify-between">
                  <div className="text-sm">Product</div>
                  <div className="text-sm font-medium">{generateProductName(subInfo.type, subInfo.subCategory)}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm">Country</div>
                  <div className="text-sm font-medium">{subInfo.countryData.displayName}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm">Amount of IPs</div>
                  <div className="text-sm font-medium">{subInfo.quantity}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm">Duration</div>
                  <div className="text-sm font-medium">{secondsToDays(parseInt(duration ?? 0))}</div>
                </div>
                <hr className="bg-muted mb-1" />
                <div className="flex items-center justify-between">
                  <div className="text-sm">New expiration date</div>
                  <div className="text-sm font-medium">
                    {format(addSeconds(new Date(subInfo.renewalDate), parseInt(duration ?? 0)), "PPP, HH:mm")}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {page === "checkout" ? (
        <div className="space-y-3">
          <div className="rounded border space-y-1 p-2">
            <div className="flex items-center justify-between">
              <div className="text-sm">Product</div>
              <div className="text-sm font-medium">{generateProductName(subInfo.type, subInfo.subCategory)}</div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm">Country</div>
              <div className="text-sm font-medium">{subInfo.countryData.displayName}</div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm">Amount of IPs</div>
              <div className="text-sm font-medium">{subInfo.quantity}</div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm">Duration</div>
              <div className="text-sm font-medium">{secondsToDays(parseInt(duration ?? 0))}</div>
            </div>
            <hr className="bg-muted mb-1" />
            <div className="flex items-center justify-between">
              <div className="text-sm">New expiration date</div>
              <div className="text-sm font-medium">
                {format(addSeconds(new Date(subInfo.renewalDate), parseInt(duration ?? 0)), "PPP, HH:mm")}
              </div>
            </div>
          </div>
          <CheckoutOverview
            price={calculatePrice}
            onCouponChange={(value) => {
              form.setValue("coupon", value);
            }}
            onPaymentProcessorChange={(value) => {
              form.setValue("paymentMethod", value);
            }}
            couponDisabled
          />
        </div>
      ) : null}
      {/* Pagination Logic */}
      <DialogFooter>
        {page === "overview" ? (
          <>
            <Button type="button" onClick={() => handleCancel()} variant="secondary">
              Cancel
            </Button>
            <Button type="button" disabled={!duration} onClick={() => setPage("checkout")}>
              Next
              <ArrowRightIcon className="w-3 h-3 ml-2" />
            </Button>
          </>
        ) : null}
        {page === "checkout" ? (
          <>
            <div className="inline-flex items-center gap-2 text-xs flex-1">
              <CheckBox value={hasAcceptedTos} onValueChange={() => setHasAcceptedTos(!hasAcceptedTos)} />
              <span>
                I agree to the{" "}
                <Link
                  className="underline hover:text-primary transition-colors"
                  to="/tos"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms of service and purchase
                </Link>
                .
              </span>
            </div>
            <Button type="button" variant="secondary" onClick={() => setPage("overview")}>
              Back
            </Button>
            <Button disabled={!paymentMethod || isLoading} type="submit">
              Checkout
            </Button>
          </>
        ) : null}
      </DialogFooter>
    </form>
  );
};

const RotatingRenew = ({ options, onOpenChange }) => {
  if (options?.plans.length === 0) {
    return (
      <div className="space-y-3">
        <Alert>
          <BanIcon className="w-4 h-4" />
          <AlertTitle>No plans available for renewal at the moment.</AlertTitle>
          <AlertDescription>
            It looks like there are no options for renewal at the moment. Please check back later.
          </AlertDescription>
        </Alert>
        <DialogFooter>
          <Button onClick={() => onOpenChange(false)} variant="secondary">
            Close
          </Button>
        </DialogFooter>
      </div>
    );
  }

  const { subscription } = useContext(SubscriptionContext);
  const { subInfo, plan } = subscription;

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState("overview"); // can be 'overview' or 'checkout'

  const [selectedPlanId, setSelectedPlanId] = useState(options?.suggestedPlan?._id);

  const [hasAcceptedTos, setHasAcceptedTos] = useState(false);

  const selectedPlan = options?.plans.find((plan) => plan._id === selectedPlanId);

  const api = useAxiosPrivate();

  const form = useForm({
    defaultValues: {},
  });

  const handleCancel = () => {
    setHasAcceptedTos(false);
    setSelectedPlanId(options?.plans.find((a) => a.bandwidthBytes === plan.bandwidthBytes)?._id);
    form.reset();
    onOpenChange(false);
  };

  const handleCheckout = (values) => {
    setIsLoading(true);
    values.plan = selectedPlan._id;
    api
      .post(`/public/subscriptions/${subInfo._id}/renew`, values)
      .then((res) => {
        if (res.data.redirectUrl) window.location.href = res.data.redirectUrl;
        onOpenChange(false);
      })
      .catch((err) => {
        console.error(err);
        toast({ title: "Could not renew subscription", variant: "destructive" });
      })
      .finally(() => setIsLoading(false));
    console.log(values);
  };

  const paymentMethod = form.watch("paymentMethod");

  return (
    <form onSubmit={form.handleSubmit(handleCheckout)} className="space-y-3">
      {page === "overview" ? (
        <div className="space-y-3">
          <div className="space-y-2">
            <Label>Select Bandwidth</Label>
            <div className="text-xs text-muted-foreground">
              You can downsize or upsize your bandwidth. The price will be adjusted accordingly.
            </div>
            <RadioGroup.Root className="flex flex-wrap gap-2" value={selectedPlanId} onValueChange={setSelectedPlanId}>
              {options?.plans.map((plan) => (
                <RadioGroup.Item
                  disabled={plan.disabled}
                  className={cn(
                    buttonVariants({ variant: "outline", size: "sm" }),
                    "transition-colors duration-200",
                    "data-[state=checked]:outline data-[state=checked]:text-primary",
                    "data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50",
                  )}
                  key={plan._id}
                  value={plan._id}
                >
                  {plan.bandwidth} GB
                </RadioGroup.Item>
              ))}
            </RadioGroup.Root>
          </div>
          {selectedPlan ? (
            <div className="rounded border space-y-1 p-2">
              <div className="flex items-center justify-between">
                <div className="text-sm">Product</div>
                <div className="text-sm font-medium">{generateProductName(subInfo.type, subInfo.subCategory)}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-sm">Bandwidth</div>
                <div className="text-sm font-medium">{selectedPlan.bandwidth} GB</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-sm">Price</div>
                <div className="text-sm font-medium">{formatCurrency.format(selectedPlan.price)}</div>
              </div>
              {selectedPlan.bandwidth > 1 ? (
                <div className="flex items-center justify-between text-muted-foreground">
                  <div className="text-sm">Price per GB</div>
                  <div className="flex items-center gap-1 text-sm font-medium">
                    <Badge variant="outline">
                      {calculateDiscountPercentage(
                        options.plans[0].price,
                        selectedPlan.price / selectedPlan.bandwidth,
                      ).toFixed(0)}
                      % OFF
                    </Badge>
                    {formatCurrency.format(calculateUnitPrice(selectedPlan.price, selectedPlan.bandwidth))}
                  </div>
                </div>
              ) : null}
              <div className="flex items-center justify-between">
                <div className="text-sm">Duration</div>
                <div className="text-sm font-medium">
                  {secondsToDays(options?.plans.find((plan) => plan._id === selectedPlanId).duration)}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {page === "checkout" ? (
        <div className="space-y-3">
          <div className="rounded border space-y-1 p-2">
            <div className="flex items-center justify-between">
              <div className="text-sm">Product</div>
              <div className="text-sm font-medium">{generateProductName(subInfo.type, subInfo.subCategory)}</div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm">Bandwidth</div>
              <div className="text-sm font-medium">{selectedPlan.bandwidth} GB</div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm">Price</div>
              <div className="text-sm font-medium">{formatCurrency.format(selectedPlan.price)}</div>
            </div>
            {selectedPlan.bandwidth > 1 ? (
              <div className="flex items-center justify-between text-muted-foreground">
                <div className="text-sm">Price per GB</div>
                <div className="flex items-center gap-1 text-sm font-medium">
                  <Badge variant="outline">
                    {calculateDiscountPercentage(
                      options.plans[0].price,
                      selectedPlan.price / selectedPlan.bandwidth,
                    ).toFixed(0)}
                    % OFF
                  </Badge>
                  {formatCurrency.format(calculateUnitPrice(selectedPlan.price, selectedPlan.bandwidth))}
                </div>
              </div>
            ) : null}
            <div className="flex items-center justify-between">
              <div className="text-sm">Duration</div>
              <div className="text-sm font-medium">
                {secondsToDays(options?.plans.find((plan) => plan._id === selectedPlanId).duration)}
              </div>
            </div>
          </div>
          <CheckoutOverview
            price={selectedPlan.price}
            onCouponChange={(value) => {
              form.setValue("coupon", value);
            }}
            onPaymentProcessorChange={(value) => {
              form.setValue("paymentMethod", value);
            }}
          />
        </div>
      ) : null}
      {/* Pagination Logic */}
      <DialogFooter>
        {page === "overview" ? (
          <>
            <Button type="button" onClick={() => handleCancel()} variant="secondary">
              Cancel
            </Button>
            <Button type="button" disabled={!selectedPlan} onClick={() => setPage("checkout")}>
              Next
              <ArrowRightIcon className="w-3 h-3 ml-2" />
            </Button>
          </>
        ) : null}
        {page === "checkout" ? (
          <>
            <div className="inline-flex items-center gap-2 text-xs flex-1">
              <CheckBox value={hasAcceptedTos} onValueChange={() => setHasAcceptedTos(!hasAcceptedTos)} />
              <span>
                I agree to the{" "}
                <Link
                  className="underline hover:text-primary transition-colors"
                  to="/tos"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms of service and purchase
                </Link>
                .
              </span>
            </div>
            <Button
              type="button"
              disabled={isLoading}
              variant="secondary"
              onClick={() => {
                setHasAcceptedTos(false);
                setPage("overview");
              }}
            >
              Back
            </Button>
            <Button disabled={!paymentMethod || isLoading} type="submit">
              {isLoading ? <LoaderIcon className="w-4 h-4 animate-spin" /> : null}
              Checkout
            </Button>
          </>
        ) : null}
      </DialogFooter>
    </form>
  );
};

export const PlanRenewExtendModal = ({ open, onOpenChange }) => {
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [options, setOptions] = useState();

  const api = useAxiosPrivate();
  const { subscription } = useContext(SubscriptionContext);
  const { subInfo } = subscription;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingOptions(true);
      api
        .get(`/public/subscriptions/${subInfo._id}/renew/options`)
        .then((res) => {
          setOptions(res.data);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setIsLoadingOptions(false));
    };

    fetchData();
  }, []);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Renew/Extend your subscription</DialogTitle>
          <DialogDescription>Review your renewal options.</DialogDescription>
        </DialogHeader>
        {isLoadingOptions ? (
          <div className="grid place-items-center py-16">
            <Loader className="w-8 h-8 animate-spin" />
          </div>
        ) : (
          <div>
            {subInfo.type === "gb" && <RotatingRenew options={options} onOpenChange={onOpenChange} />}
            {subInfo.type === "ip" && <StaticRenew options={options} onOpenChange={onOpenChange} />}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

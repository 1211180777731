import { useEffect, useState } from "react";
import axios from "../../config/axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import LoadingState from "../../components/ui/loading-state";
import useRefresh from "../../hooks/useRefresh";

const VerifyEmailPage = () => {
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  const refresh = useRefresh();

  if (!token) {
    return <Navigate to="/" />;
  }

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      axios
        .post("/auth/verify-email", { token })
        .then((response) => {
          setIsSuccess(true);
          setMessage(response.data.message); // Set success message from server
          refresh();
          setTimeout(() => {
            navigate("/");
          }, 2000);
        })
        .catch((err) => {
          console.error(err);
          setIsError(true);
          if (err.response) {
            // Server responded with a status other than 200 range
            const { status, data } = err.response;
            switch (status) {
              case 422:
                setMessage(data.message || "Validation error. Please try again.");
                break;
              case 404:
                setMessage(data.message || "Verification token not found. Please check your link.");
                break;
              case 410:
                setMessage(data.message || "Verification token has expired. Please request a new verification email.");
                break;
              case 500:
              default:
                setMessage(data.message || "An unexpected error occurred. Please try again later.");
                break;
            }
          } else if (err.request) {
            // Request was made but no response received
            setMessage("No response from server. Please check your internet connection.");
          } else {
            // Something happened in setting up the request
            setMessage("Error in setting up the request. Please try again.");
          }
        })
        .finally(() => setIsLoading(false));
    };

    fetchData();
  }, [token, navigate]);

  if (isLoading) {
    return (
      <div className="py-32 grid place-items-center">
        <div>We&apos;re confirming your e-mail...</div>
        <LoadingState />
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        Error while confirming your e-mail. <p>{message}</p>
      </div>
    );
  }

  if (isSuccess) {
    return <div>E-mail verified successfully!</div>;
  }
};

export default VerifyEmailPage;

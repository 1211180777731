import React, { useContext } from "react";
import { SubscriptionContext } from "../ui/subscription-provider";
import useAuth from "../../hooks/useAuth";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import { CopyButton } from "../ui/copy-button";

const SubscriptionAPIAccordion = () => {
  const { subscription } = useContext(SubscriptionContext);
  const { auth } = useAuth();

  const baseApiLink = `https://api.thunderproxy.com/v1/api/subscriptions/${subscription.subInfo._id}`;
  const apiKeyAppend = `?apiKey=${auth.user.subscriptionApiKey}`;
  const items = [
    {
      title: "Get subscription geotargeting country list",
      content: (
        <div className="flex items-center gap-1">
          <div className="font-medium">GET</div>
          <code className="px-1 py-0.5 truncate text-left items-center bg-muted rounded">
            <span>{`${baseApiLink}/geo${apiKeyAppend}`}</span>
          </code>
          <CopyButton value={`${baseApiLink}/geo${apiKeyAppend}`} />
        </div>
      ),
    },
  ];

  return (
    <div className="space-y-3">
      <h3 className="text-lg font-semibold leading-none tracking-tight">
        <span className="mr-2 p-1 bg-primary text-primary-foreground rounded-md">NEW</span>
        Subscription API
      </h3>
      <Accordion type="single" collapsible>
        {items.map((item, i) => (
          <AccordionItem key={item.title} value={String(i)}>
            <AccordionTrigger>{item.title}</AccordionTrigger>
            <AccordionContent>{item.content}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default SubscriptionAPIAccordion;

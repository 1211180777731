import { useEffect, useState } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../ui/accordion";
import { Badge } from "../../ui/badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../ui/tooltip";
import { HelpCircleIcon } from "lucide-react";
import SubscriptionCard from "../../ui/subscription-card";

export default function AttentionRequiredAccordion({ attentionRequiredSubs }) {
  // State to manage the default value based on user preference
  const [accordionValue, setAccordionValue] = useState("");

  // Load the user preference from local storage on component mount
  useEffect(() => {
    const savedState = localStorage.getItem("accordionExpanded");
    setAccordionValue(savedState === "true" ? "1" : "");
  }, []);

  // Function to toggle accordion state and save user preference
  const handleAccordionChange = (value) => {
    const isExpanded = value === "1";
    setAccordionValue(isExpanded ? "1" : "");
    localStorage.setItem("accordionExpanded", isExpanded.toString());
  };

  return (
    <Accordion type="single" collapsible value={accordionValue} onValueChange={handleAccordionChange}>
      <AccordionItem value="1">
        <AccordionTrigger>
          <div className="flex items-center gap-1">
            <h2 className="text-xl font-semibold">Attention required</h2>
            <Badge>{attentionRequiredSubs.length}</Badge>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircleIcon className="w-3 h-3 text-primary" />
              </TooltipTrigger>
              <TooltipContent>
                Subscriptions which will expire soon, or that recently expired, will appear in this section.
              </TooltipContent>
            </Tooltip>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 grid-rows-[1fr]">
            {attentionRequiredSubs?.map((item) => (
              <SubscriptionCard key={item._id} sub={item} noMotion />
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

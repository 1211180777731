import React from "react";
import { CheckIcon, ClipboardIcon } from "lucide-react";
import classNames from "classnames";
import { Button } from "./button";

export async function copyToClipboardWithMeta(value) {
  // eslint-disable-next-line no-undef
  navigator.clipboard.writeText(value);
}

export function CopyButton({ value, className, variant = "ghost", event, ...props }) {
  const [hasCopied, setHasCopied] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  }, [hasCopied]);

  return (
    <Button
      type="button"
      size="icon"
      variant={variant}
      className={classNames(
        "relative h-6 w-6 text-muted-foreground hover:bg-muted hover:text-foreground [&_svg]:w-3 [&_svg]:h-3",
        className,
      )}
      onClick={() => {
        copyToClipboardWithMeta(
          value,
          event
            ? {
                name: event,
                properties: {
                  code: value,
                },
              }
            : undefined,
        );
        setHasCopied(true);
      }}
      {...props}
    >
      <span className="sr-only">Copy</span>
      {hasCopied ? <CheckIcon /> : <ClipboardIcon />}
    </Button>
  );
}

export const localeDateOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const planAliases = (value) => {
  const plans = {
    thunder: {
      name: "Residential",
      i18nKey: "planAliases.residential",
    },
    thunder_datacenter: {
      name: "Datacenter",
      i18nKey: "planAliases.datacenter",
    },
    thunder_static: {
      name: "ISP",
      i18nKey: "planAliases.static",
    },
    thunder_mobile: {
      name: "Mobile",
      i18nKey: "planAliases.mobile",
    },
    thunder_datacenter_old: {
      name: "DC Old",
    },
  };

  if (!value) {
    return "";
  }

  const result = plans[value].name;

  if (!result) {
    return "Unknown";
  }

  return result;
};

export const generatorAliases = {
  thunder: "res",
  thunder_datacenter: "dc",
  thunder_static: "stc",
  thunder_mobile: "mob",
};

export const proxyFeatures = {
  thunder: [
    {
      icon: "Network",
      i18nKey: "globals.plans.features.pool.residential",
    },
    {
      icon: "Globe2",
      i18nKey: "globals.plans.features.countries",
    },
    {
      icon: "SlidersHorizontal",
      i18nKey: "globals.plans.features.rotating",
    },
    {
      icon: "Infinity",
      i18nKey: "globals.plans.features.threads",
    },
    {
      icon: "MapPin",
      i18nKey: "globals.plans.features.targeting.rotating",
    },
  ],
  thunder_static: [
    {
      icon: "Network",
      i18nKey: "globals.plans.features.pool.residential",
    },
    {
      icon: "Globe2",
      i18nKey: "globals.plans.features.countries",
    },
    {
      icon: "Router",
      i18nKey: "globals.plans.features.static",
    },
    {
      icon: "Infinity",
      i18nKey: "globals.plans.features.threads",
    },
    {
      icon: "MapPin",
      i18nKey: "globals.plans.features.targeting.static",
    },
  ],
  thunder_datacenter: [
    {
      icon: "Network",
      i18nKey: "globals.plans.features.pool.datacenter",
    },
    {
      icon: "Globe2",
      i18nKey: "globals.plans.features.countries",
    },
    {
      icon: "Router",
      i18nKey: "globals.plans.features.static",
    },
    {
      icon: "Infinity",
      i18nKey: "globals.plans.features.threads",
    },
    {
      icon: "MapPin",
      i18nKey: "globals.plans.features.targeting.static",
    },
  ],
  thunder_mobile: [
    {
      icon: "Network",
      i18nKey: "globals.plans.features.pool.mobile",
    },
    {
      icon: "Globe2",
      i18nKey: "globals.plans.features.countries",
    },
    {
      icon: "Router",
      i18nKey: "globals.plans.features.rotating",
    },
    {
      icon: "Infinity",
      i18nKey: "globals.plans.features.threads",
    },
    {
      icon: "MapPin",
      i18nKey: "globals.plans.features.targeting.rotating",
    },
  ],
};

import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { formatBytes } from "../../../../lib/utils";
import { buttonVariants } from "../../../ui/button";
import CheckBox from "../../../ui/checkbox";
import {
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaDescription,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
} from "../../../ui/credenza";
import StyledDatepicker from "../../../ui/datepicker";
import Icon from "../../../ui/icon";
import Input from "../../../ui/input";
import Label from "../../../ui/label";
import { ScrollArea } from "../../../ui/scroll-area";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/select";
import { toast } from "../../../ui/use-toast";

const PlanSelect = ({ value, onValueChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState([]);

  const api = useAxiosPrivate();

  useEffect(() => {
    const fetchPlansData = () => {
      setIsLoading(true);
      api
        .get("/admin/plans")
        .then((res) => {
          setPlans(res.data.payload.plans);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchPlansData();
  }, []);

  return (
    <Select disabled={isLoading} value={value} onValueChange={onValueChange}>
      <SelectTrigger className="uppercase">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {plans.map((item) => (
          <SelectItem className="uppercase" key={item._id} value={item._id}>
            {item.type} - {formatBytes(item.bandwidthBytes)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

const EditIpSubscription = ({ data, open, onOpenChange, callbackFn }) => {
  const [isLoading, setIsLoading] = useState(false);

  const api = useAxiosPrivate();

  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      externalProviderId: data.externalProviderId,
      isError: data.isError,
      renewalDate: data.renewalDate,
      customUsername: data.customUsername,
      customPassword: data.customPassword,
      customString: data.customString,
      gateway: data.gateway,
      deleted: data.deleted,
      externalProviderProxies: data.externalProviderProxies,
    },
  });

  const onSubmit = (formData) => {
    setIsLoading(true);
    api
      .patch(`/admin/subscriptions/${data?._id}`, formData)
      .then(() => {
        toast({
          title: "Subscription edited successfully.",
          variant: "positive",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Could not edit subscription.",
          description: err.response?.data.message || "",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
        onOpenChange(!open);
        callbackFn();
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4 mb-4 lg:grid-cols-2">
        <div className="space-y-1">
          <Label>External ID</Label>
          <Input readOnly {...register("externalProviderId")} />
        </div>
        <div className="space-y-1">
          <Label>Renewal Date</Label>
          <Controller
            control={control}
            name="renewalDate"
            render={({ field }) => <StyledDatepicker mode="single" onValueChange={field.onChange} {...field} />}
          />
        </div>
        <div className="space-y-1">
          <Label>Custom string</Label>
          <div className="flex items-center gap-1.5 pt-1.5">
            <Controller
              control={control}
              name="customString"
              render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
            />
            Enable override
          </div>
        </div>
        <div className="space-y-1">
          <Label>Custom user</Label>
          <Input {...register("customUsername")} />
        </div>
        <div className="space-y-1">
          <Label>Custom password</Label>
          <Input {...register("customPassword")} />
        </div>
        <div className="space-y-1">
          <Label>Default gateway</Label>
          <Input {...register("gateway")} />
        </div>
      </div>
      <div className="space-y-1">
        <Label>Proxy list ({data.quantity})</Label>
        <ScrollArea className="h-64 rounded-md border">
          <div className="p-3">
            {Array.from({ length: data.quantity }).map((_, i) => (
              <Controller
                key={i}
                control={control}
                name={`externalProviderProxies.${i}`}
                render={({ field }) => <Input className="mb-3" {...field} />}
              />
            ))}
          </div>
        </ScrollArea>
      </div>
      <div className="flex items-center justify-between mt-2">
        <div>
          <div className="flex items-center gap-1.5">
            <Controller
              control={control}
              name="deleted"
              render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
            />
            Subscription frozen
          </div>
        </div>
        <CredenzaFooter>
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              className={buttonVariants({ variant: "outline" })}
              onClick={(event) => {
                onOpenChange(false);
                event.preventDefault();
              }}
            >
              Cancel
            </button>
            <button type="submit" className={buttonVariants()}>
              {isLoading && <Icon name="Loader" className="mr-2 h-4 w-4 animate-spin" />}
              Submit
            </button>
          </div>
        </CredenzaFooter>
      </div>
    </form>
  );
};

const EditGbSubscription = ({ data, open, onOpenChange, callbackFn }) => {
  const [isLoading, setIsLoading] = useState(false);

  const api = useAxiosPrivate();

  const { watch, control, register, handleSubmit } = useForm({
    defaultValues: {
      isError: data.isError,
      renewalDate: data.renewalDate,
      bandwidthLeft: data.bandwidthLeft,
      planType: data.planType,
      customUsername: data.customUsername,
      customPassword: data.customPassword,
      customString: data.customString,
      gateway: data.gateway,
      plan: data.plan?._id,
      deleted: data.deleted,
    },
  });

  const planType = watch("planType", data?.planType || null);

  const onSubmit = (formData) => {
    setIsLoading(true);
    api
      .patch(`/admin/subscriptions/${data?._id}`, formData)
      .then(() => {
        toast({
          title: "Subscription edited successfully.",
          variant: "positive",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Could not edit subscription.",
          description: err.response?.data.message || "",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
        onOpenChange(!open);
        callbackFn();
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4 mb-4 lg:grid-cols-2">
        <div className="space-y-1">
          <Label>Bandwidth Left</Label>
          <Input {...register("bandwidthLeft")} />
        </div>
        <div className="space-y-1">
          <Label>Renewal Date</Label>
          <Controller
            control={control}
            name="renewalDate"
            render={({ field }) => <StyledDatepicker mode="single" onValueChange={field.onChange} {...field} />}
          />
        </div>
        <div className="space-y-1">
          <Label>Plan Type</Label>
          <Controller
            control={control}
            name="planType"
            render={({ field }) => (
              <Select onValueChange={field.onChange} {...field}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="residential">Residential</SelectItem>
                  <SelectItem value="datacenter">Datacenter</SelectItem>
                  <SelectItem value="mobile">Mobile</SelectItem>
                </SelectContent>
              </Select>
            )}
          />
        </div>
        <div className="space-y-1">
          <Label>Plan</Label>
          <Controller
            control={control}
            name="plan"
            render={({ field }) => (
              <PlanSelect value={field.value} onValueChange={field.onChange} typeFilter={planType} />
            )}
          />
        </div>
        <div className="space-y-1">
          <Label>Custom string</Label>
          <div className="flex items-center gap-1.5 pt-1.5">
            <Controller
              control={control}
              name="customString"
              render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
            />
            Enable override
          </div>
        </div>
        <div className="space-y-1">
          <Label>Custom user</Label>
          <Input {...register("customUsername")} />
        </div>
        <div className="space-y-1">
          <Label>Custom password</Label>
          <Input {...register("customPassword")} />
        </div>
        <div className="space-y-1">
          <Label>Default gateway</Label>
          <Input {...register("gateway")} />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <div className="flex items-center gap-1.5">
            <Controller
              control={control}
              name="deleted"
              render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
            />
            Subscription frozen
          </div>
        </div>
        <CredenzaFooter>
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              className={buttonVariants({ variant: "outline" })}
              onClick={(event) => {
                onOpenChange(false);
                event.preventDefault();
              }}
            >
              Cancel
            </button>
            <button type="submit" className={buttonVariants()}>
              {isLoading && <Icon name="Loader" className="mr-2 h-4 w-4 animate-spin" />}
              Submit
            </button>
          </div>
        </CredenzaFooter>
      </div>
    </form>
  );
};

function EditSubscriptionModal({ data, open, onOpenChange, callbackFn }) {
  return (
    <Credenza open={open} onOpenChange={onOpenChange}>
      <CredenzaContent>
        <CredenzaHeader>
          <CredenzaTitle>Edit subscription</CredenzaTitle>
          <CredenzaDescription>
            <span className="font-mono">{data._id}</span>
          </CredenzaDescription>
        </CredenzaHeader>
        <CredenzaBody>
          {data.type === "gb" && (
            <EditGbSubscription data={data} open={open} onOpenChange={onOpenChange} callbackFn={callbackFn} />
          )}
          {data.type === "ip" && (
            <EditIpSubscription data={data} open={open} onOpenChange={onOpenChange} callbackFn={callbackFn} />
          )}{" "}
        </CredenzaBody>
      </CredenzaContent>
    </Credenza>
  );
}

export default EditSubscriptionModal;

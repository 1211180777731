import classNames from "classnames";
import { DOTS, usePagination } from "../hooks/usePagination";
import { buttonVariants } from "./ui/button";

function Pagination({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <div className="flex items-center justify-between py-3">
      <div className="flex flex-1 items-center justify-center">
        <div>
          <nav className="isolate inline-flex -space-x-px" aria-label="Pagination">
            <button
              type="button"
              disabled={currentPage === 1}
              onClick={onPrevious}
              className={buttonVariants({ variant: "ghost" })}
            >
              Previous
            </button>
            {paginationRange.map((pageNumber) => {
              if (pageNumber === DOTS) {
                return (
                  <button
                    key="dots"
                    type="button"
                    disabled
                    className="relative inline-flex items-center px-3 py-2 text-sm font-medium"
                  >
                    &#8230;
                  </button>
                );
              }
              return (
                <button
                  type="button"
                  key={pageNumber}
                  className={classNames(
                    "rounded-full hover:bg-accent hover:text-foreground text-muted-foreground px-4 py-2",
                    pageNumber === currentPage && "bg-accent text-foreground rounded-full",
                  )}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              );
            })}
            <button
              type="button"
              disabled={currentPage === totalCount}
              onClick={onNext}
              className={buttonVariants({ variant: "ghost" })}
            >
              Next
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Pagination;

import * as React from "react";

import { Moon, Sun } from "lucide-react";
import { useTheme } from "./theme-provider";
import { Button } from "./ui/button";

export default function ModeToggle() {
  const { theme, toggleTheme } = useTheme();

  return (
    <Button onClick={() => toggleTheme()} variant="ghost">
      {theme !== "light" && <Sun className="h-4 w-4" />}
      {theme !== "dark" && <Moon className="h-4 w-4" />}
      <span className="sr-only">Toggle theme</span>
    </Button>
  );
}

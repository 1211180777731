import React from "react";
import { Trans } from "react-i18next";
import Icon from "./icon";

function LoadingState() {
  return (
    <div className="w-full flex flex-col justify-center items-center gap-3 py-6">
      <div className="font-medium">
        <Trans i18nKey="globals.loading" />
        ...
      </div>
      <Icon name="Loader2" className="w-8 h-8 text-slate-700 animate-spin" />
    </div>
  );
}

export default LoadingState;

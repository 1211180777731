import React from "react";

function CircularProgress({ progress }) {
  const radius = 7;
  const circumference = 2 * Math.PI * radius;

  // Calculate offsets based on progress
  let blueOffset = circumference;
  let greenOffset = circumference;

  if (progress <= 100) {
    // If progress is 100 or less, only adjust the blue offset
    blueOffset = circumference - (progress / 100) * circumference;
  } else {
    // If progress is more than 100, fill the blue circle completely and adjust the green circle
    blueOffset = 0;
    greenOffset = circumference - ((progress - 100) / 100) * circumference;
  }

  return (
    <svg className="transform -rotate-90" width="16" height="16">
      <circle
        className="text-muted-foreground"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        r={radius}
        cx="8"
        cy="8"
        style={{
          strokeLinecap: "round",
        }}
      />
      <circle
        className="text-blue-600"
        strokeWidth="2"
        strokeDasharray={circumference}
        strokeDashoffset={blueOffset}
        strokeLinecap="round"
        stroke="currentColor"
        fill="none"
        r={radius}
        cx="8"
        cy="8"
        style={{
          strokeLinecap: "round",
          transition: "stroke-dashoffset 0.6s ease 0s",
        }}
      />
      {progress > 100 && (
        <circle
          className="text-purple-600 animate-pulse"
          strokeWidth="2"
          strokeDasharray={circumference}
          strokeDashoffset={greenOffset}
          strokeLinecap="round"
          stroke="currentColor"
          fill="none"
          r={radius}
          cx="8"
          cy="8"
          style={{
            strokeLinecap: "round",
            transition: "stroke-dashoffset 0.6s ease 0s",
          }}
        />
      )}
    </svg>
  );
}

export default CircularProgress;

import { Trans } from "react-i18next";
import { Button } from "../../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import Icon from "../../ui/icon";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import AddFundsModal from "../../modals/add-funds";
import { formatCurrency } from "../../../utils/Utils";

function BalanceCard() {
  const [showAddFundsModal, setShowAddFundsModal] = useState(false);

  const { auth } = useAuth();

  return (
    <Card>
      <CardHeader>
        <div className="flex items-start justify-between">
          <CardTitle>
            <Trans i18nKey="dashboard.user.homepage.balance.heading" />
          </CardTitle>
          <Button size="sm" onClick={() => setShowAddFundsModal(true)}>
            <Icon name="Plus" className="w-4 h-4" />
            <Trans i18nKey="dashboard.user.wallet.action" />
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex items-end space-x-1">
          <div className="text-2xl font-semibold">{formatCurrency.format(auth?.user.balance)}</div>
          <div className="text-muted-foreground font-medium">USD</div>
        </div>
      </CardContent>
      {showAddFundsModal && <AddFundsModal open={showAddFundsModal} onOpenChange={setShowAddFundsModal} />}
    </Card>
  );
}

export default BalanceCard;

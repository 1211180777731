import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover";
import Icon from "./ui/icon";

function LanguageSelector() {
  const { i18n } = useTranslation();

  return (
    <Popover>
      <PopoverTrigger className="hidden lg:flex items-center gap-1.5">
        <div>{i18n.language.toUpperCase()}</div>
        <div>
          <Icon name="ChevronDown" className="text-muted-foreground w-4 h-4" />
        </div>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col gap-2 z-50 font-medium border p-4 rounded">
        <button
          type="button"
          onClick={() => i18n.changeLanguage("en")}
          className={classNames(
            "border border-transparent focus:outline-none text-muted-foreground hover:text-foreground transition",
            i18n.language === "en" ? "text-foreground" : "",
          )}
        >
          English (EN)
        </button>
        <button
          type="button"
          onClick={() => i18n.changeLanguage("ru")}
          className={classNames(
            "border border-transparent focus:outline-none text-muted-foreground hover:text-foreground transition",
            i18n.language === "ru" ? "text-foreground" : "",
          )}
        >
          Русский (RU)
        </button>
      </PopoverContent>
    </Popover>
  );
}

export default LanguageSelector;

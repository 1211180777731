import React from "react";
import { cn } from "../../lib/utils";

const Input = React.forwardRef((props, ref) => {
  const { className, type, ...restProps } = props;
  return (
    <input
      ref={ref}
      type={type}
      className={cn(
        "flex h-10 w-full rounded-md border border-input bg-card px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus:ring-primary disabled:cursor-not-allowed disabled:opacity-50",
        className,
      )}
      {...restProps}
    />
  );
});

Input.displayName = "Input";

export default Input;

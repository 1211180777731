/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from "react";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({});
  const [announcements, setAnnouncements] = useState([]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        announcements,
        setAnnouncements,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;

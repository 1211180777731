import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import React from "react";
import Icon from "./icon";

function CheckBox({ value, onValueChange }) {
  return (
    <CheckboxPrimitive.Root
      checked={value}
      onCheckedChange={onValueChange}
      className="w-4 h-4 border bg-background rounded focus:ring-primary focus:ring-1 grid place-items-center"
    >
      <CheckboxPrimitive.Indicator asChild>
        <Icon name="Check" className="w-3 h-3" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
}

export default CheckBox;

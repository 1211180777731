import { CopyButton } from "../ui/copy-button";

export function SubscriptionExampleCurlBox({ curlString }) {
  return (
    <div className="grow rounded">
      <div className="flex items-center bg-muted justify-between rounded-t">
        <span className="text-sm text-muted-foreground px-4 py-1">console</span>
        <CopyButton value={curlString} />
      </div>
      <div className="bg-black px-4 py-2 font-mono text-sm text-white rounded-b">{curlString}</div>
    </div>
  );
}

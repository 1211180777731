import classNames from "classnames";
import React from "react";
import { Trans } from "react-i18next";
import { NavLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import ModeToggle from "./mode-toggle";
import Icon from "./ui/icon";
import SidebarBanner from "./ui/sidebar-banner";

function Sidebar({ menuConfig }) {
  const { auth } = useAuth();
  const { sidebarBanner } = auth;
  const role = auth?.user.role === "Administrator" ? "admin" : auth?.user.manager ? "manager" : "user";

  return (
    <div className="flex flex-1 flex-col justify-between border-r">
      <div>
        <div className="d-flex flex-column pt-16">
          <ul>
            {menuConfig.map((item) => (
              <li key={item.name} className="py-3 font-medium">
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    isActive
                      ? "flex flex-row items-center border-l-4 border-primary text-foreground px-4 gap-x-2"
                      : "flex flex-row items-center text-muted-foreground px-5 hover:text-foreground gap-x-2 transition"
                  }
                >
                  <Icon name={item.icon} className="w-5 h-5" />
                  <Trans i18nKey={`dashboard.${role}.navigation.${item.name}`} />
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex flex-col items-center">
        {sidebarBanner?.enabled && (
          <SidebarBanner title={sidebarBanner.title} description={sidebarBanner.description} />
        )}
        <div className="flex items-center gap-2">
          <div>
            <ModeToggle />
          </div>
        </div>
        {process.env.REACT_APP_ === "true" ? (
          <div className="tracking-tight text-xs uppercase text-red-500">TEST MODE</div>
        ) : (
          (role === "admin" || auth?.user.manager) && (
            <div className={classNames("tracking-tight text-xs uppercase text-center py-1.5 text-muted-foreground")}>
              ADMINISTRATION - 02/24
            </div>
          )
        )}
        {role !== "admin" && !auth?.user.manager && (
          <div className="tracking-tight text-xs uppercase text-center py-1.5 text-muted-foreground">
            THUNDERPROXY v2.2.8a
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;

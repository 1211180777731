import { Slider, SliderRange, SliderThumb, SliderTrack } from "@radix-ui/react-slider";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { calculateDiscountPercentage } from "../../lib/utils";
import { pushViewItemEvent } from "../GTM";
import PurchasePlanModal from "../modals/plan-purchase";
import { Badge } from "../ui/badge";
import { buttonVariants } from "../ui/button";
import { Card, CardContent, CardHeader } from "../ui/card";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../ui/collapsible";
import Icon from "../ui/icon";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { calculateUnitPrice } from "../../lib/store";

const listFormatter = new Intl.ListFormat("en", { style: "long", type: "conjunction" });

// eslint-disable-next-line consistent-return
const typeFeatures = (type) => {
  if (type === "residential") {
    return (
      <>
        <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
          <Icon name="Network" className="w-5 h-5" />
          <Trans i18nKey="dashboard.user.store.residential.features.pool" />
        </div>
        <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
          <Icon name="Globe2" className="w-5 h-5" />
          <Trans i18nKey="dashboard.user.store.residential.features.countries" />
        </div>
        <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
          <Icon name="SlidersHorizontal" className="w-5 h-5" />
          <Trans i18nKey="dashboard.user.store.residential.features.sessions" />
        </div>
        <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
          <Icon name="Infinity" className="w-5 h-5" />
          <Trans i18nKey="dashboard.user.store.residential.features.threads" />
        </div>
        <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
          <Icon name="MapPin" className="w-5 h-5" />
          <Trans i18nKey="dashboard.user.store.residential.features.targeting" />
        </div>
        <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
          <Icon name="ShoppingCart" className="w-5 h-5" />
          <Trans i18nKey="dashboard.user.store.residential.features.sneaker" />
        </div>
      </>
    );
  }
  if (type === "datacenter") {
    return (
      <>
        <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
          <Icon name="Network" className="w-5 h-5" />
          <Trans i18nKey="dashboard.user.store.datacenter.features.pool" />
        </div>
        <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
          <Icon name="Globe2" className="w-5 h-5" />
          <Trans i18nKey="dashboard.user.store.datacenter.features.countries" />
        </div>
        <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
          <Icon name="SlidersHorizontal" className="w-5 h-5" />
          <Trans i18nKey="dashboard.user.store.datacenter.features.sessions" />
        </div>
        <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
          <Icon name="Infinity" className="w-5 h-5" />
          <Trans i18nKey="dashboard.user.store.datacenter.features.threads" />
        </div>
      </>
    );
  }
};

function UserStoreCategoryTab({ data, category }) {
  const [selectedIndex, setSelectedIndex] = useState([0]);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);

  const handleIncreasePackageSize = () => {
    if (selectedIndex[0] < data.items.length - 1) {
      const currentSliderValue = selectedIndex[0];
      setSelectedIndex([currentSliderValue + 1]);
    }
  };

  const handleDecreasePackageSize = () => {
    if (selectedIndex[0] > 0) {
      const currentSliderValue = selectedIndex[0];
      setSelectedIndex([currentSliderValue - 1]);
    }
  };

  const selectedItem = data.items[selectedIndex[0]];

  const unitPrice = calculateUnitPrice(selectedItem?.price, selectedItem?.quantity / 1024 / 1024 / 1024);

  const product = {
    id: `rotating-${category}`,
    name: `Rotating ${category.toUpperCase()} IPv4 Proxies`,
    category: `rotating`,
    variant: category,
    price: unitPrice,
  };

  useEffect(() => {
    if (selectedItem) {
      pushViewItemEvent(product, selectedItem?.price);
    }
  }, [selectedItem, category]);

  return (
    <TabsContent className="grid lg:grid-cols-2 gap-6" value={category}>
      <div className="space-y-6">
        <Card>
          <CardHeader>
            <div className="flex flex-col gap-3 items-center">
              <div className="p-3 rounded">
                <Icon name="Router" className="w-8 h-8" />
              </div>
              <div className="text-2xl font-medium">
                <Trans i18nKey={`dashboard.user.store.${category}.name`} />
              </div>
              <div className="text-muted-foreground text-center">
                <Trans i18nKey={`dashboard.user.store.${category}.description`} />
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="space-y-3">
              <div className="text-xl font-medium">
                <Trans i18nKey="dashboard.user.store.labels.features" />
              </div>
              <div className="grid lg:grid-cols-3 gap-3">{typeFeatures(category)}</div>
            </div>
          </CardContent>
        </Card>
        <Collapsible defaultOpen>
          <CollapsibleTrigger>
            <div>
              <Trans i18nKey="dashboard.user.store.labels.blacklist" />
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="p-3 text-sm">
            <p>
              <Trans i18nKey="dashboard.user.store.blacklist.disclaimer" />
            </p>
            <ul className="list-disc list-inside">
              <li>
                <Trans i18nKey="dashboard.user.store.blacklist.list.item1" />
              </li>
              <li>
                <Trans i18nKey="dashboard.user.store.blacklist.list.item2" />
              </li>
              <li>
                <Trans i18nKey="dashboard.user.store.blacklist.list.item3" />
              </li>
              <li>
                <Trans i18nKey="dashboard.user.store.blacklist.list.item4" />
              </li>
            </ul>
            <p>
              <Trans i18nKey="dashboard.user.store.blacklist.contact.part1" />
              <a href="#" className="text-primary hover:text-primary/95 transition-colors" id="intercomContact">
                <Trans i18nKey="dashboard.user.store.blacklist.contact.chatAction" />
              </a>
              <Trans i18nKey="dashboard.user.store.blacklist.contact.part2" />
              <a href="mailto:help@thunderproxy.com" className="text-primary hover:text-primary/95 transition-colors">
                help@thunderproxy.com
              </a>
              .
            </p>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTrigger>
            <Trans i18nKey="dashboard.user.store.labels.countries" /> ({data.countries.length})
          </CollapsibleTrigger>
          <CollapsibleContent className="p-3 text-sm text-justify">
            {listFormatter.format(data.countries)}.
          </CollapsibleContent>
        </Collapsible>
      </div>
      <div className="space-y-6">
        <Card>
          <CardHeader className="flex flex-col gap-3 justify-between items-center">
            <div className="text-2xl font-medium">
              <Trans i18nKey="dashboard.user.store.labels.purchaseHeading" />
              <Trans i18nKey={`dashboard.user.store.${category}.type`} />
            </div>
            <div className="text-xs text-muted-foreground">
              <Trans i18nKey="dashboard.user.store.labels.purchaseCaption" />
            </div>
          </CardHeader>
          <CardContent>
            <div className="w-full gap-3 py-3">
              <div className="flex items-center justify-between px-12">
                <button
                  type="button"
                  disabled={selectedIndex[0] < 1}
                  onClick={() => handleDecreasePackageSize()}
                  className="rounded-full p-1 bg-muted hover:bg-muted/90 transition-colors"
                >
                  <Icon name="Minus" className="w-6 h-6" />
                </button>
                <div className="flex items-center gap-1 text-2xl">
                  <div className="font-medium text-primary">{data.items[selectedIndex[0]]?.bandwidth}</div>
                  <div className="text-muted-foreground">
                    <Trans i18nKey="globals.gb" />
                  </div>
                </div>
                <button
                  type="button"
                  disabled={selectedIndex[0] === data.items.length - 1}
                  onClick={() => handleIncreasePackageSize()}
                  className="rounded-full p-1 bg-muted hover:bg-muted/90 transition-colors"
                >
                  <Icon name="Plus" className="w-6 h-6" />
                </button>
              </div>
              <div className="py-6">
                <Slider
                  className="w-full relative flex items-center select-none touch-none"
                  value={selectedIndex}
                  min={0}
                  max={data.items.length - 1}
                  step={1}
                  onValueChange={setSelectedIndex}
                >
                  <SliderTrack className="grow relative rounded-full h-1 bg-secondary">
                    <SliderRange className="absolute bg-primary rounded-full h-full" />
                  </SliderTrack>
                  <SliderThumb className="block w-5 h-5 bg-primary rounded-full" />
                </Slider>
              </div>
              <div className="flex items-start gap-3 p-3 bg-popover">
                <Icon name="Info" className="w-4 h-4 text-muted-foreground" />
                <div className="text-xs text-muted-foreground">
                  <Trans i18nKey="dashboard.user.store.labels.topupInformation" />
                </div>
              </div>
              <div className="py-6">
                <div className="flex justify-between items-center">
                  <div className="font-medium">
                    <Trans i18nKey="dashboard.user.store.labels.perGb" />
                  </div>
                  <div className="flex items-center gap-1.5">
                    {selectedIndex[0] !== 0 && (
                      <Badge variant="outline">
                        {calculateDiscountPercentage(
                          data.items[0].price,
                          selectedItem.price / selectedItem.bandwidth,
                        ).toFixed(0)}
                        % OFF
                      </Badge>
                    )}
                    <div className="font-medium text-green-500">
                      ${(selectedItem.price / selectedItem.bandwidth).toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="font-medium">
                    <Trans i18nKey="dashboard.user.store.labels.packageSize" />
                  </div>
                  <div>
                    {data.items[selectedIndex[0]].bandwidth} <Trans i18nKey="globals.gb" />
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="font-medium">
                    <Trans i18nKey="dashboard.user.store.labels.duration" />
                  </div>
                  <div>
                    30 <Trans i18nKey="globals.days" />
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              onClick={() => setShowPurchaseModal(true)}
              className={classNames("w-full", buttonVariants())}
            >
              <Trans i18nKey="dashboard.user.store.labels.action" /> - ${data.items[selectedIndex[0]].price.toFixed(2)}
            </button>
          </CardContent>
        </Card>
        {/* <CampaignBanner /> */}
      </div>
      {showPurchaseModal && (
        <PurchasePlanModal plan={selectedItem} open={showPurchaseModal} onOpenChange={setShowPurchaseModal} />
      )}
    </TabsContent>
  );
}

function RotatingStoreView({ data }) {
  const [selectedCategory, setSelectedCategory] = useState("residential");

  return (
    <Tabs className="w-full space-y-6" value={selectedCategory} onValueChange={setSelectedCategory}>
      <TabsList className="font-medium">
        {Object.keys(data).map((category) => (
          <TabsTrigger key={category} value={category}>
            <div className="flex items-center gap-1">
              <Trans i18nKey={`dashboard.user.store.${category}.type`} />
              {category === "datacenter" ? <Badge>NEW</Badge> : null}
            </div>
          </TabsTrigger>
        ))}
      </TabsList>
      {Object.keys(data).map((category) => (
        <UserStoreCategoryTab key={category} category={category} data={data[category]} />
      ))}
    </Tabs>
  );
}

export default RotatingStoreView;

import React from "react";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Icon from "./ui/icon";

function BalanceBlock() {
  const { auth } = useAuth();

  return (
    <Link to="/wallet">
      <div className="group inline-flex items-center px-4 py-2 text-sm rounded-full bg-muted gap-x-1">
        <span className="text-foreground font-semibold">${auth?.user.balance.toFixed(2)}</span>
        <span className="text-foreground ml-1 p-1 rounded-full bg-primary cursor-pointer hover:bg-primary/75 transition">
          <Icon name="Plus" className="w-3 h-3 text-primary-foreground" />
        </span>
      </div>
    </Link>
  );
}

export default BalanceBlock;

import classNames from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { Button } from "../../components/ui/button";
import Icon from "../../components/ui/icon";
import Input from "../../components/ui/input";
import Label from "../../components/ui/label";
import { toast } from "../../components/ui/use-toast";
import axios from "../../config/axios";
import Turnstile, { useTurnstile } from "react-turnstile";

function RecoverPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [errorVal, setError] = useState("");
  const turnstile = useTurnstile();
  const [captchaToken, setCaptchaToken] = useState();
  const api = axios;

  const handleFormSubmit = async (formData) => {
    setIsLoading(true);

    api
      .post("/auth/forgot-password", {
        ...formData,
        captchaToken,
      })
      .then(() => {
        toast({
          title: "A link to reset your password has been sent to your email.",
          variant: "positive",
        });
      })
      .catch((err) => {
        setError(err.response.data.message);
      })
      .finally(() => {
        turnstile.reset();
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-center sm:w-[360px] md:h-screen lg:py-0 mx-auto">
      <Link to="/" className="flex items-center mb-6 text-2xl font-semibold gap-3">
        <img
          className={classNames("mx-auto h-8 w-auto", isLoading && "animate-ping")}
          src="/images/logo.svg"
          alt="thunderproxy"
        />
        thunderproxy
      </Link>
      <Alert>
        <AlertTitle>Our e-mails may be wrongfully sent into spam.</AlertTitle>
        <AlertDescription>
          If that happens, press &quot;Mark as non-spam&quot; or similar to keep receiving e-mails from us.
        </AlertDescription>
      </Alert>
      <div className="w-full border rounded-lg shadow mt-6 sm:max-w-md xl:p-0">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl">
            <Trans i18nKey="auth.reset.heading" />
          </h1>
          <p className="text-sm">
            <Trans i18nKey="auth.reset.caption" />
          </p>
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(handleFormSubmit)}>
            {errorVal && (
              <Alert variant="destructive" className="flex flex-row gap-2 items-center">
                <Icon name="AlertTriangle" className="w-4 h-4" />
                <AlertTitle>No user with provided e-mail</AlertTitle>
              </Alert>
            )}
            <div>
              <Label>
                <Trans i18nKey="auth.email.label" />
              </Label>
              <Input
                type="email"
                placeholder="name@company.com"
                {...register("email", { required: "This field is required" })}
              />
            </div>
            <Turnstile
              autoResetOnExpire
              fixedSize
              sitekey={process.env.REACT_APP_CLOUDFLARE_CAPTCHA_KEY}
              onVerify={(token) => {
                setCaptchaToken(token);
              }}
            />
            <Button disabled={isLoading || !captchaToken}>
              <Trans i18nKey="auth.reset.action" />
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RecoverPassword;

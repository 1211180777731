import { useContext, useState } from "react";
import { DataTable } from "./proxies/data-table";
import { columns } from "./proxies/columns";
import { SubscriptionContext } from "../ui/subscription-provider";
import { generateSubscriptionProxies } from "../../lib/subscription";
import { SubscriptionExportFileModal } from "./subscription-export-file-view";

function SubscriptionStaticProxyList() {
  const { subscription } = useContext(SubscriptionContext);
  const { subInfo, authentication, externalIps } = subscription;

  const [open, setOpen] = useState(false);

  const data = generateSubscriptionProxies(subInfo, authentication, null, null, externalIps);
  return (
    <div className="space-y-3">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Proxy List</h1>
        <div className="flex items-center justify-end gap-2">
          <SubscriptionExportFileModal open={open} onOpenChange={setOpen} />
        </div>
      </div>
      <DataTable columns={columns()} data={data} />
    </div>
  );
}

export default SubscriptionStaticProxyList;

import * as TabsPrimitive from "@radix-ui/react-tabs";
import classNames from "classnames";
import { forwardRef } from "react";

const Tabs = TabsPrimitive.Root;

const TabsList = forwardRef(({ className, children, ...props }, ref) => (
  <TabsPrimitive.List ref={ref} className={classNames("flex justify-center items-start gap-6", className)} {...props}>
    {children}
  </TabsPrimitive.List>
));

const TabsTrigger = forwardRef(({ className, children, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={classNames(
      "focus:outline-none box-border border-b border-transparent data-[state=inactive]:text-muted-foreground data-[state=active]:border-primary",
      className,
    )}
    {...props}
  >
    {children}
  </TabsPrimitive.Trigger>
));

const TabsContent = forwardRef(({ className, children, ...props }, ref) => (
  <TabsPrimitive.Content ref={ref} className={className} {...props}>
    {children}
  </TabsPrimitive.Content>
));

Tabs.displayName = "Tabs";
TabsList.displayName = "TabsList";
TabsTrigger.displayName = "TabsTrigger";
TabsContent.displayName = "TabsContent";

export { Tabs, TabsContent, TabsList, TabsTrigger };

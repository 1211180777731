import classNames from "classnames";
import { format } from "date-fns";
import React from "react";
import { Trans } from "react-i18next";
import { formatCurrency } from "../../utils/Utils";
import { Button } from "./button";
import {
  Credenza,
  CredenzaBody,
  CredenzaClose,
  CredenzaContent,
  CredenzaDescription,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
  CredenzaTrigger,
} from "./credenza";
import Icon from "./icon";

function TransactionDetails({ invoice }) {
  const isTopup = invoice.topup;

  return (
    <Credenza>
      <CredenzaTrigger asChild>
        <button type="button" className="focus:outline-none text-muted-foreground hover:text-primary transition">
          <Icon name="ArrowRight" className="w-4 h-4" />
        </button>
      </CredenzaTrigger>
      <CredenzaContent>
        <CredenzaHeader>
          <CredenzaTitle>
            <Trans
              i18nKey={isTopup ? "dashboard.user.wallet.history.topup" : "dashboard.user.wallet.history.purchase"}
            />
          </CredenzaTitle>
          <CredenzaDescription className="text-muted-foreground">
            {isTopup ? "+" : "-"} {formatCurrency.format(invoice.price)}
          </CredenzaDescription>
        </CredenzaHeader>
        <CredenzaBody>
          <div className="space-y-4">
            {[
              { label: "Invoice ID", value: invoice._id },
              { label: "Created at", value: format(new Date(invoice.createdAt), "dd MMM yyyy, HH:mm") },
              { label: "Amount", value: formatCurrency.format(invoice.price) },
              { label: "Paid via", value: invoice.paymentProcessor.toUpperCase() },
              { label: "Description", value: invoice.description || "No description available" },
            ].map((item, index) => (
              <div
                key={index}
                className="flex flex-col md:flex-row md:justify-between md:items-center border-b border-gray-700 pb-2 last:border-none"
              >
                <div className="font-medium">{item.label}</div>
                <div className="text-muted-foreground mt-1 md:mt-0 md:text-right">{item.value}</div>
              </div>
            ))}
          </div>
        </CredenzaBody>
        <CredenzaFooter>
          <CredenzaClose asChild>
            <Button variant="secondary">Close</Button>
          </CredenzaClose>
        </CredenzaFooter>
      </CredenzaContent>
    </Credenza>
  );
}

function Transaction({ invoice }) {
  const isTopup = invoice.topup;

  return (
    <>
      <li
        key={invoice._id}
        className="flex justify-between items-center gap-x-6 p-3 bg-card first:rounded-t last:rounded-b"
      >
        <div className="flex flex-row gap-x-4 items-center">
          <Icon
            name={isTopup ? (invoice.paid ? "Plus" : "Hourglass") : invoice.paid ? "Minus" : "Hourglass"}
            className={classNames(
              "rounded-full p-2 w-10 h-10",
              isTopup
                ? invoice.paid
                  ? "bg-green-500/10 text-green-700"
                  : "bg-yellow-500/10 text-yellow-700"
                : invoice.paid
                  ? "bg-red-500/10 text-red-700"
                  : "bg-yellow-500/10 text-yellow-700",
            )}
          />
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-medium">
              <Trans
                i18nKey={isTopup ? "dashboard.user.wallet.history.topup" : "dashboard.user.wallet.history.purchase"}
              />
            </p>
            <p className="truncate text-xs leading-5 text-muted-foreground">
              {format(new Date(invoice.createdAt), "MM/dd/yyyy HH:mm")}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <div className="font-medium">
            {isTopup ? "+" : "-"} {formatCurrency.format(invoice.price)}
          </div>
          <TransactionDetails invoice={invoice} />
        </div>
      </li>
    </>
  );
}

export default Transaction;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import Icon from "./ui/icon";
import useLogout from "../hooks/useLogout";
import { show } from "@intercom/messenger-js-sdk";

function UserBlock() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  const logout = useLogout();

  const handleSignout = async () => {
    await logout();
    navigate("/");
  };

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger>
        <div className="flex items-center justify-center font-semibold w-9 h-9 rounded-full bg-muted p-2 hover:ring-1 hover:ring-offset-1 hover:ring-white transition">
          <Icon name="User" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={8} align="end">
        <div className="flex justify-start items-center p-2 gap-x-1.5">
          <div className="grid place-items-center bg-muted justify-center font-semibold w-9 h-9 rounded-full border">
            {auth?.user.username[0]}
          </div>
          <div>
            {auth?.user.username && <p className="font-medium">{auth?.user.username}</p>}
            {auth?.user.email && <p className="w-[128px] truncate text-xs text-muted-foreground">{auth?.user.email}</p>}
          </div>
        </div>
        <DropdownMenuSeparator />
        {auth?.user?.role !== "Administrator" && (
          <DropdownMenuItem>
            <Icon name="Settings" className="w-4 h-4 mr-2" />
            <Link to="/profile">Settings</Link>
          </DropdownMenuItem>
        )}
        {auth?.user?.role !== "Administrator" && (
          <DropdownMenuItem onClick={() => show()}>
            <Icon name="MessageCircleQuestion" className="w-4 h-4 mr-2" />
            Live chat
          </DropdownMenuItem>
        )}
        {auth?.user.canRefer && (
          <DropdownMenuItem>
            <Icon name="Share2" className="w-4 h-4 mr-2" />
            <Link to="/referrals">Affiliate</Link>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onSelect={handleSignout}>
          <Icon name="LogOut" className="w-4 h-4 mr-2" />
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default UserBlock;

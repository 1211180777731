import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "../../ui/alert-dialog";
import { buttonVariants } from "../../ui/button";
import Icon from "../../ui/icon";
import Label from "../../ui/label";
import { toast } from "../../ui/use-toast";

function EditPlanModal({ plan, open, onOpenChange, callbackFn }) {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      title: plan?.title || null,
      provider: plan?.type || "thunder",
      price: plan?.price || 0,
      duration: plan?.duration || 5184000,
      bandwidth: plan?.bandwidth,
      enabled: plan?.enabled,
      order: plan?.order,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const api = useAxiosPrivate();

  const onSubmit = (formData) => {
    setIsLoading(true);
    (plan ? api.patch(`/admin/plans/${plan?._id}`, formData) : api.post("/admin/plans", formData))
      .then(() => {
        toast({
          title: "plan details edited successfully.",
          variant: "positive",
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        callbackFn();
        onOpenChange(false);
      });
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent className="bg-popover border border-slate-700">
          <AlertDialogHeader>
            <AlertDialogTitle>
              {plan ? (
                <p>
                  Modify details for
                  <span className="text-blue-500">
                    {plan.title} - {plan.readableType} ({plan.bandwidth}
                    GB)
                  </span>
                </p>
              ) : (
                "Create a new plan"
              )}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-4 mb-4 sm:grid-cols-2">
              <div className="col-span-2">
                <div>
                  <Label>Title</Label>
                  <input
                    type="text"
                    className="text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 text-white focus:ring focus:ring-slate-500 focus:border-slate-500 focus:outline-none"
                    {...register("title")}
                  />
                </div>
              </div>
              <div>
                <Label>Plan Type</Label>
                <select
                  id="category"
                  className="text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 text-white focus:ring focus:ring-slate-500 focus:border-slate-500 focus:outline-none"
                  {...register("provider")}
                >
                  <option value="thunder">Residential</option>
                  <option value="thunder_datacenter">Datacenter</option>
                </select>
              </div>
              <div>
                <div>
                  <Label>Bandwidth (GB)</Label>
                  <input
                    type="text"
                    className="text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 text-white focus:ring focus:ring-slate-500 focus:border-slate-500 focus:outline-none"
                    {...register("bandwidth")}
                  />
                </div>
              </div>
              <div>
                <div>
                  <Label>Display order</Label>
                  <input
                    type="text"
                    className="text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 text-white focus:ring focus:ring-slate-500 focus:border-slate-500 focus:outline-none"
                    {...register("order")}
                  />
                </div>
              </div>
              <div>
                <div>
                  <Label>Price</Label>
                  <input
                    type="text"
                    className="text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 text-white focus:ring focus:ring-slate-500 focus:border-slate-500 focus:outline-none"
                    {...register("price")}
                  />
                </div>
              </div>
              <div>
                <Label>Duration (seconds)</Label>
                <input
                  type="text"
                  className="text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 text-white focus:ring focus:ring-slate-500 focus:border-slate-500 focus:outline-none"
                  {...register("duration")}
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <div className="flex items-center gap-1.5">
                  <input
                    type="checkbox"
                    className="text-sm rounded-lg block p-2.5 bg-gray-700 border-gray-600 text-white focus:ring focus:ring-slate-500 focus:border-slate-500 focus:outline-none"
                    {...register("enabled")}
                  />
                  Available for purchase
                </div>
              </div>
              <div className="flex items-center justify-end gap-3">
                <button
                  type="button"
                  className={buttonVariants()}
                  onClick={(event) => {
                    onOpenChange(false);
                    event.preventDefault();
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className={buttonVariants({ variant: "confirm" })}>
                  {isLoading && <Icon name="Loader" className="mr-2 h-4 w-4 animate-spin" />}
                  Submit
                </button>
              </div>
            </div>
          </form>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}

export default EditPlanModal;

import React from "react";

function EmptyState() {
  return (
    <div className="flex min-h-[200px] flex-col items-center justify-center text-center animate-in fade-in-50">
      <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center text-muted-foreground">
        <h2 className="mt-6 text-xl font-semibold">No results</h2>
        <p className="mb-8 mt-2 text-center text-xs font-normal leading-6 text-muted-foreground">Nothing to see here</p>
      </div>
    </div>
  );
}

export default EmptyState;

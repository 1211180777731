import classNames from "classnames";
import { Check, X } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../components/ui/button";
import DataTableWithPagination from "../../../../components/ui/data-table-pagination";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import Label from "../../../../components/ui/label";
import LoadingState from "../../../../components/ui/loading-state";
import DashboardHeader from "../../../../components/ui/page-header";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../components/ui/select";
import { toast } from "../../../../components/ui/use-toast";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { formatCurrency } from "../../../../utils/Utils";

function UserRequestCampaignPayoutModal({ open, onOpenChange, data }) {
  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch,
  } = useForm();
  const paymentMethod = watch("paymentMethod", null);

  const api = useAxiosPrivate();

  const onSubmit = (formData) => {
    api
      .post(`/public/campaigns/${data._id}/payout`, formData)
      .then(() => {
        toast({
          title: "Payout requested with success",
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Error while requesting payout",
          ...(err.response?.data?.message && { description: err.response.data.message }),
        });
      })
      .finally(() => {});
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button disabled={data.availableToPayout < 20} variant="outline">
          Request payout
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Request payout</DialogTitle>
          <DialogDescription>Request a payout for {data.name}</DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="text-center">
            <span className="text-xs text-muted-foreground">Available to payout *</span>
            <p className="mt-2 font-medium">{formatCurrency.format(data.availableToPayout)}</p>
          </div>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Payment method
              </Label>
              <Controller
                control={control}
                rules={{ required: true }}
                name="paymentMethod"
                render={({ field }) => (
                  <Select onValueChange={field.onChange} {...field}>
                    <SelectTrigger className="col-span-3">
                      <SelectValue placeholder="Select one..." />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="balance">Wallet</SelectItem>
                      <SelectItem value="cryptocurrency">Cryptocurrency (soon)</SelectItem>
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
            {paymentMethod === "cryptocurrency" && (
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Cryptocurrency
                </Label>
                <Controller
                  control={control}
                  name="cryptocurrency"
                  rules={{ required: paymentMethod === "cryptocurrency" }}
                  render={({ field }) => (
                    <Select onValueChange={field.onChange} {...field}>
                      <SelectTrigger className="col-span-3">
                        <SelectValue placeholder="Select one..." />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="BTC">Bitcoin (BTC)</SelectItem>
                        <SelectItem value="ETH">Ethereum (ETH)</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                />
              </div>
            )}
          </div>
          <p className="text-xs text-muted-foreground">
            * Your total payout amount may differ from this amount and you will be prompted with the confirmed total
            once the request is submitted.
          </p>
          <DialogFooter>
            <Button disabled={!isValid} type="submit">
              Submit
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const createUserCampaignCommissionsDesktopCols = () => [
  {
    id: "deleted",
    accessorFn: "deleted",
    header: "Eligible",
    cell: ({ row }) =>
      row.original.deleted || row.original.paidOut ? (
        <X className="w-3 h-3 text-muted-foreground" />
      ) : (
        <Check className="w-3 h-3 text-green-400" />
      ),
  },
  {
    id: "user",
    accessorKey: "invoice.user",
    header: "From",
  },
  {
    id: "invoice",
    accessorKey: "invoice._id",
    header: "Purchase",
    cell: ({ row }) => <span>{row.original.invoice.title}</span>,
  },
  {
    id: "amount",
    accessorKey: "amount",
    header: "Amount",
    cell: ({ row }) => (
      <span className="text-sm font-mono text-right">+ {formatCurrency.format(row.original.amount)}</span>
    ),
  },
];

function UserCampaignDetailPageView({ data }) {
  const [isLoading, setIsLoading] = useState(false);
  const [commissionsData, setCommissionsData] = useState();

  const desktopCols = createUserCampaignCommissionsDesktopCols();

  const api = useAxiosPrivate();

  const fetchData = () => {
    api
      .get(`/public/campaigns/${data._id}/commissions`)
      .then((res) => {
        setCommissionsData(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error while loading campaign commissions.",
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="space-y-6">
      <DashboardHeader heading={`Campaign "${data.name}"`}>
        <UserRequestCampaignPayoutModal data={data} />
      </DashboardHeader>
      <div className="space-y-3">
        <p>
          You can request a payout once you hit the 20.00$ mark. Commissions for confirmed purchases are credited
          instantly and can be seen in this page.
        </p>
        <div className="flex items-center justify-evenly py-8">
          <div className="text-center">
            <span className="text-xs text-muted-foreground">Users referred</span>
            <p className="mt-2 font-medium">{data.totalUsers}</p>
          </div>
          <div className="text-center">
            <span className="text-xs text-muted-foreground">Purchases</span>
            <p className="mt-2 font-medium">{data.totalCommissions}</p>
          </div>
          <div className="text-center">
            <span className="text-xs text-muted-foreground">Available to payout</span>
            <p className={classNames("mt-2 font-medium", data.availableToPayout > 20 && "text-green-600")}>
              {formatCurrency.format(data.availableToPayout)}
            </p>
          </div>
        </div>
        <div className="space-y-3">
          <span className="font-medium">Commissions</span>
          {isLoading ? (
            <LoadingState />
          ) : commissionsData ? (
            <DataTableWithPagination columns={desktopCols} data={commissionsData} />
          ) : (
            <div>Error</div>
          )}
        </div>
      </div>
    </div>
  );
}

function UserCampaignDetailPage() {
  const { campaignId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const navigate = useNavigate();

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get(`/public/campaigns/${campaignId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Error while loading campaign.",
        });
        navigate("/referrals");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? LoadingState : data ? <UserCampaignDetailPageView data={data} /> : <div>Error</div>;
}

export default UserCampaignDetailPage;

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../../components/pagination";
import { AdminSubscriptionsView } from "../../../components/subscriptions/admin/subscriptions-view";
import Input from "../../../components/ui/input";
import LoadingState from "../../../components/ui/loading-state";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { update } from "@intercom/messenger-js-sdk";

function AdminSubsTextSearch({ value, onValueChange, ...props }) {
  const [input, setInput] = useState(value);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onValueChange(input);
    }, 750);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [input]);

  return (
    <Input
      placeholder="Search by owner, authuser or id..."
      value={input}
      onChange={(e) => setInput(e.target.value)}
      {...props}
    />
  );
}

function AdminSubscriptionsPage() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(1);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState(searchParams.get("search") || null);

  const api = useAxiosPrivate();

  const [page, setPage] = useState(searchParams.get("page") || 1);
  const size = searchParams.get("size") ?? 10;

  const fetchData = () => {
    api
      .get("/admin/subscriptions", {
        params: searchParams,
      })
      .then((res) => {
        setData(res.data.payload.subscriptions);
        setTotal(res.data.pager.total);
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePageChange = (newValue) => {
    setPage(newValue);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    update({
      hide_default_launcher: true,
    });
    searchParams.set("page", page);
    searchParams.set("size", 10);
    if (search) {
      searchParams.set("search", search);
    } else {
      searchParams.delete("search");
    }
    setSearchParams(searchParams);
    fetchData();
  }, [page, search]);

  return (
    <section className="space-y-6 animate-in fade-in">
      <div className="space-y-3">
        <h1 className="font-heading text-2xl">Subscriptions</h1>
        <div className="mt-2 flex items-center gap-2 flex-wrap lg:justify-between">
          <div className="w-64">
            <AdminSubsTextSearch value={search} onValueChange={setSearch} />
          </div>
        </div>
      </div>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          <AdminSubscriptionsView data={data} callbackFn={fetchData} />
          <Pagination
            onPageChange={handlePageChange}
            totalCount={total}
            currentPage={parseInt(page, 10)}
            pageSize={parseInt(size, 10)}
          />
        </>
      )}
    </section>
  );
}
export default AdminSubscriptionsPage;

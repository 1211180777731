import React, { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../../components/pagination";
import Input from "../../../components/ui/input";
import LoadingState from "../../../components/ui/loading-state";
import AdminUsersView from "../../../components/users/admin/users-view";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { update } from "@intercom/messenger-js-sdk";

function AdminUsersTextSearch({ value, onValueChange, ...props }) {
  const [input, setInput] = useState(value);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onValueChange(input);
    }, 750);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [input]);

  return <Input value={input} onChange={(e) => setInput(e.target.value)} {...props} />;
}

function AdminUsersPage() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(1);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(searchParams.get("page") ?? 1);
  const size = searchParams.get("size") ?? 10;

  const [search, setSearch] = useState(searchParams.get("search") || null);

  const api = useAxiosPrivate();

  const fetchData = () => {
    api
      .get("/admin/users", {
        params: searchParams,
      })
      .then((res) => {
        setData(res.data.payload.users);
        setTotal(res.data.pager.total);
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePageChange = (newValue) => {
    setPage(newValue);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    update({
      hide_default_launcher: true,
    });
    searchParams.set("page", page);
    searchParams.set("size", 10);
    if (search) {
      searchParams.set("search", search);
    } else {
      searchParams.delete("search");
    }
    setSearchParams(searchParams);
    fetchData();
  }, [page, search]);

  return (
    <section className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="font-heading text-2xl">Users</h1>
        <div>
          <AdminUsersTextSearch value={search} onValueChange={setSearch} />
        </div>
      </div>
      {isLoading ? (
        <LoadingState />
      ) : data ? (
        <>
          <AdminUsersView data={data} callbackFn={fetchData} />
          <Pagination
            onPageChange={handlePageChange}
            totalCount={total}
            currentPage={parseInt(page, 10)}
            pageSize={parseInt(size, 10)}
          />
        </>
      ) : (
        "Nothing to see here"
      )}
    </section>
  );
}
export default AdminUsersPage;

import { format } from "date-fns";
import { Check, ExternalLink, Trash } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { bytesToGB } from "../../../lib/utils";
import { formatCurrency } from "../../../utils/Utils";
import { Badge } from "../../ui/badge";
import DataTable from "../../ui/data-table";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../ui/dropdown-menu";
import Icon from "../../ui/icon";
import statusBadgeVariants from "../../ui/status-badge";
import DeleteInvoiceModal from "./modals/delete-invoice";
import MarkAsPaidModal from "./modals/mark-as-paid";

export function AdminInvoiceOperations({ data, callbackFn }) {
  const [showDeleteInvoiceModal, setShowDeleteInvoiceModal] = useState(false);
  const [showMarkAsPaidModal, setShowMarkAsPaidModal] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  // Fix for iOS mobile not opening
  // eslint-disable-next-line no-undef
  const touchDevice = "ontouchstart" in window;

  return (
    <>
      <DropdownMenu open={open} modal={false} onOpenChange={setOpen}>
        <DropdownMenuTrigger
          className="flex h-8 w-8 items-center justify-center rounded-md border transition-colors hover:bg-muted"
          {...(touchDevice
            ? {
                onPointerDown: (e) => e.preventDefault(),
                onClick: () => setOpen(!open),
              }
            : undefined)}
        >
          <div>
            <Icon name="MoreVertical" className="w-3 h-3" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            // eslint-disable-next-line no-undef
            onClick={() => navigator.clipboard.writeText(data._id)}
          >
            <Icon name="Clipboard" className="w-4 h-4 mr-2" />
            Copy ID
          </DropdownMenuItem>
          {data.subscription && (
            <DropdownMenuItem onClick={() => navigate(`/subscriptions/${data.subscription._id}`)}>
              <Icon name="Eye" className="w-4 h-4 mr-2" />
              View associated sub
            </DropdownMenuItem>
          )}
          <DropdownMenuItem onClick={() => setShowMarkAsPaidModal(true)} disabled={data.paid}>
            <Check className="w-4 h-4 mr-2" />
            Mark as paid
          </DropdownMenuItem>
          {!!data.invoiceLink && (
            <Link to={data.invoiceLink} target="_blank">
              <DropdownMenuItem>
                <ExternalLink className="w-4 h-4 mr-2" />
                View invoice link
              </DropdownMenuItem>
            </Link>
          )}
          <DropdownMenuItem onClick={() => setShowDeleteInvoiceModal(true)}>
            <Trash className="w-4 h-4 mr-2" />
            Delete invoice
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      {showMarkAsPaidModal && (
        <MarkAsPaidModal
          invoice={data}
          open={showMarkAsPaidModal}
          onOpenChange={setShowMarkAsPaidModal}
          callbackFn={callbackFn}
        />
      )}
      {showDeleteInvoiceModal && (
        <DeleteInvoiceModal
          invoice={data}
          open={showDeleteInvoiceModal}
          onOpenChange={setShowDeleteInvoiceModal}
          callbackFn={callbackFn}
        />
      )}
    </>
  );
}

function AdminInvoiceItem({ item, callbackFn }) {
  return (
    <div className="flex items-center gap-1.5 pl-3 py-3">
      <div className="grow flex flex-col justify-between gap-1.5">
        <div className="flex items-center justify-between">
          <div className="font-medium">{item._id}</div>
          <div>
            {item.paid && (
              <span className={statusBadgeVariants({ variant: "positive" })}>
                Completed
                <Icon name="Check" className="w-3 h-3" />
              </span>
            )}
            {item.unresolvedStatus && !item.paid && !item.pending && (
              <span className={statusBadgeVariants({ variant: "warning" })}>
                Payment issue
                <Icon name="AlertTriangle" className="w-3 h-3" />
              </span>
            )}
            {!item.paid && (
              <span className={statusBadgeVariants({ variant: "warning" })}>
                Unpaid
                <Icon name="Clock3" className="w-3 h-3" />
              </span>
            )}
          </div>
        </div>
        <div className="flex items-center text-muted-foreground gap-1">
          <Icon name="User" className="w-3 h-3" />
          {item.user?.username}
        </div>
        <div className="flex items-center justify-between">
          <div>{item.title}</div>
          {!item.topup && item.subscription && item.billingType === "gb" && (
            <div>
              {item.subscription?.bandwidthLeft > 0 ? bytesToGB(item.subscription.bandwidthLeft) : "0"}/
              {item.plan?.bandwidth.toFixed(2)}
              GB
            </div>
          )}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1.5">
            <Icon name="Dollar" className="w-3 h-3 text-muted-foreground" />
            {formatCurrency.format(item.price)}{" "}
            {item.paymentProcessor === "balance" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="Wallet" className="w-3 h-3" />
                Balance
              </div>
            )}
            {item.paymentProcessor === "cryptocurrency" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="Bitcoin" className="w-3 h-3" />
                Crypto
              </div>
            )}
            {item.paymentProcessor === "coingate" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="Bitcoin" className="w-3 h-3" />
                Coingate
              </div>
            )}
            {item.paymentProcessor === "card" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="CreditCard" className="w-3 h-3" />
                Card
              </div>
            )}
            {item.paymentProcessor === "trial" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="Gift" className="w-3 h-3" />
                Trial
              </div>
            )}
            {item.paymentProcessor === "admin" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="HelpingHand" className="w-3 h-3" />
                Admin
              </div>
            )}
          </div>
          <div>{format(new Date(item.createdAt), "dd MMM HH:mm")}</div>
        </div>
      </div>
      <div className="flex flex-row justify-end">
        <AdminInvoiceOperations data={item} callbackFn={callbackFn} />
      </div>
    </div>
  );
}

const createAdminInvoicesDesktopCols = (callbackFn) => [
  {
    accessorKey: "_id",
    header: "Invoice ID",
    cell: ({ row }) => (
      <div>
        <span className="truncate font-mono text-xs">
          ...
          {row.getValue("_id").slice(-8)}
        </span>
      </div>
    ),
  },
  {
    id: "user",
    header: "Username",
    cell: ({ row }) => {
      const invoice = row.original;
      return (
        <Link
          to={`/users/${invoice.user?._id}`}
          className="font-medium text-primary hover:text-primary/95 transition-colors hover:border-b border-dotted"
        >
          {invoice.user?.username ? invoice.user.username : "N/A"}
        </Link>
      );
    },
  },
  {
    accessorKey: "amount",
    header: "Amount",
    cell: ({ row }) => {
      const item = row.original;
      return <div className="text-right font-semibold">{formatCurrency.format(item.price)}</div>;
    },
  },
  {
    id: "status",
    header: "Status",
    cell: ({ row }) => {
      const item = row.original;
      // Paid correctly
      if (item.paid) {
        return (
          <Badge variant="secondary">
            Completed
            <Icon name="Check" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      // Underpayment, overpayment
      if (item.unresolvedStatus) {
        return (
          <Badge>
            Payment issue
            <Icon name="AlertTriangle" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      // Payment detected, awaiting confirmation
      if (item.pending) {
        return (
          <Badge variant="outline">
            Pending
            <Icon name="Hourglass" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      // No payment has been detected yet
      if (!item.paid) {
        return (
          <Badge variant="outline">
            Unpaid
            <Icon name="Clock3" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      return <span>Unknown</span>;
    },
  },
  {
    id: "title",
    header: "Contents",
    cell: ({ row }) => <div>{row.original.title}</div>,
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    cell: ({ row }) => {
      const item = row.original;
      return format(new Date(item.createdAt), "dd MMM, HH:mm");
    },
  },
  {
    accessorKey: "paymentProcessor",
    header: "Processor",
    cell: ({ row }) => {
      const item = row.original;
      if (item.paymentProcessor === "balance") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="Wallet" className="w-3 h-3" />
            Balance
          </div>
        );
      }
      if (item.paymentProcessor === "cryptocurrency") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="Bitcoin" className="w-3 h-3" />
            Crypto
          </div>
        );
      }
      if (item.paymentProcessor === "coingate") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="Bitcoin" className="w-3 h-3" />
            Coingate
          </div>
        );
      }
      if (item.paymentProcessor === "card") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="CreditCard" className="w-3 h-3" />
            Card
          </div>
        );
      }
      if (item.paymentProcessor === "trial") {
        return (
          <div className="inline-flex items-center gap-1 text-blue-200">
            <Icon name="Gift" className="w-3 h-3" />
            Trial
          </div>
        );
      }
      if (item.paymentProcessor === "admin") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="HelpingHand" className="w-3 h-3" />
            Admin
          </div>
        );
      }
      return <span>Unknown</span>;
    },
  },
  {
    id: "actions",
    header: "Actions",
    cell: ({ row }) => {
      const item = row.original;

      return <AdminInvoiceOperations data={item} callbackFn={callbackFn} />;
    },
  },
];

const createAdminInvoicesMobileCols = (callbackFn) => [
  {
    accessorKey: "main",
    header: "Results",
    cell: ({ row }) => {
      const item = row.original;

      return <AdminInvoiceItem item={item} callbackFn={callbackFn} />;
    },
  },
];

function AdminInvoicesView({ data, callbackFn }) {
  const adminInvoicesDesktopCols = createAdminInvoicesDesktopCols(callbackFn);
  const adminInvoicesMobileCols = createAdminInvoicesMobileCols(callbackFn);

  return (
    <section className="animate-in fade-in">
      <div className="hidden lg:flex w-full">
        <DataTable columns={adminInvoicesDesktopCols} data={data} />
      </div>
      <div className="lg:hidden w-full">
        <DataTable columns={adminInvoicesMobileCols} data={data} />
      </div>
    </section>
  );
}

export default AdminInvoicesView;

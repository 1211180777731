import classNames from "classnames";
import { Check, ExternalLink, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { buttonVariants } from "../../../../components/ui/button";
import DataTableWithPagination from "../../../../components/ui/data-table-pagination";
import LoadingState from "../../../../components/ui/loading-state";
import { toast } from "../../../../components/ui/use-toast";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { formatCurrency } from "../../../../utils/Utils";

const createAdminCampaignCommissionsDesktopCols = () => [
  {
    id: "deleted",
    accessorKey: "deleted",
    header: "Eligible",
    cell: ({ row }) =>
      row.original.deleted ? (
        <X className="w-3 h-3 text-muted-foreground" />
      ) : (
        <Check className="w-3 h-3 text-green-400" />
      ),
  },
  {
    id: "user",
    accessorKey: "user",
    header: "From",
    cell: ({ row }) => (
      <Link
        to={`/users/${row.original.invoice.user._id}`}
        className={classNames(buttonVariants({ variant: "link" }), "px-0")}
      >
        {row.original.invoice.user.username}
      </Link>
    ),
  },
  {
    id: "invoice",
    accessorKey: "invoice._id",
    header: "Invoice",
    cell: ({ row }) => (
      <div className="flex items-center gap-2">
        <Link
          to={`/invoices/${row.original.invoice._id}`}
          className={classNames(buttonVariants({ variant: "link" }), "px-0")}
        >
          {row.original.invoice._id.slice(-8)}
        </Link>
        <span>{row.original.invoice.title}</span>
        <Link
          to={`/invoices/${row.original.invoice._id}`}
          className={classNames(buttonVariants({ variant: "link" }), "px-0")}
        >
          <ExternalLink className="w-3 h-3" />
        </Link>
      </div>
    ),
  },
  {
    id: "amount",
    accessorKey: "amount",
    header: "Amount",
    cell: ({ row }) => (
      <span className="text-sm font-mono text-right">+ {formatCurrency.format(row.original.amount)}</span>
    ),
  },
];

function AdminCampaignDetailPageView({ data }) {
  const [isLoading, setIsLoading] = useState(false);
  const [commissionsData, setCommissionsData] = useState();

  const desktopCols = createAdminCampaignCommissionsDesktopCols();

  const api = useAxiosPrivate();

  const fetchData = () => {
    api
      .get(`/admin/campaigns/${data._id}/commissions`)
      .then((res) => {
        setCommissionsData(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error while loading campaign commissions.",
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-bold">
          Campaign &quot;
          {data.name}
          &quot;
        </h2>
        <div className="mt-2 flex items-center gap-1">
          <span className="text-sm">Owner:</span>
          <Link to={`/users/${data.user._id}`} className={buttonVariants({ variant: "link" })}>
            {data.user.username}
          </Link>
        </div>
      </div>
      <div className="flex items-center justify-evenly py-8">
        <div className="text-center">
          <span className="text-xs text-muted-foreground">Users referred</span>
          <p className="mt-2 font-medium">{data.totalUsers}</p>
        </div>
        <div className="text-center">
          <span className="text-xs text-muted-foreground">Purchases</span>
          <p className="mt-2 font-medium">{data.totalCommissions}</p>
        </div>
        <div className="text-center">
          <span className="text-xs text-muted-foreground">Available to payout</span>
          <p className={classNames("mt-2 font-medium", data.availableToPayout > 20 && "text-green-600")}>
            {formatCurrency.format(data.availableToPayout)}
          </p>
        </div>
      </div>
      <div className="space-y-3">
        <span className="text-lg">Commissions</span>
        {isLoading ? (
          <LoadingState />
        ) : commissionsData ? (
          <DataTableWithPagination columns={desktopCols} data={commissionsData} />
        ) : (
          <div>Error</div>
        )}
      </div>
    </div>
  );
}

function AdminCampaignDetailPage() {
  const { campaignId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const navigate = useNavigate();

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get(`/admin/campaigns/${campaignId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Error while loading campaign.",
        });
        navigate("/campaigns");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? <LoadingState /> : data ? <AdminCampaignDetailPageView data={data} /> : <div>Error</div>;
}

export default AdminCampaignDetailPage;

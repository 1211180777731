import PurchasingSettings from "./general/purchasing-settings";
import SidebarBannerSettings from "./general/sidebar-banner-settings";

function GeneralSettings({ data }) {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="font-bold text-3xl">General Settings</h3>
      </div>
      <PurchasingSettings data={data.PurchasingEnabled} />
      <SidebarBannerSettings />
    </div>
  );
}

export default GeneralSettings;

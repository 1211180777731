import { Info } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";

export function SubscriptionIsErrorAlert() {
  return (
    <Alert>
      <Info className="h-4 w-4" />
      <AlertTitle>There&apos;s been a problem activating your subscription.</AlertTitle>
      <AlertDescription>
        <a href="#" id="intercomContact">
          Reach out to us
        </a>{" "}
        and we will find a solution together.
      </AlertDescription>
    </Alert>
  );
}

export function SubscriptionDeletedAlert({ data }) {
  let reason;

  if (new Date(data.renewalDate) >= new Date() && data.bandwidthLeft <= 0) {
    reason = "Your subscription ran out of data.";
  }

  if (new Date(data.renewalDate) < new Date()) {
    reason = "Your subscription exceeded its expiry date.";
  }

  return (
    <Alert>
      <Info className="h-4 w-4" />
      <AlertTitle>This subscription is not active.</AlertTitle>
      <AlertDescription>{reason}</AlertDescription>
    </Alert>
  );
}

export function SubscriptionNotYetActiveAlert() {
  return (
    <Alert>
      <Info className="h-4 w-4" />
      <AlertTitle>This subscription is still activating.</AlertTitle>
      <AlertDescription>
        Try again in a few moments. We will refresh this page for you automatically once it activates.
      </AlertDescription>
    </Alert>
  );
}

import React, { useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { formatCurrency } from "../../../../utils/Utils";
import { Button } from "../../../ui/button";
import { CurrencyInput } from "../../../ui/currency-input";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../../ui/dialog";
import { toast } from "../../../ui/use-toast";

function EditUserBalanceModal({ user, open, onOpenChange, callbackFn }) {
  const [value, setValue] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const api = useAxiosPrivate();

  const submit = (operation) => {
    setIsLoading(true);
    api
      .patch(`/admin/users/${user?._id}/balance`, {
        // MALPRACTICE
        amount: parseFloat(value.replace("$", "")),
        operation,
      })
      .then(() => {
        toast({
          title: "Balance modified successfully.",
          variant: "positive",
        });
        callbackFn();
        onOpenChange(false);
      })
      .catch((err) => {
        console.log(err.response?.data.message);
        toast({
          title: "Could not edit balance. Check console.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Modify balance for <span className="text-primary">{user.username}</span>
          </DialogTitle>
        </DialogHeader>
        <div className="text-center">
          <div className="text-sm text-muted-foreground font-medium">Current balance</div>
          <div className="text-xl font-medium text-green-500">{formatCurrency.format(user.balance)}</div>
        </div>
        <div className="flex items-center justify-between gap-3">
          <div className="text-xs text-muted-foreground">Manual amount</div>
          <div className="flex items-center justify-center gap-1.5">
            $
            <CurrencyInput type="currency" value={value} onChange={(e) => setValue(e.target.value)} />
          </div>
        </div>
        <DialogFooter>
          <Button variant="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button disabled={!value || isLoading} onClick={() => submit("add")}>
            Add
          </Button>
          <Button variant="destructive" disabled={!value || isLoading} onClick={() => submit("sub")}>
            Sub
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default EditUserBalanceModal;

import React from "react";
import { Link, NavLink } from "react-router-dom";
import useLockBody from "../hooks/use-lock-body";
import Icon from "./ui/icon";
import Logo from "../assets/images/logo_v2.svg";

function MobileNav({ menuConfig, open, onOpenChange }) {
  useLockBody();
  return (
    <div className="fixed inset-0 top-16 z-20 grid h-[calc(100vh-4rem)] bg-black/25 backdrop-blur-sm grid-flow-row auto-rows-max overflow-auto p-3 pb-32 shadow-md animate-in slide-in-from-bottom-80 lg:hidden">
      <div className="relative z-50 grid gap-6 rounded-md bg-popover p-4 text-popover-foreground shadow-md">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex gap-1 items-center">
            <img src={Logo} className="object-contain h-7 -mb-1" alt="Thunder" />
            <span className="leading-5 text-foreground font-semibold text-2xl">thunderproxy</span>
          </Link>
        </div>
        <ul>
          {menuConfig.map((item) => (
            <li key={item.name} className="py-3 font-medium">
              <NavLink
                onClick={() => onOpenChange(!open)}
                to={item.href}
                className={({ isActive }) =>
                  isActive
                    ? "flex flex-row items-center border-l-4 border-primary text-foreground px-4 gap-x-2"
                    : "flex flex-row items-center text-muted-foreground px-5 hover:text-foreground gap-x-2 transition"
                }
              >
                <Icon name={item.icon} className="w-5 h-5" />
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MobileNav;

import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

const headers = {
  "Content-Type": "application/json",
};

// eslint-disable-next-line no-undef
const accessToken = localStorage.getItem("accessToken");
if (accessToken) {
  headers.Authorization = `Bearer ${accessToken}`;
}

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers,
});

import classNames from "classnames";
import { Trans } from "react-i18next";

function SubscriptionsFilter({ subscriptionsFilter, setSubscriptionsFilter }) {
  return (
    <div className="flex justify-between items-center py-3">
      <h2 className="text-xl font-semibold">
        <Trans i18nKey="dashboard.user.homepage.subscriptions.heading.title" />
      </h2>
      <div className="flex flex-row gap-4 items-center">
        <button
          type="button"
          onClick={() => setSubscriptionsFilter("active")}
          className={classNames(
            "flex h-7 items-center justify-center rounded-full px-4 text-center text-sm transition-colors hover:text-primary",
            subscriptionsFilter === "active" ? "bg-muted font-medium text-primary" : "text-muted-foreground",
          )}
        >
          <Trans i18nKey="dashboard.user.homepage.subscriptions.filters.type.active" />
        </button>
        <button
          type="button"
          onClick={() => setSubscriptionsFilter("expired")}
          className={classNames(
            "flex h-7 items-center justify-center rounded-full px-4 text-center text-sm transition-colors hover:text-primary",
            subscriptionsFilter === "expired" ? "bg-muted font-medium text-primary" : "text-muted-foreground",
          )}
        >
          <Trans i18nKey="dashboard.user.homepage.subscriptions.filters.type.expired" />
        </button>
        <button
          type="button"
          onClick={() => setSubscriptionsFilter("all")}
          className={classNames(
            "flex h-7 items-center justify-center rounded-full px-4 text-center text-sm transition-colors hover:text-primary",
            subscriptionsFilter === "all" ? "bg-muted font-medium text-primary" : "text-muted-foreground",
          )}
        >
          <Trans i18nKey="dashboard.user.homepage.subscriptions.filters.type.all" />
        </button>
      </div>
    </div>
  );
}

export default SubscriptionsFilter;

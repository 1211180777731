import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import classNames from "classnames";
import { ChevronDown } from "lucide-react";
import React from "react";

const Collapsible = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <CollapsiblePrimitive.Root {...props} ref={forwardedRef}>
    {children}
  </CollapsiblePrimitive.Root>
));

const CollapsibleTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <CollapsiblePrimitive.Trigger
    className={classNames(
      "w-full flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
      className,
    )}
    {...props}
    ref={forwardedRef}
  >
    {children}
    <ChevronDown className="h-4 w-4 transition-transform duration-200" />
  </CollapsiblePrimitive.Trigger>
));

const CollapsibleContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <CollapsiblePrimitive.Content
    className={classNames(
      "overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      className,
    )}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </CollapsiblePrimitive.Content>
));

Collapsible.displayName = "Collapsible";
CollapsibleTrigger.displayName = "CollapsibleTrigger";
CollapsibleContent.displayName = "CollappsibleContent";

export { Collapsible, CollapsibleTrigger, CollapsibleContent };

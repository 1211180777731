import React from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import Toaster from "../components/ui/toaster";

function AuthLayout() {
  return (
    <>
      <Helmet>
        <title>thunderproxy | Customer Area</title>
      </Helmet>
      <main className="flex flex-col min-h-screen cursor-default">
        <Outlet />
        <Toaster />
      </main>
    </>
  );
}
export default AuthLayout;

import { RadioGroup, RadioGroupItem } from "../ui/radiogroup";
import Label from "../ui/label";

/// Formats: proxyuri, user:pass@host:port, host:port:user:pass

export function FormatConfiguratorView({ format, onValueChange }) {
  return (
    <div className="space-y-2">
      <div className="text-lg font-semibold">Format</div>
      <RadioGroup value={format} onValueChange={onValueChange} className="space-y-1.5">
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="host:port:user:pass" id="r1" />
          <Label htmlFor="r1">host:port:user:pass</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="user:pass@host:port" id="r2" />
          <Label htmlFor="r2">user:pass@host:port</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="proxyuri" id="r3" />
          <Label htmlFor="r3">Proxy URI (protocol://user:pass@host:port)</Label>
        </div>
      </RadioGroup>
    </div>
  );
}

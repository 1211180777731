import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import EditCouponModal from "../../../components/admin/modals/edit-coupon";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import Icon from "../../../components/ui/icon";
import LoadingState from "../../../components/ui/loading-state";
import { toast } from "../../../components/ui/use-toast";
import DataTable from "../../../components/users/admin/data-table";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

function AdminCouponsOperations({ data, callbackFn }) {
  const [showEditCouponModal, setShowEditCouponModal] = useState(false);

  const api = useAxiosPrivate();

  const handleDeleteCoupon = () => {
    api
      .delete(`/admin/coupons/${data._id}`)
      .then(() => {
        toast({
          title: "Coupon deleted successfully",
        });
        callbackFn();
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Could not delete coupon",
          variant: "destructive",
        });
      })
      .finally(() => {});
  };

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="flex h-8 w-8 items-center justify-center rounded-md border transition-colors hover:bg-muted">
          <Icon name="MoreVertical" className="w-3 h-3" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="min-w-[150px] bg-popover p-1">
          <div className="text-xs p-1 font-medium uppercase">Actions</div>
          <DropdownMenuItem disabled>
            <Icon name="Eye" className="w-4 h- mr-2" />
            View all purchases
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setShowEditCouponModal(true)}>
            <Icon name="Pencil" className="w-4 h-4 mr-2" />
            Edit details
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleDeleteCoupon()}>
            <Icon name="Trash" className="w-4 h-4 mr-2" />
            Delete coupon
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {showEditCouponModal && (
        <EditCouponModal
          data={data}
          open={showEditCouponModal}
          onOpenChange={setShowEditCouponModal}
          callbackFn={callbackFn}
        />
      )}
    </>
  );
}

const createAdminCouponsDesktopColumns = (callbackFn) => [
  {
    accessorKey: "code",
    header: "Coupon",
  },
  {
    accessorKey: "discount",
    header: "Discount",
  },
  {
    accessorKey: "createdAt",
    header: "Created on",
    cell: ({ row }) => {
      const coupon = row.original;
      return <div>{format(new Date(coupon.createdAt), "dd/MM/yyyy, HH:mm:ss")}</div>;
    },
  },
  {
    accessorKey: "timesUsed",
    header: "Uses",
  },
  {
    accessorKey: "paymentProcessor",
    header: "Method",
    cell: ({ row }) => {
      const coupon = row.original;
      return (
        <div>
          {!coupon.paymentProcessor && "All"}
          {coupon.paymentProcessor === "cryptocurrency" && <Icon name="Bitcoin" className="w-4 h-4" />}
          {coupon.paymentProcessor === "card" && <Icon name="Card" className="w-4 h-4" />}
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => (
      <div className="flex flex-row justify-end">
        <AdminCouponsOperations data={row.original} callbackFn={callbackFn} />
      </div>
    ),
  },
];

function AdminCouponsPageView({ data, callbackFn }) {
  const desktopCols = createAdminCouponsDesktopColumns(callbackFn);

  return (
    <div className="space-y-3">
      <div className="text-lg font-medium">
        Currently active coupons ({data.filter((coupon) => coupon.active).length})
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-3">
        {data
          .filter((coupon) => coupon.active)
          .map((coupon) => (
            <button
              key={coupon._id}
              type="button"
              className="bg-popover card rounded-md border hover:ring-2 hover:ring-primary transition"
            >
              <div className="p-3 space-y-1">
                <div className="flex items-center justify-between">
                  <div className="font-medium uppercase">{coupon.code}</div>
                  <div className="mt-1 px-2 py-1 rounded-xl bg-primary text-xs font-medium">{coupon.discount}%</div>
                </div>
                <div className="grid md:grid-cols-2">
                  <div className="font-medium text-sm text-left">Created on</div>
                  <div className="text-sm text-muted-foreground md:text-right">
                    {format(new Date(coupon.createdAt), "dd/MM/yyyy, HH:mm")}
                  </div>
                  <div className="font-medium text-sm text-left">Uses</div>
                  <div className="text-sm text-muted-foreground md:text-right">{coupon.timesUsed}</div>
                  <div className="font-medium text-sm text-left">Method</div>
                  <div className="flex text-sm text-muted-foreground md:text-right justify-end items-center">
                    {!coupon.paymentProcessor && "All"}
                    {coupon.paymentProcessor === "cryptocurrency" && <Icon name="Bitcoin" className="w-4 h-4" />}
                    {coupon.paymentProcessor === "card" && <Icon name="Card" className="w-4 h-4" />}
                  </div>
                </div>
              </div>
            </button>
          ))}
      </div>
      <div className="text-lg font-medium">All coupons</div>
      <DataTable columns={desktopCols} data={data} />
    </div>
  );
}

function AdminCouponsPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);
  const [showEditCouponModal, setShowEditCouponModal] = useState();

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get("/admin/coupons")
      .then((res) => setData(res.data.payload.coupons))
      .catch((err) => {
        console.log(err);
        toast({
          description: "Could not retrieve data from server.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="lg:max-w-7xl mx-auto px-4 py-6 space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold">Coupons</h2>
        <Button onClick={() => setShowEditCouponModal(true)}>
          <Icon name="Plus" className="w-4 h-4" />
          Create new
        </Button>
      </div>

      {isLoading ? (
        <LoadingState />
      ) : data ? (
        <AdminCouponsPageView data={data} callbackFn={fetchData} />
      ) : (
        <div>An error occurred</div>
      )}

      {showEditCouponModal && (
        <EditCouponModal
          data={null}
          open={showEditCouponModal}
          onOpenChange={setShowEditCouponModal}
          callbackFn={fetchData}
        />
      )}
    </div>
  );
}
export default AdminCouponsPage;

import { RadioGroup, RadioGroupItem } from "@radix-ui/react-radio-group";
import classNames from "classnames";
import { Loader2Icon } from "lucide-react";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { generateCheckoutReference } from "../../lib/utils";
import { formatCurrency } from "../../utils/Utils";
import { Button, buttonVariants } from "../ui/button";
import CheckBox from "../ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "../ui/dialog";
import Icon from "../ui/icon";
import Input from "../ui/input";
import { toast } from "../ui/use-toast";

function PurchasePlanModal({ plan, open, onOpenChange }) {
  const {
    auth: { user },
  } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("cryptocurrency");
  const [coupon, setCoupon] = useState();
  const [discount, setDiscount] = useState(0);
  const [couponPaymentMethod, setCouponPaymentMethod] = useState();
  const [hasAcceptedTos, setHasAcceptedTos] = useState();
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);

  const orderTotal = parseFloat((plan.price - (discount / 100) * plan.price).toFixed(2));
  const handlePaymentMethodChange = (newValue) => {
    setPaymentMethod(newValue);
  };

  const api = useAxiosPrivate();

  const handleCheckCoupon = () => {
    setIsCouponValid(false);
    api
      .get(`/public/coupons/${coupon}`)
      .then((res) => {
        setIsCouponValid(true);
        setDiscount(res.data.payload.discount);
        if (res.data.payload.paymentProcessor) {
          setPaymentMethod(res.data.payload.paymentProcessor);
          setCouponPaymentMethod(res.data.payload.paymentProcessor);
        }
      })
      .catch((err) => {
        console.error(err);
        setDiscount(0);
      })
      .finally(() => {
        setIsLoadingCoupon(false);
      });
  };

  const handleChangeCoupon = (e) => {
    setCoupon(e.target.value);
  };

  const handleConfirmCheckout = () => {
    const checkoutReference = generateCheckoutReference();

    const transaction = {
      paymentMethod,
      planId: plan._id,
      checkoutReference,
    };

    if (coupon && isCouponValid) {
      transaction.coupon = coupon;
    }

    setIsLoading(true);

    api
      .post("/public/store/rotating/checkout", transaction, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.redirectUrl) window.location.href = res.data.redirectUrl;
        onOpenChange(false);
      })
      .catch((err) => {
        if (err.response?.data) {
          toast({
            title: "An error occurred",
            description: err.response.data.error,
            variant: "destructive",
          });
        }
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleToggleAcceptTos = () => {
    setHasAcceptedTos(!hasAcceptedTos);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setIsLoadingCoupon(true);
    if (!coupon) {
      setIsCouponValid(false);
      setIsLoadingCoupon(false);
      setDiscount(0);
    }
    if (coupon) {
      const delayDebounceFn = setTimeout(() => {
        handleCheckCoupon();
      }, 500);

      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [coupon]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <Trans i18nKey="dashboard.user.modals.purchase.title" />
            </DialogTitle>
            <DialogDescription>
              <Trans i18nKey="dashboard.user.modals.purchase.caption" />
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-2 border rounded p-2 text-sm">
            <div className="flex justify-between">
              <div className="text-muted-foreground">
                <Trans i18nKey="globals.bandwidth" />
              </div>
              <div className="font-medium">
                <Trans i18nKey={`globals.${plan.type}`} /> - {plan?.bandwidth} <Trans i18nKey="globals.gb" />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-muted-foreground">
                <Trans i18nKey="dashboard.user.modals.purchase.duration" />
              </div>
              <div className="font-medium">
                30 <Trans i18nKey="globals.days" />
              </div>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <div className="text-muted-foreground">
                <Trans i18nKey="dashboard.user.modals.purchase.subtotal" />
              </div>
              <div>{formatCurrency.format((plan.price / 1.21).toFixed(2))}</div>
            </div>
            <div className="flex justify-between items-center">
              <div className="text-muted-foreground">
                <Trans i18nKey="dashboard.user.modals.purchase.vat" />
              </div>
              <div>{formatCurrency.format((plan.price * (1 - 1 / 1.21)).toFixed(2))}</div>
            </div>
            {coupon && isCouponValid && (
              <div className="flex justify-between items-center">
                <div className="text-muted-foreground">
                  Discount ({discount}
                  %)
                </div>
                <div className="text-green-600 font-medium">
                  - {formatCurrency.format((discount / 100) * plan.price)}
                </div>
              </div>
            )}
            <div className="flex justify-between items-center border-t pt-1">
              <div className="text-muted-foreground">
                <Trans i18nKey="dashboard.user.modals.purchase.total" />
              </div>
              <div className="font-medium">{formatCurrency.format(orderTotal)}</div>
            </div>
          </div>
          {/* Todo: COUPONS */}
          {!user?.cardPaymentProcessorEnabled &&
          !user?.cryptoPaymentProcessorEnabled &&
          !user?.balancePaymentProcessorEnabled ? (
            <div className="flex flex-col items-center justify-center py-12">
              <a className="text-center font-medium">It looks like purchasing is currently disabled.</a>
              <p className="text-xs">Apologies for the inconvenience, it will be available again soon!</p>
            </div>
          ) : (
            <>
              <RadioGroup
                className="flex flex-col border rounded text-sm transition"
                value={paymentMethod}
                onValueChange={handlePaymentMethodChange}
              >
                {user?.cryptoPaymentProcessorEnabled && (
                  <RadioGroupItem
                    disabled={
                      !user?.cryptoPaymentProcessorEnabled ||
                      (isCouponValid && couponPaymentMethod && couponPaymentMethod !== "cryptocurrency")
                    }
                    value="cryptocurrency"
                    className={classNames(
                      "first:rounded-t last:rounded-b",
                      paymentMethod === "cryptocurrency" && "text-primary ring-1 ring-primary",
                      !user?.cryptoPaymentProcessorEnabled && "text-muted-foreground cursor-not-allowed",
                      isCouponValid &&
                        couponPaymentMethod &&
                        couponPaymentMethod !== "cryptocurrency" &&
                        "cursor-not-allowed text-muted-foreground",
                    )}
                  >
                    <div className="flex flex-col p-2 gap-1">
                      <div className="inline-flex items-center font-medium gap-1">
                        <Trans i18nKey="globals.payment_methods.crypto.title" />
                        <Icon name="Bitcoin" className="w-4 h-4 text-muted-foreground" />
                      </div>
                      <div className="text-left text-xs text-muted-foreground">
                        Bitcoin (BTC), Ethereum (ETH), USD Coin (USDC), Dogecoin (DOGE), Litecoin (LTC), Bitcoin Cash
                        (BCH), Tether (USDT). Polygon: MATIC, USDC
                      </div>
                    </div>
                  </RadioGroupItem>
                )}
                <RadioGroupItem
                  disabled={user?.balance < orderTotal || !user?.balancePaymentProcessorEnabled}
                  value="balance"
                  className={classNames(
                    "first:rounded-t last:rounded-b",
                    paymentMethod === "balance" && "text-primary ring-1 ring-primary",
                    (user?.balance < orderTotal || !user?.balancePaymentProcessorEnabled) &&
                      "text-muted-foreground cursor-not-allowed",
                  )}
                >
                  <div className="flex flex-col p-2 gap-1">
                    <div className="inline-flex items-center font-medium gap-1">
                      <Trans i18nKey="globals.payment_methods.balance.title" />
                      <Icon name="Wallet" className="w-4 h-4" />
                    </div>
                    <div className="text-left text-xs text-muted-foreground">
                      {user?.balance < orderTotal ? (
                        <Trans i18nKey="globals.payment_methods.balance.insufficient_funds" />
                      ) : (
                        <>
                          <Trans i18nKey="globals.payment_methods.balance.after_purchase" />{" "}
                          {formatCurrency.format(user.balance - orderTotal)}
                        </>
                      )}
                    </div>
                  </div>
                </RadioGroupItem>
                {user.cardPaymentProcessorEnabled && (
                  <RadioGroupItem
                    disabled={
                      orderTotal < user?.cardMinimum ||
                      orderTotal > user.cardMaximum ||
                      (isCouponValid && couponPaymentMethod && couponPaymentMethod !== "card")
                    }
                    value="card"
                    className={classNames(
                      "first:rounded-t last:rounded-b",
                      paymentMethod === "card" && "text-primary ring-1 ring-primary",
                      (orderTotal < user?.cardMinimum || orderTotal > user.cardMaximum) &&
                        "cursor-not-allowed text-muted-foreground",
                      isCouponValid &&
                        couponPaymentMethod &&
                        couponPaymentMethod !== "card" &&
                        "cursor-not-allowed text-muted-foreground",
                    )}
                  >
                    <div className="flex flex-col p-2 gap-1">
                      <div className="inline-flex items-center font-medium gap-1.5">
                        <Trans i18nKey="globals.payment_methods.cards.title" />
                        <Icon name="CreditCard" className="w-4 h-4" />
                      </div>
                      <div className="text-left text-xs text-muted-foreground">
                        <Trans i18nKey="dashboard.user.modals.purchase.card.description" />
                      </div>
                      <div className="text-left text-xs text-primary">
                        <Trans i18nKey="dashboard.user.modals.purchase.card.disclaimer.part1" />${user?.cardMinimum}
                        <Trans i18nKey="dashboard.user.modals.purchase.card.disclaimer.part2" />${user?.cardMaximum}
                      </div>
                    </div>
                  </RadioGroupItem>
                )}
              </RadioGroup>
              <div className="flex items-center justify-between">
                <div className="text-sm">Coupon (optional)</div>
                <div className="flex items-center gap-1">
                  {coupon &&
                    (isLoadingCoupon ? (
                      <Icon name="Loader" className="w-4 h-4 animate-spin" />
                    ) : isCouponValid ? (
                      <Icon name="Check" className="w-4 h-4 text-green-600" />
                    ) : (
                      <Icon name="X" className="w-4 h-4 text-red-600" />
                    ))}
                  <Input value={coupon} onChange={handleChangeCoupon} />
                </div>
              </div>
              <div className="inline-flex items-center gap-2 text-sm">
                <CheckBox value={hasAcceptedTos} onValueChange={handleToggleAcceptTos} />
                <span>
                  <Trans i18nKey="dashboard.user.modals.purchase.acceptance" />
                  <Link
                    className="underline hover:text-primary transition-colors"
                    to="/tos"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Trans i18nKey="dashboard.user.modals.purchase.tos" />
                  </Link>
                  .
                </span>
              </div>
            </>
          )}
          <DialogFooter>
            <Button disabled={isLoading} onClick={() => onOpenChange(false)} variant="outline">
              <Trans i18nKey="globals.buttons.actions.cancel" />
            </Button>
            <Button
              onClick={handleConfirmCheckout}
              disabled={
                !hasAcceptedTos ||
                (paymentMethod === "balance" && user?.balance?.toFixed(2) < orderTotal) ||
                !paymentMethod
              }
              className={buttonVariants()}
            >
              {isLoading && <Loader2Icon className="w-3 h-3 animate-spin mr-2" />}
              <Trans i18nKey="globals.buttons.actions.confirm" />
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}

export default PurchasePlanModal;

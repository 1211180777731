import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ExternalLink } from "lucide-react";
import Pagination from "../../../components/pagination";
import { Badge } from "../../../components/ui/badge";
import DataTable from "../../../components/ui/data-table";
import Icon from "../../../components/ui/icon";
import LoadingState from "../../../components/ui/loading-state";
import { formatCurrency } from "../../../utils/Utils";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { toast } from "../../../components/ui/use-toast";
import { Button } from "../../../components/ui/button";

function UserCancelInvoiceModal({ open, onOpenChange, data, callbackFn }) {
  const api = useAxiosPrivate();

  const handleCancelInvoice = () => {
    api
      .delete(`/public/invoices/${data._id}`)
      .then(() => {
        toast({
          title: "Invoice canceled successfully.",
        });
        callbackFn();
      })
      .catch((err) => {
        console.error(err);
        toast({ title: "Could not cancel invoice", description: "Contact support for assistance." });
      })
      .finally(() => {
        onOpenChange(false);
      });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you absolutely sure?</DialogTitle>
          <DialogDescription>
            This action cannot be undone. The invoice will be canceled and any subsequent payments will not be deemed
            valid.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button onClick={() => onOpenChange(false)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleCancelInvoice()}>Confirm</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function UserInvoiceOperations({ data, callbackFn }) {
  // Fix for iOS mobile not opening
  // eslint-disable-next-line no-undef
  const touchDevice = "ontouchstart" in window;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showCancelInvoiceModal, setShowCancelInvoiceModal] = useState(false);

  return (
    <>
      <DropdownMenu open={open} modal={false} onOpenChange={setOpen}>
        <DropdownMenuTrigger
          className="flex h-8 w-8 items-center justify-center rounded-md border transition-colors hover:bg-muted"
          {...(touchDevice
            ? {
                onPointerDown: (e) => e.preventDefault(),
                onClick: () => setOpen(!open),
              }
            : undefined)}
        >
          <div>
            <Icon name="MoreVertical" className="w-3 h-3" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            // eslint-disable-next-line no-undef
            onClick={() => navigator.clipboard.writeText(data._id)}
          >
            <Icon name="Clipboard" className="w-4 h-4 mr-2" />
            Copy ID
          </DropdownMenuItem>
          {data.subscription && (
            <DropdownMenuItem onClick={() => navigate(`/subscriptions/${data.subscription._id}`)}>
              <Icon name="Eye" className="w-4 h-4 mr-2" />
              View associated sub
            </DropdownMenuItem>
          )}
          {!data.pending && !data.paid && !data.deleted && (
            <DropdownMenuItem onClick={() => setShowCancelInvoiceModal(true)}>
              <Icon name="X" className="w-4 h-4 mr-2" />
              Cancel invoice
            </DropdownMenuItem>
          )}
          {!!data.invoiceLink && (
            <Link to={data.invoiceLink} target="_blank">
              <DropdownMenuItem>
                <ExternalLink className="w-4 h-4 mr-2" />
                View invoice link
              </DropdownMenuItem>
            </Link>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {showCancelInvoiceModal && (
        <UserCancelInvoiceModal
          open={showCancelInvoiceModal}
          onOpenChange={setShowCancelInvoiceModal}
          data={data}
          callbackFn={callbackFn}
        />
      )}
    </>
  );
}

function UserInvoiceItem({ item }) {
  return (
    <div className="flex items-center gap-1.5 pl-3 py-3">
      <div className="grow flex flex-col justify-between gap-1.5">
        <div className="flex items-center justify-between">
          <div className="font-medium">{item._id}</div>
          <div>
            {item.paid && (
              <Badge>
                Completed
                <Icon name="Check" className="w-3 h-3" />
              </Badge>
            )}
            {item.unresolvedStatus && !item.paid && !item.pending && (
              <Badge>
                Payment issue
                <Icon name="AlertTriangle" className="w-3 h-3" />
              </Badge>
            )}
            {!item.paid && (
              <Badge>
                Unpaid
                <Icon name="Clock3" className="w-3 h-3" />
              </Badge>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between" />
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1.5">
            <Icon name="Dollar" className="w-3 h-3 text-muted-foreground" />
            {formatCurrency.format(item.price)}{" "}
            {item.paymentProcessor === "balance" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="Wallet" className="w-3 h-3" />
                Balance
              </div>
            )}
            {item.paymentProcessor === "cryptocurrency" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="Bitcoin" className="w-3 h-3" />
                Crypto
              </div>
            )}
            {item.paymentProcessor === "coingate" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="Bitcoin" className="w-3 h-3" />
                Coingate
              </div>
            )}
            {item.paymentProcessor === "card" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="CreditCard" className="w-3 h-3" />
                Card
              </div>
            )}
            {item.paymentProcessor === "trial" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="Gift" className="w-3 h-3" />
                Trial
              </div>
            )}
            {item.paymentProcessor === "admin" && (
              <div className="inline-flex items-center gap-1">
                <Icon name="HelpingHand" className="w-3 h-3" />
                Admin
              </div>
            )}
          </div>
          <div>{format(new Date(item.createdAt), "dd MMM HH:mm")}</div>
        </div>
      </div>
    </div>
  );
}

const createUserInvoicesDesktopCols = (callbackFn) => [
  {
    accessorKey: "_id",
    header: "Invoice ID",
    cell: ({ row }) => (
      <div>
        <span className="truncate font-mono text-xs">
          ...
          {row.getValue("_id").slice(-8)}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "amount",
    header: "Amount",
    cell: ({ row }) => {
      const item = row.original;
      return <div className="text-right font-semibold">{formatCurrency.format(item.price)}</div>;
    },
  },
  {
    id: "status",
    header: "Status",
    cell: ({ row }) => {
      const item = row.original;
      // Paid correctly
      if (item.paid) {
        return (
          <Badge variant="secondary">
            Completed
            <Icon name="Check" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      // Underpayment, overpayment
      if (item.unresolvedStatus) {
        return (
          <Badge>
            Payment issue
            <Icon name="AlertTriangle" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      // Payment detected, awaiting confirmation
      if (item.pending) {
        return (
          <Badge variant="outline">
            Pending
            <Icon name="Hourglass" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      // No payment has been detected yet
      if (!item.paid) {
        return (
          <Badge variant="outline">
            Unpaid
            <Icon name="Clock3" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      return <span>Unknown</span>;
    },
  },
  {
    id: "description",
    header: "Contents",
    cell: ({ row }) => <span className="text-muted-foreground font-medium ml-1">{row.original.title}</span>,
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    cell: ({ row }) => {
      const item = row.original;
      return format(new Date(item.createdAt), "dd MMM, HH:mm");
    },
  },
  {
    accessorKey: "paymentProcessor",
    header: "Processor",
    cell: ({ row }) => {
      const item = row.original;
      if (item.paymentProcessor === "balance") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="Wallet" className="w-3 h-3" />
            Balance
          </div>
        );
      }
      if (item.paymentProcessor === "cryptocurrency") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="Bitcoin" className="w-3 h-3" />
            Crypto
          </div>
        );
      }
      if (item.paymentProcessor === "coingate") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="Bitcoin" className="w-3 h-3" />
            Coingate
          </div>
        );
      }
      if (item.paymentProcessor === "card") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="CreditCard" className="w-3 h-3" />
            Card
          </div>
        );
      }
      if (item.paymentProcessor === "trial") {
        return (
          <div className="inline-flex items-center gap-1 text-blue-200">
            <Icon name="Gift" className="w-3 h-3" />
            Trial
          </div>
        );
      }
      if (item.paymentProcessor === "admin") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="HelpingHand" className="w-3 h-3" />
            Admin
          </div>
        );
      }
      return <span>Unknown</span>;
    },
  },
  {
    id: "actions",
    header: "Actions",
    cell: ({ row }) => {
      const item = row.original;

      return <UserInvoiceOperations data={item} callbackFn={callbackFn} />;
    },
  },
];

const createUserInvoicesMobileCols = (callbackFn) => [
  {
    accessorKey: "main",
    header: "Results",
    cell: ({ row }) => {
      const item = row.original;

      return <UserInvoiceItem item={item} callbackFn={callbackFn} />;
    },
  },
];

function UserInvoicesPageView({ data, callbackFn }) {
  const userInvoicesDesktopCols = createUserInvoicesDesktopCols(callbackFn);
  const userInvoicesMobileCols = createUserInvoicesMobileCols();

  return (
    <section className="animate-in fade-in">
      <div className="hidden lg:flex w-full">
        <DataTable columns={userInvoicesDesktopCols} data={data} />
      </div>
      <div className="lg:hidden w-full">
        <DataTable columns={userInvoicesMobileCols} data={data} />
      </div>
    </section>
  );
}

function UserInvoicesPage() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get(`public/invoices?page=${page}`)
      .then((res) => {
        setData(res.data.payload.invoices);
        setTotal(res.data.pager.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePageChange = (newValue) => {
    setPage(newValue);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-2xl font-semibold">Invoices</h1>
      </div>
      {isLoading ? (
        <div className="h-[475px] flex items-center justify-center">
          <LoadingState />
        </div>
      ) : (
        <UserInvoicesPageView data={data} callbackFn={fetchData} />
      )}
      <Pagination onPageChange={handlePageChange} totalCount={total} currentPage={page} pageSize={10} />
    </>
  );
}

export default UserInvoicesPage;

import { Bar, BarChart, ResponsiveContainer, Tooltip } from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { formatBytes } from "../../lib/utils";

function CustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    const { timestamp } = payload[0].payload;
    const [datePart, hourPart] = timestamp.split(" ");
    const display = `${datePart} ${hourPart}:00-${hourPart}:59`;

    return (
      <div className="rounded-lg border bg-background p-2 shadow-sm">
        <div className="flex flex-col">
          <span className="text-[0.70rem] uppercase text-muted-foreground">{display}</span>
          <span className="font-bold">{formatBytes(payload[0].value)}</span>
        </div>
      </div>
    );
  }

  return null;
}

function SubscriptionBandwidthConsumption({ data }) {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-base font-normal">Traffic usage history</CardTitle>
      </CardHeader>
      <CardContent className="lg:h-[400px]">
        {data?.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart width={730} height={250} data={data}>
              <Tooltip content={<CustomTooltip />} />
              <Bar stroke="#3b82f6" fill="#3b82f6" dataKey="amount" />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div className="w-full h-full grid place-items-center text-center">
            <div>
              History of bandwidth usage is not available yet. Start using your subscription and come back after a few
              minutes.
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default SubscriptionBandwidthConsumption;

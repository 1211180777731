import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { SubscriptionExportFileView } from "./subscription-export-file-view";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import SubscriptionExportApiView from "./subscription-export-api-view";

export function SubscriptionExportView() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Export</CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="file">
          <TabsList className="grid w-full grid-cols-2 mb-6">
            <TabsTrigger value="file">Proxy List</TabsTrigger>
            <TabsTrigger value="api">API Link</TabsTrigger>
          </TabsList>
          <TabsContent value="file">
            <SubscriptionExportFileView />
          </TabsContent>
          <TabsContent value="api">
            <SubscriptionExportApiView />
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
}

import { useContext } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { SubscriptionContext } from "../ui/subscription-provider";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { AuthorisationView } from "./subscription-authorisation-view";
import { IPAuthView } from "./subscription-ip-auth-view";
import SubscriptionLegacyGenerator from "./subscription-legacy-generator";

export function SubscriptionQuickAccessView() {
  const { subscription } = useContext(SubscriptionContext);
  const { ipAuth } = subscription;

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle>Quick Access</CardTitle>
          <div className="hidden lg:block">
            <SubscriptionLegacyGenerator />
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue={ipAuth.ips.length > 0 ? "ipauth" : "authorisation"}>
          <TabsList className="grid w-full grid-cols-2 mb-6">
            <TabsTrigger value="authorisation">user:pass</TabsTrigger>
            <TabsTrigger value="ipauth">IP auth</TabsTrigger>
          </TabsList>
          <TabsContent value="authorisation">
            <AuthorisationView />
          </TabsContent>
          <TabsContent value="ipauth">
            <IPAuthView />
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
}

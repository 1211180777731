import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import AddFundsModal from "../../components/modals/add-funds";
import Pagination from "../../components/pagination";
import { buttonVariants } from "../../components/ui/button";
import { Card } from "../../components/ui/card";
import EmptyState from "../../components/ui/empty-state";
import Icon from "../../components/ui/icon";
import LoadingState from "../../components/ui/loading-state";
import Transaction from "../../components/ui/transaction";
import { formatCurrency } from "../../utils/Utils";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function Wallet() {
  const { auth } = useAuth();

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);

  const [data, setData] = useState();
  const [isLoadingHistory, setIsLoadingHistory] = useState();
  const [showAddFundsModal, setShowAddFundsModal] = useState(false);

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoadingHistory(true);
    api
      .get(`/public/wallet?page=${page}`)
      .then((res) => {
        setData(res.data.payload.invoices);
        setTotal(res.data.pager.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingHistory(false);
      });
  };

  const handlePageChange = (newValue) => {
    setPage(newValue);
  };

  useEffect(() => {
    fetchData();
  }, [page]);
  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-2xl font-semibold">
          <Trans i18nKey="dashboard.user.wallet.heading" />
        </h1>
        <button
          type="button"
          onClick={() => {
            setShowAddFundsModal(true);
          }}
          className={buttonVariants()}
        >
          <Icon name="Plus" className="w-4 h-4 text-primary-foreground" />
          <Trans i18nKey="dashboard.user.wallet.action" />
        </button>
      </div>
      <div className="mt-3">
        <div className="flex justify-between items-center py-3">
          <h2 className="text-xl font-semibold">
            <Trans i18nKey="globals.balance" />
          </h2>
        </div>
        <Card className="flex flex-col w-64 rounded gap-2 p-3">
          <div className="flex flex-row items-center gap-2 font-bold text-green-700">
            <Icon name="DollarSign" className="rounded-full bg-green-500/10 p-2 w-10 h-10" />
            USD
          </div>
          <div className="text-2xl font-semibold">
            {auth?.user ? formatCurrency.format(auth?.user.balance) : "Loading..."}
          </div>
        </Card>
        {/* History */}
        <div className="flex justify-between items-center py-3">
          <h2 className="text-xl font-semibold">
            <Trans i18nKey="dashboard.user.wallet.history.heading" />
          </h2>
        </div>
        <div className="lg:h-[652px]">
          {isLoadingHistory ? (
            <LoadingState />
          ) : data?.length ? (
            <ul className="rounded border divide-y">
              {data?.map((invoice) => (
                <Transaction key={invoice._id} invoice={invoice} />
              ))}
            </ul>
          ) : (
            <EmptyState />
          )}
        </div>
        <Pagination onPageChange={handlePageChange} totalCount={total} currentPage={page} pageSize={10} />
      </div>
      <AddFundsModal open={showAddFundsModal} onOpenChange={setShowAddFundsModal} />
    </>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Turnstile, { useTurnstile } from "react-turnstile";
import { z } from "zod";
import Logo from "../../assets/images/logo_v2.svg";
import { pushSignUpEvent } from "../../components/GTM";
import { Button, buttonVariants } from "../../components/ui/button";
import CheckBox from "../../components/ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import Icon from "../../components/ui/icon";
import Input from "../../components/ui/input";
import { toast } from "../../components/ui/use-toast";
import axios from "../../config/axios";
import { update } from "@intercom/messenger-js-sdk";

function UserRegisterForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();
  const turnstile = useTurnstile();

  const registerFormSchema = z
    .object({
      username: z
        .string({
          required_error: "Please enter a username.",
        })
        .refine((value) => /^[a-zA-Z][a-zA-Z0-9_]{4,31}$/.test(value), {
          message: "Username must be at 5-32 long and may consist only of lowercase letters, numbers, and underscores.",
        }),
      consentToEmails: z.boolean(),
      password: z.string().regex(/^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\-]).{8,16}$/, {
        message:
          "The password must be 8-16 characters long and contain at least one uppercase letter and one special character.",
      }),
      telegram: z.string().optional(),
      email: z
        .string({
          required_error: "Please enter an email address.",
        })
        .refine((value) => /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/.test(value), {
          message: "Please enter a valid email address.",
        }),
    })
    .refine(
      (data) => {
        if (data.telegram) {
          return /^[a-zA-Z][a-zA-Z0-9_]{4,31}$/.test(data.telegram);
        }
        return true;
      },
      {
        message:
          "Invalid Telegram username. Must start with a letter and be 5-32 characters long including letters, numbers, and underscores.",
        path: ["telegram"],
      },
    );

  const form = useForm({
    resolver: zodResolver(registerFormSchema),
    defaultValues: {
      consentToEmails: true,
    },
    mode: "onChange",
  });

  const onSubmit = async (formData) => {
    setIsLoading(true);

    if (formData.telegram === "") {
      // eslint-disable-next-line no-param-reassign
      delete formData.telegram;
    }

    axios
      .post(
        "/auth/signup",
        {
          ...formData,
          captchaToken,
        },
        { withCredentials: true },
      )
      .then((res) => {
        // eslint-disable-next-line no-undef
        localStorage.setItem("accessToken", res.data.payload.accessToken);
        pushSignUpEvent("Email");
        toast({
          title: "Registration successful",
          description: "Welcome onboard!",
          variant: "positive",
        });
        window.location.href = "/store";
      })
      .catch((err) => {
        if (err.code === "ERR_NETWORK") {
          toast({ title: "Failure to connect to our servers. Check your connection settings or try again later." });
        }
        if (err.response) {
          toast({
            title: "Could not register",
            description: err.response.data.message ?? "Contact support.",
          });
        }
      })
      .finally(() => {
        turnstile.reset();
        setIsLoading(false);
      });
  };

  return (
    <div className="grid gap-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans i18nKey="auth.username.label" />
                </FormLabel>
                <FormControl>
                  <Input placeholder="Username" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans i18nKey="auth.email.label" />
                </FormLabel>
                <FormControl>
                  <Input placeholder="username@example.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans i18nKey="auth.password.label" />
                </FormLabel>
                <FormControl>
                  <Input type="password" placeholder="8 to 16 characters" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="telegram"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Telegram username (optional)</FormLabel>
                <FormControl>
                  <Input placeholder="Without @" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="consentToEmails"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Informational emails consent</FormLabel>
                <FormControl>
                  <div className="flex items-center gap-2">
                    <CheckBox value={field.value} onValueChange={field.onChange} />
                    <div className="text-xs">
                      I want to receive informational emails about product changes, system updates and sales.
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Turnstile
            autoResetOnExpire
            fixedSize
            sitekey={process.env.REACT_APP_CLOUDFLARE_CAPTCHA_KEY}
            onVerify={(token) => {
              setCaptchaToken(token);
            }}
          />
          <Button type="submit" disabled={!captchaToken} className="w-full">
            {isLoading && <Icon name="Loader2" className="mr-2 h-4 w-4 animate-spin" />}
            <Trans i18nKey="auth.register.action" />
          </Button>
        </form>
      </Form>
      <p className="text-sm text-muted-foreground">
        <Trans i18nKey="auth.register.footer.caption" />{" "}
        <Link to="/auth/login" className="font-medium text-primary hover:text-primary/95 transition-colors">
          <Trans i18nKey="auth.register.footer.action" />
        </Link>
      </p>
    </div>
  );
}

function Register() {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    update({
      hide_default_launcher: true,
    });
  }, []);

  return (
    <div className="container relative flex-1 flex-col items-center justify-center lg:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <Link
        to="/auth/login"
        className={classNames(buttonVariants({ variant: "ghost" }), "absolute right-4 top-4 md:right-8 md:top-8")}
      >
        Login
      </Link>
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
        <div className="absolute inset-0" />
        <div className="relative z-20 flex items-center text-lg font-medium">
          <Link to="/" className="hidden md:flex justify-center gap-1 items-center border-bottom border-white p-4">
            <img src={Logo} className="object-contain h-7" alt="" />
            <span className="leading-6 text-foreground tracking-tight font-semibold text-2xl">thunderproxy</span>
          </Link>
        </div>
        <div className="my-auto">
          <h2 className="text-xl font-semibold">
            Register an account to access all our products.
            <br />
            No credit card or commitment required.
          </h2>
        </div>
      </div>
      <div className="h-screen grid place-items-center lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              <Trans i18nKey="auth.register.heading" />
            </h1>
          </div>
          <UserRegisterForm />
          <p className="px-8 text-center text-sm text-muted-foreground">
            By continuing, you agree to our{" "}
            <Link to="/tos" className="underline underline-offset-4 hover:text-primary">
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link to="/privacy" className="underline underline-offset-4 hover:text-primary">
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
export default Register;

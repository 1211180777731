import { showArticle } from "@intercom/messenger-js-sdk";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@radix-ui/react-collapsible";
import { Separator } from "@radix-ui/react-select";
import { ChevronsDown, ChevronsUp, ExternalLinkIcon, Loader2 } from "lucide-react";
import { useContext, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  generateSubscriptionExampleCurl,
  generateSubscriptionUri,
  generateSubscriptionUsername,
} from "../../lib/subscription";
import { Button, buttonVariants } from "../ui/button";
import { CardDescription, CardTitle } from "../ui/card";
import { CopyButton } from "../ui/copy-button";
import CopyOnClickInput from "../ui/copy-on-click-input";
import { Dialog, DialogContent, DialogFooter, DialogTitle, DialogTrigger } from "../ui/dialog";
import Label from "../ui/label";
import { SubscriptionContext } from "../ui/subscription-provider";
import { toast } from "../ui/use-toast";
import { GeotargetingConfiguratorView } from "./geotargeting-configurator-view";
import { RotationConfiguratorView } from "./rotation-configurator-view";
import { SubscriptionExampleCurlBox } from "./subscription-example-curl-box";

function SubscriptionResetPasswordModal() {
  const [isLoading, setIsLoading] = useState(false);
  const [open, onOpenChange] = useState(false);

  const { subscription } = useContext(SubscriptionContext);
  const { subInfo } = subscription;

  const api = useAxiosPrivate();

  const resetPassword = () => {
    setIsLoading(true);
    api
      .post(`/public/subscriptions/${subInfo._id}/update-password`)
      .then(() => {
        toast({ title: "Password changed" });
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        toast({ title: "Could not change password", variant: "destructive" });
      })
      .finally(() => {
        setIsLoading(false);
        onOpenChange(!open);
      });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button variant="link" className="pr-0">
          Change
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Reset subscription password</DialogTitle>
        <p>After doing so, the old credentials won&apos;t work anymore.</p>
        <DialogFooter>
          <Button disabled={isLoading} onClick={() => onOpenChange(!open)} variant="outline">
            Cancel
          </Button>
          <Button disabled={isLoading} onClick={() => resetPassword()} variant="destructive">
            {isLoading && <Loader2 className="w-4 h-4 animate-spin mr-2" />}
            Proceed
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function AuthorisationView() {
  const [showResolvedHost, setShowResolvedHost] = useState(false);

  const [formatsCollapsibleOpen, setFormatsCollapsibleOpen] = useState(false);

  const { subscription } = useContext(SubscriptionContext);
  const { subInfo, subConfig, plan, authentication, geoTargeting } = subscription;

  const host = showResolvedHost ? authentication.resolvedHost : authentication.host;

  const defaultSubConfig = {
    rotation: "rotating",
    country: "ANY",
    state: "",
    city: "",
  };

  const [authorisationSubConfig, setAuthorisationSubConfig] = useState(defaultSubConfig);

  const handleChangeAuthorisationSubConfig = (data) => {
    setAuthorisationSubConfig((old) => ({ ...old, ...data }));
  };

  const formedUsername = generateSubscriptionUsername(subInfo, plan, authorisationSubConfig, authentication);

  return (
    <div>
      <CardTitle>Authorisation</CardTitle>
      <CardDescription>
        For software that allows the use of user:pass authorisation.
        <a
          className={buttonVariants({ variant: "link" })}
          href="https://help.thunderproxy.com/en/articles/9809024-how-to-use-rotating-proxies#h_b1cd5e10ce"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </CardDescription>
      <div className="space-y-3">
        {geoTargeting && (
          <GeotargetingConfiguratorView
            value={authorisationSubConfig}
            onValueChange={handleChangeAuthorisationSubConfig}
          />
        )}
        {!!subConfig.rotation && (
          <RotationConfiguratorView value={authorisationSubConfig} onValueChange={handleChangeAuthorisationSubConfig} />
        )}
        <div>Enter the settings below in your software to start using your proxy.</div>
        <div className="space-y-1">
          <Label>Protocol</Label>
          <div>HTTP</div>
        </div>
        <div className="space-y-1">
          <Label>Username</Label>
          <div className="flex gap-1 items-center">
            <CopyOnClickInput readOnly defaultValue={formedUsername} value={formedUsername} />
            <CopyButton value={formedUsername} />
          </div>
        </div>
        <div className="space-y-1">
          <Label>
            Password
            <SubscriptionResetPasswordModal />
          </Label>
          <div className="flex gap-1 items-center">
            <CopyOnClickInput readOnly defaultValue={authentication.password} value={authentication.password} />
            <CopyButton value={authentication.password} />
          </div>
        </div>
        <div className="space-y-1">
          <Label>
            Host{" "}
            <Button
              className="focus:outline-none"
              onClick={() => setShowResolvedHost(!showResolvedHost)}
              variant="link"
            >
              {showResolvedHost ? "Show domain" : "Show IP"}
            </Button>
          </Label>
          <div className="flex gap-1 items-center">
            <CopyOnClickInput value={host} />
            <CopyButton value={host} />
          </div>
        </div>
        <div className="space-y-1">
          <Label>Port</Label>
          <div className="flex items-center gap-1">
            <CopyOnClickInput value={authentication.port} />
            <CopyButton value={authentication.port} />
          </div>
        </div>
        <Separator />
        <Collapsible open={formatsCollapsibleOpen} onOpenChange={setFormatsCollapsibleOpen} className="space-y-2">
          <div className="space-y-1 w-full">
            <Label>Proxy URI</Label>
            <div className="flex items-center gap-1">
              <CopyOnClickInput
                className="w-full"
                readOnly
                value={generateSubscriptionUri(formedUsername, authentication.password, host, authentication.port)}
              />
              <CopyButton
                value={generateSubscriptionUri(formedUsername, authentication.password, host, authentication.port)}
              />
            </div>
          </div>
          <div className="grid place-items-center">
            <CollapsibleTrigger asChild>
              <Button variant="ghost" size="sm">
                {formatsCollapsibleOpen ? <span>hide</span> : <span>show more formats</span>}{" "}
                {formatsCollapsibleOpen ? (
                  <ChevronsUp className="h-4 w-4 ml-2" />
                ) : (
                  <ChevronsDown className="h-4 w-4 ml-2" />
                )}
              </Button>
            </CollapsibleTrigger>
          </div>
          <CollapsibleContent className="space-y-2 animate-in fade-in ease-in">
            <div className="space-y-1 w-full">
              <Label>user:pass@host:port</Label>
              <CopyOnClickInput
                className="w-full"
                readOnly
                value={`${formedUsername}:${authentication.password}@${host}:${authentication.port}`}
              />
            </div>
            <div className="space-y-1 w-full">
              <Label>host:port:user:pass</Label>
              <CopyOnClickInput
                className="w-full"
                readOnly
                value={`${host}:${authentication.port}:${formedUsername}:${authentication.password}`}
              />
            </div>
          </CollapsibleContent>
        </Collapsible>
      </div>
      <Separator />
      <div className="space-y-3">
        <div className="flex items-center justify-between">
          <CardTitle>Example cURL</CardTitle>
          <button
            onClick={() => showArticle(9724491)}
            type="button"
            className="flex text-sm items-center text-primary underline-offset-4 hover:underline cursor-pointer"
          >
            What&apos;s cURL?
            <ExternalLinkIcon className="ml-2 w-3 h-3" />
          </button>
        </div>
        <SubscriptionExampleCurlBox
          curlString={generateSubscriptionExampleCurl(
            "authentication",
            host,
            authentication.port,
            formedUsername,
            authentication.password,
          )}
        />
      </div>
    </div>
  );
}

import { cva } from "class-variance-authority";

const statusBadgeVariants = cva(
  "inline-flex gap-0.5 items-center text-white rounded border font-medium text-xs p-0.5",
  {
    variants: {
      variant: {
        default: "bg-slate-500 dark:bg-slate-700 border",
        positive: "bg-green-500 dark:bg-green-700 border",
        negative: "bg-red-500 dark:bg-red-700 border",
        warning: "bg-yellow-500 dark:bg-yellow-700 border",
        confirm: "bg-blue-500 dark:bg-blue-700 border",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export default statusBadgeVariants;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminUserDetailPageView from "../../../../components/admin/views/user-detail-view";
import LoadingState from "../../../../components/ui/loading-state";
import { toast } from "../../../../components/ui/use-toast";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { update } from "@intercom/messenger-js-sdk";

function AdminUserDetailPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const { userId } = useParams();

  const api = useAxiosPrivate();

  const navigate = useNavigate();

  const fetchData = () => {
    api
      .get(`/admin/users/${userId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        toast({
          title: "Could not load user profile",
          variant: "negative",
        });
        console.log(err);
        navigate("/users");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line no-undef
    update({
      hide_default_launcher: true,
    });
  }, []);

  return isLoading ? <LoadingState /> : <AdminUserDetailPageView data={data} callbackFn={fetchData} />;
}

export default AdminUserDetailPage;

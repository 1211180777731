import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { buttonVariants } from "../../../components/ui/button";
import DataTable from "../../../components/ui/data-table";
import Icon from "../../../components/ui/icon";
import LoadingState from "../../../components/ui/loading-state";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { cn } from "../../../lib/utils";

export const createAdminCampaignsDesktopCols = () => [
  {
    accessorKey: "_id",
    header: "Campaign ID",
    cell: ({ row }) => (
      <div>
        <span className="truncate font-mono text-xs">
          ...
          {row.getValue("_id").slice(-8)}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "user._id",
    header: "Owner",
    cell: ({ row }) => (
      <div>
        <Link to={`/users/${row.original.user._id}`} className={cn(buttonVariants({ variant: "link" }), "p-0")}>
          {row.original.user.username}
        </Link>
      </div>
    ),
  },
  {
    accessorKey: "_id",
    header: "Name",
    cell: ({ row }) => <span>{row.original.name}</span>,
  },
  {
    accessorKey: "_id",
    header: "Code",
    cell: ({ row }) => <span>{row.original.code}</span>,
  },
  {
    accessorKey: "_id",
    header: "Registered",
    cell: ({ row }) => <span>{row.original.totalUsers}</span>,
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const item = row.original;

      return (
        <div className="flex w-full justify-end">
          <Link to={`/referrals/campaigns/${item._id}`}>
            <Icon
              name="Eye"
              className="flex h-4 w-4 items-center justify-center rounded-md transition-colors hover:bg-muted"
            />
          </Link>
        </div>
      );
    },
  },
];

function AdminCampaignsPageView({ data }) {
  const desktopCols = createAdminCampaignsDesktopCols();

  return <DataTable columns={desktopCols} data={data} />;
}

function AdminCampaignsPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const api = useAxiosPrivate();

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      api
        .get("/admin/campaigns")
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
          navigate("/");
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);

  return (
    <section className="space-y-6 animate-in fade-in">
      <div className="flex items-center justify-between">
        <h1 className="font-heading text-2xl">Campaigns</h1>
      </div>
      {isLoading ? <LoadingState /> : <AdminCampaignsPageView data={data} />}
    </section>
  );
}

export default AdminCampaignsPage;

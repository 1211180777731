import * as icons from "lucide-react";
import React from "react";

function Icon({ name, ...props }) {
  const LucideIcon = icons[name];
  if (!LucideIcon) {
    return null;
  }
  return <LucideIcon {...props} />;
}

export default Icon;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../../../ui/dialog";
import { Button } from "../../../ui/button";
import { toast } from "../../../ui/use-toast";

function DeleteUserModal({ user, open, onOpenChange, callbackFn }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const api = useAxiosPrivate();

  const submit = () => {
    setLoading(true);
    api
      .delete(`/admin/users/${user?._id}`)
      .then(() => {
        toast({
          title: "User deleted successfully.",
          variant: "positive",
        });
        navigate("/users");
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error when deleting user",
          description: err.response ? err.response.data.message : "Check console for more info",
          variant: "destructive",
        });
      })
      .finally(() => {
        setLoading(false);
        callbackFn();
      });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Delete user &quot;
            {user.username}
            &quot;?
          </DialogTitle>
          <DialogDescription className="text-muted-foreground">This action cannot be undone.</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline">Cancel</Button>
          <Button disabled={loading} onClick={submit} variant="confirm">
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteUserModal;

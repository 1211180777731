import { useContext } from "react";
import { SubscriptionContext } from "../ui/subscription-provider";
import Label from "../ui/label";
import {
  GeotargetingCityCombobox,
  GeotargetingCountryCombobox,
  GeotargetingStateCombobox,
} from "./geotargeting-country-combobox";

export function GeotargetingConfiguratorView({ value, onValueChange }) {
  const { subscription } = useContext(SubscriptionContext);
  const { geoTargeting } = subscription;

  const filterStates = (country) => geoTargeting.states?.filter((item) => item.country === country);
  const filterCities = (country, state) =>
    geoTargeting.cities?.filter((item) => item.country === country && item.state === state);

  const handleValueChange = (key, value) => {
    const update = { [key]: value };

    if (key === "state") {
      update.city = "";
    }

    if (key === "country") {
      update.state = "";
      update.city = "";
    }

    const finalUpdate = {
      ...update,
    };

    onValueChange(finalUpdate);
  };

  return (
    <div className="space-y-2">
      <div className="text-lg font-semibold">Geotargeting</div>
      <div className="grid lg:grid-cols-2">
        <div>
          <Label>Country</Label>
          <GeotargetingCountryCombobox
            items={geoTargeting.countries}
            value={value.country}
            onValueChange={(item) => handleValueChange("country", item)}
          />
        </div>
        {value.country !== "ANY" && !!filterStates(value.country) && (
          <div>
            <Label>State</Label>
            <GeotargetingStateCombobox
              items={filterStates(value.country)}
              value={value.state}
              onValueChange={(item) => handleValueChange("state", item)}
            />
          </div>
        )}
        {value.state !== "" && !!filterCities(value.country, value.state) && (
          <div>
            <Label>City</Label>
            <GeotargetingCityCombobox
              items={filterCities(value.country, value.state)}
              value={value.city}
              onValueChange={(item) => handleValueChange("city", item)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";

import GeneralSettings from "../../../components/admin/settings/general-settings";
import PrivacyEditor from "../../../components/admin/settings/privacyeditor";
import TosEditor from "../../../components/admin/settings/toseditor";
import LoadingState from "../../../components/ui/loading-state";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { toast } from "../../../components/ui/use-toast";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { cn } from "../../../lib/utils";
import { update } from "@intercom/messenger-js-sdk";

function AdminSettingsPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState();
  const [tabSelection, setTabSelection] = useState("general");
  const api = useAxiosPrivate();

  const handleTabSelection = (newValue) => {
    setTabSelection(newValue);
  };

  const fetchData = () => {
    setIsLoading(true);
    api
      .get("/admin/settings")
      .then((res) => {
        setSettings(res.data.payload);
      })
      .catch((err) => {
        console.error(err);
        toast({ description: "Could not fetch settings." });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    update({
      hide_default_launcher: true,
    });

    fetchData();
  }, []);

  return (
    <div className="lg:max-w-7xl mx-auto px-4 py-6 space-y-6">
      {isLoading ? (
        <LoadingState />
      ) : settings ? (
        <Tabs className="w-full space-y-6" value={tabSelection} onValueChange={handleTabSelection}>
          <TabsList className="font-medium text-muted-foreground flex justify-center items-start gap-6">
            <TabsTrigger
              className={cn(tabSelection === "general" && "focus:outline-none box-border  border-b border-primary")}
              value="general"
            >
              General
            </TabsTrigger>
            <TabsTrigger
              className={cn(tabSelection === "tos" && "focus:outline-none box-border  border-b border-primary")}
              value="tos"
            >
              TOS
            </TabsTrigger>
            <TabsTrigger
              className={cn(tabSelection === "privacy" && "focus:outline-none box-border  border-b border-primary")}
              value="privacy"
            >
              Privacy
            </TabsTrigger>
          </TabsList>
          <TabsContent value="general">
            <GeneralSettings data={{ PurchasingEnabled: settings.PurchasingEnabled }} />
          </TabsContent>
          <TabsContent value="tos">
            <TosEditor content={settings.TOS} />
          </TabsContent>
          <TabsContent value="privacy">
            <PrivacyEditor content={settings.Privacy} />
          </TabsContent>
        </Tabs>
      ) : (
        <p className="text-sm">An error occurred.</p>
      )}
    </div>
  );
}

export default AdminSettingsPage;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateUserReferralCampaign from "../../../components/referrals/create-user-campaign";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../../components/ui/accordion";
import { Button, buttonVariants } from "../../../components/ui/button";
import { CopyButton } from "../../../components/ui/copy-button";
import Input from "../../../components/ui/input";
import LoadingState from "../../../components/ui/loading-state";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

function UserReferralCampaigns({ data, callbackFn }) {
  const { auth } = useAuth();

  const [showCreateUserCampaignModal, setShowCreateUserCampaignModal] = useState(false);

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-semibold">My campaigns</h2>
      </div>
      <Accordion type="single" collapsible>
        {data.map((item, idx) => (
          <AccordionItem key={item.name} value={`item-${idx}`}>
            <AccordionTrigger>
              <div className="flex-1 flex justify-between items-center">
                <span className="font-mediu">{item.name}</span>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className="grid grid-cols-3 font-medium py-6">
                <div>
                  <div className="text-sm">Referrals</div>
                  <div className="text-secondary-foreground text-sm">{item.totalUsers}</div>
                </div>
                <div>
                  <div className="text-sm">Total purchases</div>
                  <div className="text-secondary-foreground text-sm">{item.totalPurchases}</div>
                </div>
                <div>
                  <div className="text-sm">Commission Rate</div>
                  <div className="text-secondary-foreground text-sm">{item.commissionRate * 100}%</div>
                </div>
              </div>
              <div className="lg:grid lg:grid-cols-2">
                <div>
                  <div className="font-medium text-sm">Referral Link</div>
                  <div className="relative mt-2">
                    <Input readOnly value={`https://thunderproxy.com/?c=${item.code}`} />
                    <div className="absolute inset-y-0 end-0 grid place-items-center pr-2">
                      <CopyButton value={`https://thunderproxy.com/?c=${item.code}`} />
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <Link to={`/referrals/campaigns/${item._id}`} className={buttonVariants({ variant: "link" })}>
                    Details
                  </Link>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
      <button
        disabled={auth?.user.maxCampaigns <= data.length}
        type="button"
        className={buttonVariants()}
        onClick={() => setShowCreateUserCampaignModal(true)}
      >
        Create new campaign
      </button>
      {showCreateUserCampaignModal && (
        <CreateUserReferralCampaign
          open={showCreateUserCampaignModal}
          onOpenChange={setShowCreateUserCampaignModal}
          callbackFn={callbackFn}
        />
      )}
    </div>
  );
}

function ReferralProgram() {
  const { auth } = useAuth();
  const [campaignsData, setCampaignsData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const api = useAxiosPrivate();

  const fetchData = () => {
    api
      .get("/public/affiliate/campaigns")
      .then((res) => {
        setCampaignsData(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <LoadingState />
  ) : (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-semibold">Affiliate program</h1>
      </div>
      <div>
        <p className="text-sm">
          Refer our service and earn money through participating in thunderproxy’ Affiliate Program! You will receive a
          commission for users who register and purchase on thunderproxy.com through your links.
        </p>
      </div>
      {auth?.user?.canRefer && <UserReferralCampaigns data={campaignsData} callbackFn={fetchData} />}
      {!auth?.user?.canRefer && (
        <div className="py-8 grid place-items-center">
          <Button type="button" id="intercomContact">
            Apply now
          </Button>
        </div>
      )}
    </div>
  );
}

export default ReferralProgram;

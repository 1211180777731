import { addDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { buttonVariants } from "../../../ui/button";
import CheckBox from "../../../ui/checkbox";
import StyledDatepicker from "../../../ui/datepicker";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "../../../ui/dialog";
import Icon from "../../../ui/icon";
import Input from "../../../ui/input";
import Label from "../../../ui/label";
import LoadingState from "../../../ui/loading-state";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/select";
import { toast } from "../../../ui/use-toast";

function AddUserSubscriptionModal({ user, open, onOpenChange, callbackFn }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [plans, setPlans] = useState([]);

  const { watch, control, register, handleSubmit } = useForm({
    defaultValues: {
      renewalDate: addDays(new Date(), 30),
      planType: "residential",
      trial: false,
    },
  });

  const watchSelectedPlanType = watch("planType", "residential");

  const api = useAxiosPrivate();

  const onSubmit = (formData) => {
    setIsLoading(true);
    api
      .post(`/admin/users/${user._id}/subscriptions`, formData)
      .then(() => {
        toast({
          title: "Subscription added successfully.",
          variant: "positive",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Could not edit subscription.",
          description: err.response?.data.message || "",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
        onOpenChange(false);
        callbackFn();
      });
  };

  useEffect(() => {
    const fetchPlansData = () => {
      setIsLoadingPlans(true);
      api
        .get("/admin/plans")
        .then((res) => {
          setPlans(res.data.payload.plans);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoadingPlans(false);
        });
    };

    fetchPlansData();
  }, []);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogTitle>
          Add subscription to <span className="text-primary">{user.username}</span>
        </DialogTitle>
        <DialogDescription>
          {isLoadingPlans ? (
            <div className="grid place-items-center py-32">
              <LoadingState />
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div className="space-y-2">
                  <Label>Bandwidth Left</Label>
                  <Input placeholder="Empty for default" {...register("bandwidthLeft")} />
                </div>
                <div className="space-y-2">
                  <Label>Renewal Date</Label>
                  <Controller
                    control={control}
                    name="renewalDate"
                    rules={{ required: true }}
                    render={({ field }) => <StyledDatepicker mode="single" onValueChange={field.onChange} {...field} />}
                  />
                </div>
                <div className="space-y-2">
                  <Label>Plan Type</Label>
                  <Controller
                    control={control}
                    name="planType"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select onValueChange={field.onChange} {...field}>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="residential">Residential</SelectItem>
                          <SelectItem value="datacenter">Datacenter</SelectItem>
                          <SelectItem value="mobile">Mobile</SelectItem>
                          <SelectItem value="datacenter_premium">DC Premium</SelectItem>
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <Label>Plan</Label>
                  <Controller
                    control={control}
                    name="plan"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select disabled={isLoadingPlans} onValueChange={field.onChange} {...field}>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {plans
                            .filter((item) => item.type === watchSelectedPlanType)
                            .map((plan) => (
                              <SelectItem key={plan._id} value={plan._id}>
                                {plan.title} {plan.bandwidth}
                                GB
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <div className="mb-2 text-sm font-medium">Custom string</div>
                  <div className="flex items-center gap-1.5 pt-1.5">
                    <Controller
                      control={control}
                      name="customString"
                      render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                    />
                    Enable override
                  </div>
                </div>
                <div>
                  <Label>Custom User</Label>
                  <Input {...register("customUsername")} />
                </div>
                <div>
                  <Label>Custom Password</Label>
                  <Input {...register("customPassword")} />
                </div>
                <div>
                  <Label>Default Gateway</Label>
                  <Input {...register("gateway")} />
                </div>
                <div className="flex items-center gap-1.5">
                  <Controller
                    control={control}
                    name="trial"
                    render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                  />
                  Mark as trial when invoicing
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <div className="flex items-center gap-1.5">
                    <Controller
                      control={control}
                      name="deleted"
                      render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                    />
                    Subscription frozen
                  </div>
                </div>
                <div className="flex items-center justify-end gap-3">
                  <button
                    type="button"
                    className={buttonVariants({ variant: "outline" })}
                    onClick={(event) => {
                      onOpenChange(false);
                      event.preventDefault();
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={buttonVariants()}>
                    {isLoading && <Icon name="Loader" className="mr-2 h-4 w-4 animate-spin" />}
                    Submit
                  </button>
                </div>
              </div>
            </form>
          )}
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

export default AddUserSubscriptionModal;

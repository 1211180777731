import { format } from "date-fns";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronRight, Wallet } from "lucide-react";
import { formatCurrency } from "../../../utils/Utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import Icon from "../../ui/icon";
import DeleteUserModal from "./modals/delete-user-modal";
import EditUserBalanceModal from "./modals/edit-balance-modal";
import EditUserModal from "./modals/edit-user-details";
import DataTable from "../../ui/data-table";

function AdminUserItem({ item }) {
  return (
    <Link to={`/users/${item._id}`} className="flex items-center">
      <div className="grid grid-cols-2 items-center flex-1">
        <div className="font-medium text-primary">{item.username}</div>
        <div className="text-right text-muted-foreground">
          {item.createdAt ? format(new Date(item.createdAt), "dd MMM yy") : "N/A"}
        </div>
        <div className="text-accent-foreground">{item.email}</div>
        <div className="text-right">{formatCurrency.format(item.totalSpent)}</div>
        <div className="inline-flex items-center text-muted-foreground">
          <Wallet className="w-3 h-3 mr-1" />
          {formatCurrency.format(item.balance)}
        </div>
        <div className="text-right">XX.XXGB</div>
      </div>
      <div>
        <ChevronRight className="w-4 h-4 ml-2 text-muted-foreground" />
      </div>
    </Link>
  );
}

function AdminUserOperations({ data, callbackFn }) {
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showAddBalanceModal, setShowAddBalanceModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="flex h-8 w-8 items-center justify-center rounded-md border transition-colors hover:bg-muted">
          <Icon name="MoreVertical" className="w-3 h-3" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <Link to={`/users/${data._id}`}>
            <DropdownMenuItem>
              <Icon name="Eye" className="w-4 h-4 mr-2" />
              View details
            </DropdownMenuItem>
          </Link>
          <DropdownMenuItem onClick={() => setShowEditUserModal(true)}>
            <Icon name="Pencil" className="w-4 h-4 mr-2" />
            Edit details
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setShowAddBalanceModal(true)}>
            <Icon name="DollarSign" className="w-4 h-4 mr-2" />
            Edit balance
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setShowDeleteUserModal(true)}>
            <Icon name="Trash" className="w-4 h-4 mr-2" />
            Delete account
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <div className="text-xs p-1 font-medium uppercase">View</div>
          <Link to={`/subscriptions?user=${data._id}`}>
            <DropdownMenuItem>
              <Icon name="Package" className="w-4 h-4 mr-2" />
              Subs
            </DropdownMenuItem>
          </Link>
          <Link to={`/invoices?user=${data._id}`}>
            <DropdownMenuItem>
              <Icon name="FileSearch" className="w-4 h-4 mr-2" />
              Invoices
            </DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>

      {showAddBalanceModal && (
        <EditUserBalanceModal
          user={data}
          open={showAddBalanceModal}
          onOpenChange={setShowAddBalanceModal}
          callbackFn={callbackFn}
        />
      )}
      {showDeleteUserModal && (
        <DeleteUserModal
          user={data}
          open={showDeleteUserModal}
          onOpenChange={setShowDeleteUserModal}
          callbackFn={callbackFn}
        />
      )}
      {showEditUserModal && (
        <EditUserModal
          user={data}
          open={showEditUserModal}
          onOpenChange={setShowEditUserModal}
          callbackFn={callbackFn}
        />
      )}
    </>
  );
}

const createAdminUsersDesktopCols = (callbackFn) => [
  {
    accessorKey: "username",
    header: "Username",
    cell: ({ row }) => {
      const user = row.original;
      return (
        <Link
          to={`/users/${user._id}`}
          className="font-medium text-primary hover:text-primary/75 transition-colors hover:border-b border-dotted"
        >
          {user.username}
        </Link>
      );
    },
  },
  {
    accessorKey: "email",
    header: "E-mail",
    cell: ({ row }) => {
      const user = row.original;
      return <div>{user.email}</div>;
    },
  },
  {
    accessorKey: "balance",
    header: "Balance",
    cell: ({ row }) => {
      const user = row.original;
      return <div>{formatCurrency.format(user.balance)}</div>;
    },
  },
  {
    accessorKey: "totalSpent",
    header: "Total Spent",
    cell: ({ row }) => {
      const user = row.original;
      return <div>{formatCurrency.format(user.totalSpent)}</div>;
    },
  },
  {
    accessorKey: "createdAt",
    header: "Registered on",
    cell: ({ row }) => {
      const user = row.original;
      return <div>{format(new Date(user.createdAt), "dd/MM/yyyy, HH:mm:ss")}</div>;
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const user = row.original;

      return (
        <div className="flex justify-end">
          <AdminUserOperations data={user} callbackFn={callbackFn} />
        </div>
      );
    },
  },
];

const createAdminUsersMobileCols = (callbackFn) => [
  {
    accessorKey: "main",
    header: () => "Results",
    cell: ({ row }) => {
      const item = row.original;
      return <AdminUserItem item={item} callbackFn={callbackFn} />;
    },
  },
];

function AdminUsersView({ data, callbackFn }) {
  const adminUsersDesktopCols = createAdminUsersDesktopCols(callbackFn);
  const adminUsersMobileCols = createAdminUsersMobileCols(callbackFn);

  return (
    <section className="animate-in fade-in">
      <div className="hidden lg:block">
        <DataTable columns={adminUsersDesktopCols} data={data} />
      </div>
      <div className="lg:hidden">
        <DataTable columns={adminUsersMobileCols} data={data} />
      </div>
    </section>
  );
}

export default AdminUsersView;

// PurchasingSettings.js
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "../../../ui/use-toast";
import { Button } from "../../../ui/button";
import { Loader2Icon } from "lucide-react";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../../ui/card";
import CheckBox from "../../../ui/checkbox";
import Label from "../../../ui/label";

function PurchasingSettings({ data }) {
  const [isSaving, setIsSaving] = useState(false);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      active: data,
    },
  });

  const api = useAxiosPrivate();

  const onSubmit = (formData) => {
    setIsSaving(true);
    api
      .patch("/admin/settings/purchases", formData)
      .then(() => {
        toast({ title: "Saved successfully." });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  console.log(data);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Purchasing settings</CardTitle>
      </CardHeader>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <div className="flex items-center gap-1">
            <Controller
              control={control}
              name="active"
              render={({ field }) => (
                <div className="flex items-center gap-1">
                  <CheckBox value={field.value} onChange={field.onChange} />
                  <Label>Enabled</Label>
                </div>
              )}
            />
          </div>
        </CardContent>
        <CardFooter>
          <Button type="submit">
            {isSaving && <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />}
            <span>Save</span>
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
}

export default PurchasingSettings;

import { CheckIcon, LockKeyholeIcon, PlugIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Link, Navigate, useLocation } from "react-router-dom";
import RotatingStoreView from "../../components/store/rotating-store-view";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Breadcrumb from "../../components/ui/breadcrumb";
import StaticStoreView from "../../components/store/static-store-view";
import LoadingState from "../../components/ui/loading-state";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";

function StoreFaqSection() {
  const faqItems = [
    {
      question: "What are rotating proxies?",
      answer:
        "Rotating proxies dynamically change the IP address used for each connection request. This provides enhanced anonymity and helps avoid detection and rate limits imposed by target websites. Our rotating proxies include both residential and datacenter IPs, and you can use either user:pass or IP-Auth for authentication.",
    },
    {
      question: "How do sticky sessions work with rotating proxies?",
      answer:
        "Sticky sessions allow you to maintain the same IP address for a certain period or number of requests. This is useful when you need to maintain a consistent session with a website, such as for online shopping or playing games. In sticky mode, we will try to keep the same IP for as long as it's online.",
    },
    {
      question: "What is the pricing model for rotating proxies?",
      answer:
        "Our rotating proxies use a bandwidth-based pricing model where you pay per GB of data used. This flexible and convenient pricing allows you to scale according to your needs.",
    },
    {
      question: "What are static proxies?",
      answer:
        "Static proxies provide fixed IP addresses that do not change over time. These are ideal for tasks that require a consistent IP address, such as managing multiple accounts or accessing geo-restricted content. You can purchase as many static IP addresses as you need and use user:pass authentication.",
    },
    {
      question: "What are the benefits of using static proxies?",
      answer:
        "Static proxies offer several benefits, including:\n- Consistency: You get a fixed IP address that remains the same.\n- Control: Ideal for managing multiple accounts and accessing geo-restricted content.\n- Reliability: No need to worry about IP address changes that might disrupt your activities.",
    },
    {
      question: "What authentication methods are supported?",
      answer:
        "Both our rotating and static proxies support user:pass authentication. Additionally, rotating proxies also support IP-Auth, giving you the flexibility to choose the authentication method that best suits your needs.",
    },
    {
      question: "What protocols are supported by your proxies?",
      answer: "Our proxies support the HTTP protocol, ensuring compatibility with most web services and applications.",
    },
    {
      question: "How do I choose between rotating and static proxies?",
      answer:
        "- Rotating Proxies: Best for tasks requiring high anonymity, such as web scraping or bypassing IP bans.\n- Static Proxies: Best for tasks requiring a consistent IP address, such as managing social media accounts or accessing geo-restricted services.",
    },
    {
      question: "How can I get started with your proxies?",
      answer:
        "To get started, select the type of proxy you need (rotating or static) from our store, choose the appropriate plan, and follow the instructions to configure your proxies with the provided authentication details.",
    },
  ];

  return (
    <div className="space-y-6">
      <h2 className="text-center scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        Frequently Asked Questions
      </h2>
      <Accordion type="single" collapsible className="w-full">
        {faqItems.map((item, index) => (
          <AccordionItem key={item.question} value={`item-${index + 1}`}>
            <AccordionTrigger>{item.question}</AccordionTrigger>
            <AccordionContent>
              {item.answer.split("\n").map((line) => (
                <p key={line}>{line}</p>
              ))}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}

export function RotatingStorePage() {
  const location = useLocation();

  const data = location.state;

  return data === null ? (
    <Navigate to="/store" />
  ) : (
    <div className="space-y-6">
      <Breadcrumb />
      <div>
        <h1 className="text-2xl font-semibold">Pay as you go</h1>
      </div>
      <RotatingStoreView data={data} />
    </div>
  );
}

export function StaticStorePage() {
  const location = useLocation();
  const data = location.state;

  return data === null ? (
    <Navigate to="/store" />
  ) : (
    <div className="space-y-6">
      <Breadcrumb />
      <div>
        <h1 className="text-2xl font-semibold">Pay per address</h1>
      </div>
      <StaticStoreView data={data} />
    </div>
  );
}

function Store() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);

    api
      .get("/public/store")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <LoadingState />
  ) : (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold">
        <Trans i18nKey="dashboard.user.store.heading.title" />
      </h1>
      <div className="grid lg:grid-cols-2 gap-6">
        <Link to="/store/rotating" state={data.gb}>
          <Card className="hover:border-primary">
            <CardHeader>
              <CardTitle>Rotating proxies (IPv4)</CardTitle>
              <CardDescription>
                Bandwidth-based pricing and everly refreshing pools with sticky sessions.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ul className="space-y-1.5">
                <li className="flex items-center gap-2">
                  <CheckIcon className="w-4 h-4 text-green-600/25" />
                  <span className="leading-3">Residential and Datacenter proxies</span>
                </li>
                <li className="flex items-center gap-2">
                  <CheckIcon className="w-4 h-4 text-green-600/25" />
                  <span className="leading-3">Flexible and convenient pricing - pay per GB</span>
                </li>
                <li className="flex items-center gap-2">
                  <LockKeyholeIcon className="w-4 h-4 text-muted-foreground" />
                  <span className="leading-3">user:pass and IP-Auth</span>
                </li>
                <li className="flex items-center gap-2">
                  <PlugIcon className="w-4 h-4 text-muted-foreground" />
                  <span className="leading-3">
                    Protocol: <span className="font-medium">HTTP</span>
                  </span>{" "}
                </li>
              </ul>
            </CardContent>
          </Card>
        </Link>
        <Link to="/store/static" state={data.ip}>
          <Card className="hover:border-primary">
            <CardHeader>
              <CardTitle>Static proxies (IPv4)</CardTitle>
              <CardDescription>
                Purchase the exact amount of IPs you need and keep the addresses across time.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ul className="space-y-1.5">
                <li className="flex items-center gap-2">
                  <CheckIcon className="w-4 h-4 text-green-600/25" />
                  <span className="leading-3">ISP Proxies</span>
                </li>
                <li className="flex items-center gap-2">
                  <CheckIcon className="w-4 h-4 text-green-600/25" />
                  <span className="leading-3">Buy as many addresses as you need</span>
                </li>
                <li className="flex items-center gap-2">
                  <LockKeyholeIcon className="w-4 h-4 text-muted-foreground" />
                  <span className="leading-3">user:pass Authentication</span>
                </li>
                <li className="flex items-center gap-2">
                  <PlugIcon className="w-4 h-4 text-muted-foreground" />
                  <span className="leading-3">
                    Protocol: <span className="font-medium">HTTP</span>
                  </span>
                </li>
              </ul>
            </CardContent>
          </Card>
        </Link>
      </div>
      <StoreFaqSection />
    </div>
  );
}

export default Store;

import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import BalanceBlock from "./balance-block";
import LanguageSelector from "./language-selector";
import MobileNav from "./mobile-nav";
import Icon from "./ui/icon";
import UserBlock from "./user-block";
import Logo from "../assets/images/logo_v2.svg";
import { Link } from "react-router-dom";

function Header({ menuConfig }) {
  const { auth } = useAuth();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <>
      <div className="flex w-full justify-between fixed top-0 z-10 h-16 flex-shrink-0 bg-card border-b items-center px-4 md:px-6 ">
        <div className="flex items-center gap-2">
          <button
            type="button"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className="lg:hidden border rounded justify-center items-center p-2"
          >
            <Icon name={showMobileMenu ? "X" : "Menu"} className="w-6 h-6" />
          </button>
          <Link to="/" className="hidden lg:flex justify-center gap-1 items-center border-bottom border-border -mt-1">
            <img src={Logo} className="object-contain h-7 -mb-1" alt="" />
            <span className="leading-5 text-foreground font-semibold text-2xl">thunderproxy</span>
          </Link>
        </div>
        <div className="flex w-full justify-end items-center gap-x-2 px-4">
          {auth?.user.role !== "Administrator" && !auth?.user.manager && <LanguageSelector />}
          {auth?.user.role !== "Administrator" && !auth?.user.manager && <BalanceBlock />}
          <UserBlock />
        </div>
      </div>
      {showMobileMenu && <MobileNav menuConfig={menuConfig} open={showMobileMenu} onOpenChange={setShowMobileMenu} />}
    </>
  );
}

export default Header;

/* eslint-disable no-undef */
export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return navigator.clipboard.writeText(text);
  }
  return document.execCommand("copy", true, text);
}

export function generateRandom8DigitString() {
  let randomNumber;
  let randomString;

  do {
    randomNumber = Math.floor(Math.random() * 1e8);
    randomString = randomNumber.toString();
  } while (randomString.length !== 8 || randomString[0] === "0");

  return randomString;
}

export const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

// Function that calculates the from current date
// Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result *= 100;
  return Math.floor(result);
};

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  const result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const createToken = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

import { format } from "date-fns";
import saveFile from "easy-file-saver";
import { useContext, useState } from "react";
import { generateSubscriptionProxies } from "../../lib/subscription";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { Button } from "../ui/button";
import { CardDescription, CardTitle } from "../ui/card";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import Label from "../ui/label";
import { RadioGroup, RadioGroupItem } from "../ui/radiogroup";
import { SubscriptionContext } from "../ui/subscription-provider";
import { FormatConfiguratorView } from "./format-configurator-view";
import { GeotargetingConfiguratorView } from "./geotargeting-configurator-view";
import { RotationConfiguratorView } from "./rotation-configurator-view";
import { DownloadIcon, Loader2Icon } from "lucide-react";

export const generateFormattedProxies = (data, proxyFormat) =>
  data.map((item) => {
    switch (proxyFormat) {
      case "host:port:user:pass":
        return `${item.host}:${item.port}:${item.user}:${item.password}`;
      case "user:pass@host:port":
        return `${item.user}:${item.password}@${item.host}:${item.port}`;
      case "proxyuri":
        return `http://${item.user}:${item.password}@${item.host}:${item.port}`;
      default:
        throw new Error(`Unsupported proxy format: ${proxyFormat}`);
    }
  });

function SubscriptionExportFileModal({ open, onOpenChange, subConfig }) {
  const { subscription } = useContext(SubscriptionContext);
  const { subInfo, authentication, plan } = subscription;

  const [proxyFormat, setProxyFormat] = useState("host:port:user:pass");
  const [extension, setExtension] = useState("txt");

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    setIsDownloading(true);
    const data = generateSubscriptionProxies(subInfo, authentication, subConfig, plan);
    const formattedData = generateFormattedProxies(data, proxyFormat);
    const fileContent = formattedData.join("\n");
    saveFile({
      data: fileContent,
      filename: `thunderproxy_${format(new Date(), "dd-MM-yyyy")}.${extension}`,
    });
    setIsDownloading(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button>
          <DownloadIcon className="w-4 h-4 mr-2" />
          Export
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Download your proxy list</DialogTitle>
        </DialogHeader>
        <div className="space-y-3">
          <FormatConfiguratorView format={proxyFormat} onValueChange={setProxyFormat} />
          <div className="space-y-2">
            <div className="text-lg font-semibold">Extension</div>
            <RadioGroup value={extension} onValueChange={setExtension}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="txt" id="txt" />
                <Label htmlFor="txt">.txt</Label>
              </div>
            </RadioGroup>
          </div>
        </div>
        <Alert>
          <AlertTitle>Important!</AlertTitle>
          <AlertDescription>
            Your browser may request permission to download files before allowing the download of the proxy list. It
            usually shows up in the top left corner.
          </AlertDescription>
        </Alert>
        <DialogFooter>
          <Button variant="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button disabled={isDownloading} onClick={() => handleDownload()}>
            {isDownloading ? <Loader2Icon className="w-4 h-4 mr-2 animate-spin" /> : null}
            Download
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function SubscriptionExportFileView() {
  const { subscription } = useContext(SubscriptionContext);
  const { subConfig, geoTargeting } = subscription;

  const [open, setOpen] = useState(false);

  const defaultSubConfig = {
    rotation: "rotating",
    country: "ANY",
    state: "",
    city: "",
  };

  const [exportSubConfig, setExportSubConfig] = useState(defaultSubConfig);
  const handleChangeExportSubConfig = (data) => {
    setExportSubConfig((old) => ({ ...old, ...data }));
  };

  return (
    <div>
      <div>
        <CardTitle>File</CardTitle>
        <CardDescription>
          Export your proxy list as a file. Selecting geo-targeting settings here won&apos;t invalidate the data
          (including IP auth) you see in Quick Access (on the left) - you can still use both at the same time.
        </CardDescription>
      </div>
      <div className="space-y-3">
        {geoTargeting && (
          <GeotargetingConfiguratorView value={exportSubConfig} onValueChange={handleChangeExportSubConfig} />
        )}
        {!!subConfig.rotation && (
          <RotationConfiguratorView value={exportSubConfig} onValueChange={handleChangeExportSubConfig} forList />
        )}
      </div>
      <div className="grow flex gap-2 items-center justify-end">
        <SubscriptionExportFileModal open={open} onOpenChange={setOpen} subConfig={exportSubConfig} />
      </div>
    </div>
  );
}

export { SubscriptionExportFileModal, SubscriptionExportFileView };

import Output from "editorjs-react-renderer";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import LoadingState from "../components/ui/loading-state";

function PrivacyPage() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get("/landing/privacy")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="max-w-7xl mx-auto space-y-6 py-6 px-3">
      <h2 className="text-3xl font-bold">Privacy Policy</h2>
      {isLoading ? (
        <LoadingState />
      ) : data ? (
        <div className="h-[full] p-3 bg-background-primary rounded prose prose-invert max-w-none">
          <Output data={data} />
        </div>
      ) : (
        <div>Error</div>
      )}
    </div>
  );
}

export default PrivacyPage;

import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { Button } from "../../../ui/button";
import CheckBox from "../../../ui/checkbox";
import {
  DrawerDialog,
  DrawerDialogContent,
  DrawerDialogHeader,
  DrawerDialogOverlay,
  DrawerDialogPortal,
  DrawerDialogTitle,
} from "../../../ui/drawer-dialog";
import Icon from "../../../ui/icon";
import Input from "../../../ui/input";
import { toast } from "../../../ui/use-toast";

function EditUserModal({ user, open, onOpenChange, callbackFn }) {
  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      username: user?.username,
      email: user?.email,
      password: "",
      cryptoPaymentProcessorEnabled: user?.cryptoPaymentProcessorEnabled || false,
      balancePaymentProcessorEnabled: user?.balancePaymentProcessorEnabled || false,
      cardPaymentProcessorEnabled: user?.cardPaymentProcessorEnabled || false,
      paddlePaymentProcessorEnabled: user?.paddlePaymentProcessorEnabled || false,
      cardMinimum: user?.cardMinimum,
      cardMaximum: user?.cardMaximum,
      isDeveloper: user?.isDeveloper,
      canRefer: user?.canRefer,
      isAffiliate: user?.isAffiliate,
      isBusiness: user?.isBusiness,
      canContributeToCommission: user?.canContributeToCommission,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const api = useAxiosPrivate();

  const onSubmit = (data) => {
    setIsLoading(true);
    api
      .put(`/admin/users/${user?._id}`, data)
      .then(() => {
        toast({
          title: "User details edited successfully.",
          variant: "positive",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error when updating user",
          variant: "negative",
        });
      })
      .finally(() => {
        onOpenChange(false);
        callbackFn();
        setIsLoading(false);
      });
  };

  return (
    <DrawerDialog open={open} onOpenChange={onOpenChange}>
      <DrawerDialogPortal>
        <DrawerDialogOverlay />
        <DrawerDialogContent>
          <DrawerDialogHeader>
            <DrawerDialogTitle>
              {user ? (
                <p>
                  Modify details for
                  <span className="text-blue-500">{user.username}</span>
                </p>
              ) : (
                "Create a new user"
              )}
            </DrawerDialogTitle>
            <button type="button" onClick={() => onOpenChange(false)}>
              <Icon name="X" className="text-muted-foreground hover:text-white transition-colors w-4 h-4" />
            </button>
          </DrawerDialogHeader>
          <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-1.5">
              <div className="block text-muted-foreground font-medium">Account information</div>
              <div>
                <span htmlFor="first-name" className="block text-sm font-medium text-secondary-foreground">
                  Username
                </span>
                <Input {...register("username")} />
              </div>
              <div>
                <span htmlFor="first-name" className="block text-sm font-medium text-secondary-foreground">
                  E-mail
                </span>
                <Input {...register("email")} />
              </div>
              <div>
                <span htmlFor="first-name" className="block text-sm font-medium text-secondary-foreground">
                  New password
                </span>
                <Input {...register("password")} />
              </div>
            </div>
            <div className="space-y-1.5">
              <span htmlFor="first-name" className="block text-muted-foreground font-medium">
                Payment methods
              </span>
              <div className="grid lg:grid-cols-3 gap-3">
                <div className="flex items-center gap-3 text-sm">
                  <Controller
                    control={control}
                    name="cryptoPaymentProcessorEnabled"
                    render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                  />
                  Crypto
                </div>
                <div className="flex items-center gap-3 text-sm">
                  <Controller
                    control={control}
                    name="cardPaymentProcessorEnabled"
                    render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                  />
                  Cards
                </div>
                <div className="flex items-center gap-3 text-sm">
                  <Controller
                    control={control}
                    name="balancePaymentProcessorEnabled"
                    render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                  />
                  Balance
                </div>
                <div className="flex items-center gap-3 text-sm">
                  <Controller
                    control={control}
                    name="paddlePaymentProcessorEnabled"
                    render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                  />
                  Paddle
                </div>
              </div>
              <div className="space-y-1.5">
                <span htmlFor="first-name" className="text-muted-foreground block text font-medium">
                  Permissions
                </span>
                <div className="space-y-1.5">
                  <div className="flex items-center gap-3 text-sm">
                    <Controller
                      control={control}
                      name="canContributeToCommission"
                      render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                    />
                    Can contribute to commission
                  </div>
                  <div className="flex items-center gap-3 text-sm">
                    <Controller
                      control={control}
                      name="canRefer"
                      render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                    />
                    Can refer
                  </div>
                  <div className="flex items-center gap-3 text-sm">
                    <Controller
                      control={control}
                      name="isAffiliate"
                      render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                    />
                    Is affiliate
                  </div>
                  <div className="flex items-center gap-3 text-sm">
                    <Controller
                      control={control}
                      name="isDeveloper"
                      render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                    />
                    Has API access
                  </div>
                  <div className="flex items-center gap-3 text-sm">
                    <Controller
                      control={control}
                      name="isBusiness"
                      render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                    />
                    Is a business
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-1.5 items-center justify-end">
              <Button type="button" onClick={() => onOpenChange(false)} variant="outline">
                Cancel
              </Button>
              <Button type="submit" disabled={isLoading}>
                Submit
              </Button>
            </div>
          </form>
        </DrawerDialogContent>
      </DrawerDialogPortal>
    </DrawerDialog>
  );
}

export default EditUserModal;

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button, buttonVariants } from "../components/ui/button";
import Intercom from "@intercom/messenger-js-sdk";

function NotFound() {
  return (
    <>
      <Helmet>
        <title>Not Found | thunderproxy</title>
      </Helmet>
      <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold  tracking-tight sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-muted-foreground">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/" className={buttonVariants()}>
              Go back home
            </Link>
            <Button onClick={() => Intercom("show")} variant="link">
              Contact support <span aria-hidden="true">&rarr;</span>
            </Button>
          </div>
        </div>
      </main>
    </>
  );
}

export default NotFound;

import classNames from "classnames";
import React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

const CopyOnClickInput = React.forwardRef((props, ref) => {
  const { className, type, ...restProps } = props;

  const handleCopyOnClick = () => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(props.value);
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <input
          onClick={handleCopyOnClick}
          ref={ref}
          type={type}
          className={classNames(
            "hover:border-primary cursor-default",
            "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
            className,
          )}
          {...restProps}
        />
      </TooltipTrigger>
      <TooltipContent>Click to copy</TooltipContent>
    </Tooltip>
  );
});

CopyOnClickInput.displayName = "CopyOnClickInput";

export default CopyOnClickInput;

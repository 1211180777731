import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import { format } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { cn } from "../../../lib/utils";
import { buttonVariants } from "../../ui/button";
import Icon from "../../ui/icon";
import { toast } from "../../ui/use-toast";

function TosEditor({ content }) {
  const ejInstance = useRef();
  const [isSaving, setIsSaving] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const { handleSubmit } = useForm();

  const api = useAxiosPrivate();

  const initEditor = useCallback(async () => {
    if (!ejInstance.current) {
      const editor = new EditorJS({
        holder: "editorjs",
        onReady: () => {
          ejInstance.current = editor;
        },
        placeholder: "Type here to write your post...",
        data: content,
        inlineToolbar: true,
        tools: {
          header: Header,
        },
      });
    }
  }, [content]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      initEditor();
    }

    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, [isMounted, initEditor]);

  async function onSubmit() {
    setIsSaving(true);
    const blocks = await ejInstance.current?.save();
    api
      .put("/admin/settings/tos", blocks)
      .then(() => {
        toast({
          description: "Your post has been saved.",
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast({
            title: "Error when updating TOS",
            description: err.response.data.message ? err.response.data.message : "Check console",
          });
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  if (!isMounted) {
    return null;
  }

  return (
    <div className="space-y-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid w-full gap-10">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center space-x-10">
              <p className="text-sm text-muted-foreground italic">
                {content?.time && format(new Date(content.time), "d MMM yyyy. HH:mm")}
              </p>
            </div>
            <button type="submit" className={cn(buttonVariants())}>
              {isSaving && <Icon name="Loader" className="mr-2 h-4 w-4 animate-spin" />}
              <span>Save</span>
            </button>
          </div>
          <div className="prose prose-stone dark:prose-invert mx-auto w-[800px]">
            <h2 className="text-3xl font-bold">Terms of Service</h2>
            <p className="text-sm text-gray-500">
              Use <kbd className="rounded-md border bg-muted px-1 text-xs uppercase">Tab</kbd> to open the command menu.
            </p>
            <div id="editorjs" className="rounded-lg min-h-[500px] px-3 ring-1 ring-white/5" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default TosEditor;

import Output from "editorjs-react-renderer";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import LoadingState from "../components/ui/loading-state";

function TermsPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get("/landing/terms")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="max-w-7xl mx-auto space-y-6 py-6 px-3">
      <h2 className="text-3xl font-bold">Terms of Service</h2>
      <div className="w-full h-[full] p-3 rounded prose dark:prose-invert max-w-none">
        {isLoading ? <LoadingState /> : data ? <Output data={data} /> : <div>Error</div>}
      </div>
    </div>
  );
}

export default TermsPage;

const menuConfig = {
  user: [
    {
      icon: "LayoutDashboard",
      name: "Dashboard",
      href: "/",
    },
    {
      icon: "ShoppingBag",
      name: "Store",
      href: "/store",
    },
    {
      icon: "Wallet",
      name: "Wallet",
      href: "/wallet",
    },
    {
      icon: "FileText",
      name: "Invoices",
      href: "/invoices",
    },
    {
      icon: "Network",
      name: "Referrals",
      href: "/referrals",
    },
    {
      icon: "HelpCircle",
      name: "HelpCenter",
      href: "/help",
    },
  ],
  manager: [
    {
      icon: "BarChart4",
      name: "Dashboard",
      href: "/",
    },
    {
      icon: "Users",
      name: "Users",
      href: "/users",
    },
    {
      icon: "FileText",
      name: "Invoices",
      href: "/invoices",
    },
  ],
  admin: [
    {
      icon: "BarChart4",
      name: "Dashboard",
      href: "/",
      i18nKey: "",
    },
    {
      icon: "Users",
      name: "Users",
      href: "/users",
      i18nKey: "",
    },
    {
      icon: "FileText",
      name: "Invoices",
      href: "/invoices",
      i18nKey: "",
    },
    {
      icon: "ShoppingCart",
      name: "Plans",
      href: "/plans",
      i18nKey: "",
    },
    {
      icon: "Package",
      name: "Subs",
      href: "/subscriptions",
      i18nKey: "",
    },
    {
      icon: "Network",
      name: "Campaigns",
      href: "/referrals/campaigns",
    },
    {
      icon: "ListOrdered",
      name: "PayoutsQueue",
      href: "/referrals/campaigns/payouts/queue",
      i18nKey: "",
    },
    {
      icon: "Ticket",
      name: "Coupons",
      href: "/coupons",
      i18nKey: "",
    },
    {
      icon: "Cog",
      name: "Settings",
      href: "/settings",
      i18nKey: "",
    },
  ],
};

export default menuConfig;

import { useEffect, useState } from "react";
import UserDashboardView from "../../components/dashboard/user/user-dashboard-view";
import LoadingState from "../../components/ui/loading-state";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import useAuth from "../../hooks/useAuth";
import { MailIcon, UserRoundXIcon } from "lucide-react";
import { VerifyEmailModal } from "./profile";

export const UserNotVerifiedAlert = () => {
  return (
    <Alert>
      <UserRoundXIcon className="h-4 w-4" />
      <AlertTitle>You have not verified your e-mail.</AlertTitle>
      <AlertDescription>
        A valid e-mail address is required in order to receive information about your purchases. Also, if you have opted
        in to receive e-mails regarding promotional offers, you may be eligible to take advantage of them only if you
        have verified your e-mail address.
      </AlertDescription>
      <VerifyEmailModal />
    </Alert>
  );
};

export const MailInSpamAlert = () => {
  return (
    <Alert>
      <MailIcon className="h-4 w-4" />
      <AlertTitle>Our e-mails may be wrongfully sent into spam.</AlertTitle>
      <AlertDescription>
        If that happens, press &quot;Mark as non-spam&quot; or similar to keep receiving e-mails from us.
      </AlertDescription>
    </Alert>
  );
};

function Homepage() {
  const [isLoading, setIsLoading] = useState(true);
  const api = useAxiosPrivate();
  const [data, setData] = useState();

  const { auth } = useAuth();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get("/public/dashboard")
      .then((res) => {
        setData(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="space-y-6">
      <MailInSpamAlert />
      {!auth.user.verified && <UserNotVerifiedAlert />}
      {isLoading ? <LoadingState /> : <UserDashboardView data={data} />}
    </div>
  );
}

export default Homepage;

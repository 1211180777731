import { generateRandom8DigitString } from "../utils/Utils";

const generateSubscriptionUsername = (subInfo, plan, subConfig, authentication) => {
  let result;

  if (subInfo.type === "gb") {
    result = authentication.user;
    if (plan.type === "residential") {
      if (subConfig.state) {
        result += "-res_sc";
      } else {
        result += "-res";
      }

      if (subConfig.country === "ROW") {
        if (subConfig.state) {
          result += `-US_${subConfig.state}`;
          if (subConfig.city) {
            result += `_${subConfig.city}`;
          }
        } else {
          result += `-ROW`;
        }
      } else {
        result += `-${subConfig.country}`;

        if (subConfig.state) {
          result += `_${subConfig.state}`;

          if (subConfig.city) {
            result += `_${subConfig.city}`;
          }
        }
      }
    }

    if (plan.type === "datacenter_premium") {
      result = `${authentication.user}-dc_premium`;

      if (subConfig.country) {
        result += `-${subConfig.country}`;
      } else {
        result += "-ANY";
      }
    }

    if (plan.type === "datacenter") {
      result = `${authentication.user}-dc`;
      if (subConfig.country) {
        result += `-${subConfig.country}`;
      } else {
        result += "-ANY";
      }
    }

    if (subConfig.rotation === "sticky") {
      result += `-sid-${generateRandom8DigitString()}`;
    }

    return result;
  }

  return result;
};

const generateSubscriptionProxies = (subInfo, authentication, subConfig, plan, externalIps) => {
  const result = [];

  if (subInfo.type === "ip") {
    for (let i = 0; i < subInfo.quantity; i += 1) {
      const proxy = {
        user: `${authentication.user}-stc-isp-sid-${i}`,
        password: authentication.password,
        host: subInfo.usesResolvedHost === true ? authentication.resolvedHost : authentication.host,
        port: authentication.port,
      };

      if (externalIps && externalIps.length > 0) {
        proxy.externalIp = externalIps[i].split(":")[0];
      }

      result.push(proxy);
    }
  }

  if (subInfo.type === "gb") {
    if (subConfig.rotation === "rotating") {
      result.push({
        user: generateSubscriptionUsername(subInfo, plan, subConfig, authentication),
        password: authentication.password,
        host: subInfo.usesResolvedHost === true ? authentication.resolvedHost : authentication.host,
        port: authentication.port,
      });
    }
    if (subConfig.rotation === "sticky") {
      for (let i = 0; i < subConfig.sessions; i += 1) {
        result.push({
          user: generateSubscriptionUsername(subInfo, plan, subConfig, authentication),
          password: authentication.password,
          host: subInfo.usesResolvedHost === true ? authentication.resolvedHost : authentication.host,
          port: authentication.port,
        });
      }
    }
  }

  return result;
};

const generateSubscriptionExampleCurl = (mode, host, port, username, password) => {
  let result;
  if (mode === "ipauth") {
    result = `curl -x ${host}:${port} ip-api.com`;
  }
  if (mode === "authentication") {
    result = `curl -x ${username}:${password}@${host}:${port} ip-api.com`;
  }

  return result;
};

const generateSubscriptionUri = (username, password, host, port) => `http://${username}:${password}@${host}:${port}`;

export {
  generateSubscriptionProxies,
  generateSubscriptionExampleCurl,
  generateSubscriptionUsername,
  generateSubscriptionUri,
};

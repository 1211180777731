import { startOfMonth, startOfWeek, subDays, subMonths } from "date-fns";

export const periods = {
  last7Days: {
    from: subDays(new Date(), 7),
    to: new Date(),
    defaultUnit: "day",
  },
  last30Days: {
    from: subDays(new Date(), 30),
    to: new Date(),
    defaultUnit: "week",
  },
  last3Months: {
    from: subMonths(new Date(), 3),
    to: new Date(),
    defaultUnit: "week",
  },
  currentWeek: {
    from: startOfWeek(new Date(), { weekStartsOn: 1 }),
    to: new Date(),
    defaultUnit: "day",
  },
  currentMonth: {
    from: startOfMonth(new Date()),
    to: new Date(),
    defaultUnit: "week",
  },
};

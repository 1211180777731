import clsx from "clsx";
import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "../ui/alert-dialog";
import { buttonVariants } from "../ui/button";
import CheckBox from "../ui/checkbox";
import Icon from "../ui/icon";
import { toast } from "../ui/use-toast";
import { RadioGroup, RadioGroupItem } from "@radix-ui/react-radio-group";

function AddFundsModal({ open, onOpenChange }) {
  const { auth } = useAuth();
  const [amount, setAmount] = useState(10);
  const [hasAcceptedTos, setHasAcceptedTos] = useState();
  const [purchasePaymentMethod, setPurchasePaymentMethod] = useState("cryptocurrency");
  const { i18n } = useTranslation();

  const handleAmountChange = (newValue) => {
    setAmount(newValue);
  };

  const handlePurchasePaymentMethodChange = (newValue) => {
    setPurchasePaymentMethod(newValue);
  };

  const api = useAxiosPrivate();

  const handleAddFunds = () => {
    api
      .post("/public/topup", {
        balance: amount,
        paymentMethod: purchasePaymentMethod,
      })
      // eslint-disable-next-line no-undef
      .then((res) => window.open(res.data.payload.checkoutUrl, "_self"))
      .catch((err) => {
        toast({
          title: "Could not top-up",
          description: `${err.response?.data.message}`,
          variant: "negative",
        });
      });
  };

  const handleToggleAcceptTos = () => {
    setHasAcceptedTos(!hasAcceptedTos);
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent className="bg-popover border">
          <AlertDialogHeader>
            <AlertDialogTitle>
              <Trans i18nKey="dashboard.user.modals.addfunds.title" />
            </AlertDialogTitle>
            <AlertDialogDescription className="text-muted-foreground">
              <Trans i18nKey="dashboard.user.modals.addfunds.caption" />
            </AlertDialogDescription>
          </AlertDialogHeader>
          <div className="flex justify-between items-center gap-2">
            <div className="grow flex items-center justify-center text-2xl">
              $
              <CurrencyInput
                value={amount}
                placeholder="0.00"
                decimalsLimit={2}
                className="border-box w-24 bg-transparent text-right focus:outline-none border-b border-slate-700"
                onValueChange={handleAmountChange}
              />
            </div>
          </div>
          <RadioGroup
            className="flex flex-col border rounded text-sm transition"
            value={purchasePaymentMethod}
            onValueChange={handlePurchasePaymentMethodChange}
          >
            <RadioGroupItem
              value="cryptocurrency"
              className={purchasePaymentMethod === "cryptocurrency" && "ring-1 ring-primary rounded-t"}
            >
              <div className="flex flex-col p-2 gap-1">
                <div className="inline-flex items-center font-medium gap-1">
                  <Trans i18nKey="globals.payment_methods.crypto.title" />
                  <Icon name="Bitcoin" className="w-4 h-4 text-muted-foreground" />
                </div>
                <div className="text-left text-xs text-muted-foreground">
                  Bitcoin (BTC), Ethereum (ETH), USD Coin (USDC), Dogecoin (DOGE), Litecoin (LTC), Bitcoin Cash (BCH),
                  Tether (USDT). Polygon: MATIC, USDC
                </div>
              </div>
            </RadioGroupItem>
            {auth?.user.cardPaymentProcessorEnabled && (
              <RadioGroupItem
                disabled={amount < auth?.cardMinimum || amount > auth.cardMaximum}
                value="card"
                className={clsx(
                  purchasePaymentMethod === "card" && "text-muted-foreground ring-1 ring-primary rounded-b",
                  (amount < auth?.cardMinimum || amount > auth.cardMaximum) &&
                    "cursor-not-allowed text-muted-foreground",
                )}
              >
                <div className="flex flex-col p-2 gap-1">
                  <div className="inline-flex items-center font-medium gap-1.5">
                    <Trans i18nKey="globals.payment_methods.cards.title" />
                    <Icon name="CreditCard" className="w-4 h-4" />
                  </div>
                  <div className="text-left text-xs text-muted-foreground">
                    <Trans i18nKey="dashboard.user.modals.purchase.card.description" />
                  </div>
                  <div className="text-left text-xs text-primary">
                    <Trans i18nKey="dashboard.user.modals.purchase.card.disclaimer.part1" />${auth?.user?.cardMinimum}
                    <Trans i18nKey="dashboard.user.modals.purchase.card.disclaimer.part2" />${auth?.user?.cardMaximum}
                  </div>
                </div>
              </RadioGroupItem>
            )}
          </RadioGroup>
          {i18n.language === "ru" && (
            <div className="text-xs italic">
              Нуждаетесь в пополнении через российские карты, QIWI?{" "}
              <a
                className="text-primary hover:text-primary/95 transition-colors"
                href="https://t.me/markelov_o"
                rel="noreferrer noopener"
                target="_blank"
              >
                Обратитесь в ТГ
              </a>
            </div>
          )}
          <div className="inline-flex items-center gap-2 text-sm">
            <CheckBox value={hasAcceptedTos} onValueChange={handleToggleAcceptTos} />
            <span>
              <Trans i18nKey="dashboard.user.modals.purchase.acceptance" />
              <Link
                className="underline hover:text-primary transition-colors"
                to="/tos"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans i18nKey="dashboard.user.modals.purchase.tos" />
              </Link>
              .
            </span>
          </div>
          <AlertDialogFooter>
            <AlertDialogCancel className={buttonVariants({ variant: "outline" })}>
              <Trans i18nKey="globals.buttons.actions.cancel" />
            </AlertDialogCancel>
            <AlertDialogAction
              disabled={!hasAcceptedTos || !amount}
              onClick={handleAddFunds}
              className={buttonVariants()}
            >
              <Trans i18nKey="globals.buttons.actions.confirm" />
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}

export default AddFundsModal;

import { format as formatDate } from "date-fns";
import saveFile from "easy-file-saver";
import { DownloadIcon } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { generateSubscriptionProxies } from "../../lib/subscription";
import { Button } from "../ui/button";
import { CopyButton } from "../ui/copy-button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import Label from "../ui/label";
import NumberField from "../ui/number-field";
import { RadioGroup, RadioGroupItem } from "../ui/radiogroup";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { SubscriptionContext } from "../ui/subscription-provider";
import { Textarea } from "../ui/textarea";
import { generateFormattedProxies } from "./subscription-export-file-view";

const CitySelect = ({ data, value, onValueChange }) => {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectItem>Random</SelectItem>
        {data?.map((item) => (
          <SelectItem key={item.city} value={item.city}>
            {item.city}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

const StateSelect = ({ data, value, onValueChange }) => {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectItem>Random</SelectItem>
        {data?.map((item) => (
          <SelectItem key={item.state} value={item.state}>
            {item.state}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

const CountrySelect = ({ data, value, onValueChange }) => {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="ANY">Random (Worldwide)</SelectItem>
        {data?.map((item) => (
          <SelectItem key={item.code} value={item.code}>
            {item.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

const SubscriptionLegacyGenerator = () => {
  const { subscription } = useContext(SubscriptionContext);

  const { subInfo, geoTargeting, authentication, plan } = subscription;

  const [result, setResult] = useState("");

  const [quantity, setQuantity] = useState(1);
  const [rotation, setRotation] = useState("rotating");
  const [format, setFormat] = useState("host:port:user:pass");

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const handleDownload = async () => {
    saveFile({
      data: result,
      filename: `thunderproxy_${formatDate(new Date(), "dd-MM-yyyy-HH:mm:ss")}.${"txt"}`,
    });
  };

  const handleGenerateProxies = () => {
    const proxies = generateSubscriptionProxies(
      subInfo,
      authentication,
      {
        sessions: quantity,
        rotation,
        country,
        state,
        city,
      },
      plan,
    );

    if (rotation === "rotating" && quantity > 1) {
      const preliminaryResult = generateFormattedProxies(proxies, format) + "\n";
      setResult(preliminaryResult.repeat(quantity));
    } else {
      setResult(generateFormattedProxies(proxies, format).join("\n"));
    }
  };

  useEffect(() => {
    handleGenerateProxies();
  }, [quantity, rotation, format, country, state, city]);

  const filterStates = geoTargeting.states?.filter((item) => item.country === country);
  const filterCities = geoTargeting.cities?.filter((item) => item.country === country && item.state === state);

  const handleSelectCountry = (country) => {
    setState("");
    setCity("");
    setCountry(country);
  };

  const handleSelectState = (state) => {
    setCity("");
    setState(state);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="link" className="px-0">
          Legacy Generator
        </Button>
      </DialogTrigger>
      <DialogContent className="lg:max-w-5xl">
        <DialogHeader>
          <DialogTitle>Legacy Generator</DialogTitle>
        </DialogHeader>
        <div className="grid lg:grid-cols-2 gap-3">
          <div className="space-y-1">
            <Label>Country</Label>
            <CountrySelect data={geoTargeting.countries} value={country} onValueChange={handleSelectCountry} />
          </div>
          <div className="space-y-1">
            <Label>State</Label>
            <StateSelect
              disabled={country === "ANY" || !country}
              data={filterStates ?? []}
              value={state}
              onValueChange={handleSelectState}
            />
          </div>
          <div className="space-y-1 ">
            <Label>City</Label>
            <CitySelect disabled={!state} data={filterCities ?? []} value={city} onValueChange={setCity} />
          </div>
        </div>
        <div className="grid gap-3">
          <div className="space-y-1">
            <Label>Session type</Label>
            <RadioGroup value={rotation} onValueChange={setRotation}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="rotating" id="rotating" />
                <Label htmlFor="rotating">Rotating</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="sticky" id="sticky" />
                <Label htmlFor="sticky">Sticky</Label>
              </div>
            </RadioGroup>
          </div>
          <div className="space-y-1">
            <Label>Quantity</Label>
            <NumberField value={quantity} onValueChange={setQuantity} min={1} max={20000} />
          </div>
          <div className="space-y-1">
            <Label>Format</Label>
            <Select value={format} onValueChange={setFormat}>
              <SelectTrigger>
                <SelectValue placeholder="host:port:user:pass" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="host:port:user:pass">host:port:user:pass</SelectItem>
                <SelectItem value="user:pass@host:port">user:pass@host:port</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-1">
            <div className="flex items-center justify-between">
              <Label>Result</Label>
              <div className="flex gap-1">
                <CopyButton value={result} />
                <Button
                  variant="ghost"
                  type="button"
                  size="icon"
                  className={
                    "relative h-6 w-6 text-muted-foreground hover:bg-muted hover:text-foreground [&_svg]:w-3 [&_svg]:h-3"
                  }
                  onClick={handleDownload}
                >
                  <DownloadIcon />
                </Button>
              </div>
            </div>
            <Textarea value={result} defaultValue={result} readOnly rows={5} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionLegacyGenerator;

import { MinusIcon, PlusIcon } from "lucide-react";
import React from "react";

function NumberField({ value, onValueChange, min, max, step = 1, ...props }) {
  const increment = () => {
    onValueChange((prev) => Math.min(prev + 1 * step, max));
  };

  const decrement = () => {
    onValueChange((prev) => Math.max(prev - 1 * step, min));
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue)) {
      onValueChange(Math.min(Math.max(newValue, min), max));
    }
  };

  return (
    <div className="w-fit flex items-center space-x-2 border rounded bg-background h-10">
      <button
        type="button"
        onClick={decrement}
        disabled={value <= min}
        aria-label="Decrease value"
        className="focus:outline-none px-2"
      >
        <MinusIcon className="h-4 w-4" />
      </button>
      <input
        id="number-input"
        type="number"
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        className="w-20 text-center bg-transparent focus:outline-none text-sm"
        step={step}
        {...props}
      />
      <button
        type="button"
        onClick={increment}
        disabled={value >= max}
        aria-label="Increase value"
        className="focus:outline-none px-2"
      >
        <PlusIcon className="h-4 w-4" />
      </button>
    </div>
  );
}

export default NumberField;

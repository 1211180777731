import { update } from "@intercom/messenger-js-sdk";
import classNames from "classnames";
import { addMonths, addQuarters, addWeeks, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Bar,
  BarChart,
  Cell,
  Label,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { DatePickerWithRange } from "../../components/ui/datepicker-with-range";
import EmptyState from "../../components/ui/empty-state";
import LoadingState from "../../components/ui/loading-state";
import DashboardHeader from "../../components/ui/page-header";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { toast } from "../../components/ui/use-toast";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { periods } from "../../lib/admin";
import { formatCurrency } from "../../utils/Utils";

function formatDateLabel(label, unit) {
  let firstPart = "";
  let secondPart = "";

  switch (unit) {
    case "hour":
      firstPart = format(new Date(label), "LLL do, HH:00 - HH:59");
      break;
    case "week":
      firstPart = format(new Date(label), "LLL do");
      secondPart = format(addWeeks(new Date(label), 1), "LLL do");
      break;
    case "month":
      firstPart = format(new Date(label), "LLL do");
      secondPart = format(addMonths(new Date(label), 1), "LLL do");
      break;
    case "quarter":
      firstPart = `${format(new Date(label), "QQQ yyyy")} - ${format(new Date(label), "LLL do")}`;
      secondPart = format(addQuarters(new Date(label), 1), "LLL do");
      break;
    default:
      firstPart = format(new Date(label), "LLL do");
      break;
  }

  return secondPart ? `${firstPart} - ${secondPart}` : firstPart;
}

function CustomizedTooltip({ active, payload, label, unit, monetary }) {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-md border bg-background p-2 shadow-sm">
        <div className="flex flex-col">
          <span className="text-[0.70rem] uppercase text-muted-foreground">{formatDateLabel(label, unit)}</span>
          <span className="font-bold text-muted-foreground">
            {monetary ? formatCurrency.format(payload[0].value) : payload[0].value}
          </span>
        </div>
      </div>
    );
  }

  return null;
}

function BandwidthAllocated({ filters }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  const typeColors = {
    residential: "#0088FE",
    datacenter: "#00C49F",
    mobile: "#FFBB28",
    datacenter_premium: "#FEFEFE",
  };

  function CustomLabel({ viewBox, labelText, value }) {
    const { cx, cy } = viewBox;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fill="hsl(var(--muted-foreground))"
          fontSize="15"
        >
          {labelText}
        </text>
        <text
          x={cx}
          y={cy + 20}
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fill="hsl(var(--foreground))"
          fontSize="26"
          fontWeight="600"
        >
          {value} GB
        </text>
      </g>
    );
  }

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      api
        .get("/admin/statistics/allocated-bandwidth", { params: filters })
        .then((res) => setData(res.data))
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setIsLoading(false));
    };

    if (filters?.from && filters?.to) {
      fetchData();
    }
  }, [filters]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Bandwidth allocated</CardTitle>
      </CardHeader>
      <CardContent className="h-full">
        <div className="grid place-items-center h-[200px]">
          {isLoading && <LoadingState />}
          {!isLoading && data?.result.length === 0 && <EmptyState />}
          {!isLoading && data && (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width="100%" height="100%" nameKey="type" dataKey="allocated">
                <Pie
                  data={data?.result}
                  dataKey="allocated"
                  nameKey="type"
                  cx="50%"
                  cy="50%"
                  fill="#8884d8"
                  innerRadius="80%"
                  outerRadius="100%"
                  style={{
                    stroke: "hsl(var(--background))",
                  }}
                >
                  {data?.result.map((entry) => (
                    <Cell key={entry} fill={typeColors[entry.type]} />
                  ))}
                  <Label content={<CustomLabel labelText="Total" value={data?.total} />} position="center" />
                </Pie>
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className="rounded-md border bg-background p-2 shadow-sm">
                          <div className="flex flex-col">
                            <span className="text-[0.70rem] uppercase text-muted-foreground">{payload[0].name}</span>
                            <span className="font-bold text-muted-foreground">{payload[0].value} GB</span>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

function TopPlans({ filters }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      api
        .get("/admin/statistics/plans/top", { params: filters })
        .then((res) => setData(res.data))
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setIsLoading(false));
    };

    if (filters?.from && filters?.to) {
      fetchData();
    }
  }, [filters]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Most popular plans</CardTitle>
      </CardHeader>
      <CardContent>
        {!isLoading &&
          data &&
          data.map((item) => (
            <div key={`${item.type}${item.bandwidth}`} className="flex items-center">
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none uppercase">
                  {item.type} {item.bandwidth}
                  GB
                </p>
                <p className="text-sm text-muted-foreground">
                  Count:
                  {item.soldCount}
                </p>
              </div>
              <div className="ml-auto font-medium">{formatCurrency.format(item.price)}</div>
            </div>
          ))}
        {!isLoading && data?.length === 0 && <EmptyState />}
      </CardContent>
    </Card>
  );
}

function NewUsers({ filters }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  useEffect(() => {
    const fetchData = () => {
      api
        .get("/admin/statistics/charts/users", { params: filters })
        .then((res) => setData(res.data))
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setIsLoading(false));
    };

    if (filters?.from && filters?.to) {
      fetchData();
    }
  }, [filters]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>New users</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid place-items-center h-[200px]">
          {isLoading && <LoadingState />}
          {!!data?.result.length && (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data?.result}
                margin={{
                  top: 5,
                  right: 10,
                  left: 10,
                  bottom: 0,
                }}
              >
                <Bar
                  nameKey="x"
                  dataKey="y"
                  style={{
                    fill: "hsl(var(--primary))",
                    opacity: 1,
                  }}
                />
                <Tooltip
                  content={({ active, payload, label }) => (
                    <CustomizedTooltip active={active} payload={payload} label={label} unit={data?.unit} />
                  )}
                />
                <XAxis dataKey="x" tick={null} />
              </BarChart>
            </ResponsiveContainer>
          )}
          {!isLoading && data?.length === 0 && <EmptyState />}
        </div>
      </CardContent>
    </Card>
  );
}

function IncomeLineChart({ data }) {
  const fallbackData = [
    {
      x: "2024-03-17T23:00:00.000Z",
      y: 1000.0,
    },
    {
      x: "2024-03-17T23:00:00.000Z",
      y: 1200.0,
    },
    {
      x: "2024-03-17T23:00:00.000Z",
      y: 900.0,
    },
    {
      x: "2024-03-17T23:00:00.000Z",
      y: 1300.0,
    },
    {
      x: "2024-03-17T23:00:00.000Z",
      y: 2000.0,
    },
    {
      x: "2024-03-17T23:00:00.000Z",
      y: 1600.0,
    },
    {
      x: "2024-03-17T23:00:00.000Z",
      y: 600.0,
    },
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data?.result ?? fallbackData}
        margin={{
          top: 15,
          right: 10,
          left: 10,
          bottom: 0,
        }}
      >
        <Tooltip
          content={({ active, payload, label }) => (
            <CustomizedTooltip active={active} payload={payload} label={label} unit={data?.unit} monetary />
          )}
        />
        <Line
          type="monotone"
          strokeWidth={2}
          dataKey="y"
          activeDot={{
            r: 8,
            style: { fill: "hsl(var(--primary))" },
          }}
          dot={false}
          style={{
            stroke: "hsl(var(--primary))",
          }}
        />
        <XAxis dataKey="x" tick={null} axisLine={false} />
      </LineChart>
    </ResponsiveContainer>
  );
}

function LookupFilters({ value, onValueChange }) {
  const [date, setDate] = useState({
    from: value?.from ?? periods.currentWeek.from,
    to: value?.to ?? periods.currentWeek.to,
  });
  const [period, setPeriod] = useState("currentWeek");
  const [unit, setUnit] = useState(period ? periods.currentWeek.defaultUnit : value?.unit);

  const handleChangePeriod = (p) => {
    setPeriod(p);
    setDate(periods[p]);
    setUnit(periods[p].defaultUnit);
  };

  const handleInputDate = (d) => {
    setPeriod("");
    setDate(d);
  };

  useEffect(() => {
    let newUnit = unit;

    if (!period) {
      newUnit = null;
    }

    onValueChange({ ...value, ...date, unit: newUnit });
  }, [date, unit]);

  return (
    <div className="flex items-center gap-1">
      <Select value={period} onValueChange={(value) => handleChangePeriod(value)}>
        <SelectTrigger>
          <span className="mr-1">Period:</span>
          <SelectValue placeholder="Custom" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="last7Days">Last 7 days</SelectItem>
          <SelectItem value="last30Days">Last 30 days</SelectItem>
          <SelectItem value="last3Months">Last 3 months</SelectItem>
          <SelectItem value="currentWeek">This week</SelectItem>
          <SelectItem value="currentMonth">This month</SelectItem>
        </SelectContent>
      </Select>
      <DatePickerWithRange mode="range" date={date} setDate={handleInputDate} />
    </div>
  );
}

function MainChart({ filters }) {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const api = useAxiosPrivate();

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      api
        .get("/admin/statistics/charts/income", { params: filters })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Error loading income chart",
          });
        })
        .finally(() => setIsLoading(false));
    };
    if (filters?.from && filters?.to) {
      fetchData();
    }
  }, [filters]);

  return (
    <Card className="h-full flex flex-col">
      <CardHeader>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1 whitespace-nowrap">
            <div className="w-2 h-2 rounded-full bg-primary" />
            <div className="text-sm font-medium">Income</div>
          </div>
          <div>
            <span className="text-muted-foreground uppercase font-medium">
              {!!data?.totalAmount && `${formatCurrency.format(data.totalAmount)}`}
            </span>
          </div>
        </div>
      </CardHeader>
      <CardContent className={classNames("flex-1", isLoading && "blur-sm")}>
        <IncomeLineChart data={data} />
      </CardContent>
    </Card>
  );
}

function TopCustomers({ filters }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      api
        .get("/admin/statistics/customers/top", { params: filters })
        .then((res) => setData(res.data))
        .catch((err) => {
          console.error(err);
          toast({ title: "Could not load top customer stats" });
        })
        .finally(() => setIsLoading(false));
    };
    if (filters?.from && filters?.to) {
      fetchData();
    }
  }, [filters]);

  return (
    <Card className="h-full">
      <CardHeader>
        <CardTitle>Top customers</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6 h-full">
        {(isLoading || !data) && (
          <>
            <div className="blur-sm flex items-center">
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none">Olivia Martin</p>
                <p className="text-sm text-muted-foreground">olivia.martin@email.com</p>
              </div>
              <div className="ml-auto font-medium">+$1,999.00</div>
            </div>
            <div className="blur-sm flex items-center">
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none">Jackson Lee</p>
                <p className="text-sm text-muted-foreground">jackson.lee@email.com</p>
              </div>
              <div className="ml-auto font-medium">+$39.00</div>
            </div>
            <div className="blur-sm flex items-center">
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none">Isabella Nguyen</p>
                <p className="text-sm text-muted-foreground">isabella.nguyen@email.com</p>
              </div>
              <div className="ml-auto font-medium">+$299.00</div>
            </div>
            <div className="blur-sm flex items-center">
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none">William Kim</p>
                <p className="text-sm text-muted-foreground">will@email.com</p>
              </div>
              <div className="ml-auto font-medium">+$99.00</div>
            </div>
            <div className="blur-sm flex items-center">
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none">Sofia Davis</p>
                <p className="text-sm text-muted-foreground">sofia.davis@email.com</p>
              </div>
              <div className="ml-auto font-medium">+$39.00</div>
            </div>
          </>
        )}
        {!isLoading &&
          data &&
          data.map((item) => (
            <Link key={item._id} to={`/users/${item._id}`} className="flex items-center">
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none">{item.username}</p>
                <p className="text-sm text-muted-foreground">{item.email}</p>
              </div>
              <div className="ml-auto font-medium">{formatCurrency.format(item.totalSpending)}</div>
            </Link>
          ))}
        {!isLoading && data?.length === 0 && <EmptyState />}
      </CardContent>
    </Card>
  );
}

function BrowseableStats() {
  const [filters, setFilters] = useState();

  return (
    <>
      <div className="hidden lg:flex items-center justify-between">
        <h2 className="text-lg font-medium">Overview</h2>
        <LookupFilters value={filters} onValueChange={setFilters} />
      </div>
      <div className="lg:grid grid-cols-3 gap-3 items-stretch">
        <div className="col-span-2">
          <MainChart filters={filters} />
        </div>
        <div>
          <TopCustomers filters={filters} />
        </div>
      </div>
      <div className="lg:grid grid-cols-3 gap-6 items-stretch">
        <NewUsers filters={filters} />
        <TopPlans filters={filters} />
        <BandwidthAllocated filters={filters} />
      </div>
    </>
  );
}

function DashboardStats() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  useEffect(() => {
    setIsLoading(true);
    api
      .get("/admin/statistics/dashboard")
      .then((res) => setData(res.data))
      .catch((err) => {
        console.error(err);
        toast({ title: "Could not load dashboard stats" });
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader>
            <CardTitle className="text-sm font-medium">Today</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">...</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-sm font-medium">This week</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">...</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-sm font-medium">This month</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">...</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-sm font-medium">This year</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">...</div>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (data) {
    return (
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader>
            <CardTitle className="text-sm font-medium">Today</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{formatCurrency.format(data.today)}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-sm font-medium">This week</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{formatCurrency.format(data.thisWeek)}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-sm font-medium">This month</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{formatCurrency.format(data.thisMonth)}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-sm font-medium">This year</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{formatCurrency.format(data.thisYear)}</div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const AdminDashboardHomePage = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    update({
      hide_default_launcher: true,
    });
  });

  return (
    <div className="space-y-6">
      <DashboardHeader heading="Dashboard" />
      <DashboardStats />
      <BrowseableStats />
    </div>
  );
};

export default AdminDashboardHomePage;

// Push a "view_item" event to GTM data layer
export const pushViewItemEvent = (product, totalValue) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "view_item",
    value: totalValue,
    currency: "USD",
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        item_category: product.category,
        item_variant: product.variant,
        price: product.price,
      },
    ],
  });
};

// Push a "sign_up" event to GTM data layer
export const pushSignUpEvent = (method) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "sign_up",
    category: "User",
    method: method, // Specify the method (e.g., 'Email', 'Google')
  });
};

import React from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import Header from "../components/header";
import Sidebar from "../components/sidebar";
import Toaster from "../components/ui/toaster";
import { TooltipProvider } from "../components/ui/tooltip";
import menuConfig from "../config/navigation";
import useAuth from "../hooks/useAuth";
import Banner from "../components/dashboard/user/banner";

function DashboardLayout() {
  const { auth } = useAuth();

  return (
    <>
      <Helmet>
        <title>thunderproxy - Dashboard</title>
      </Helmet>
      <div className="flex flex-col h-screen">
        <Header menuConfig={auth?.user?.role === "Administrator" ? menuConfig.admin : menuConfig.user} />
        <div className="flex flex-1">
          <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col bg-popover">
            <Sidebar
              menuConfig={
                auth?.user?.role === "Administrator"
                  ? menuConfig.admin
                  : auth?.user?.manager
                    ? menuConfig.manager
                    : menuConfig.user
              }
            />
          </div>
          <div className="flex flex-col flex-1 min-h-screen lg:pl-64 pt-[63px]">
            <Banner />
            <div>
              <TooltipProvider delayDuration={50}>
                <div className="lg:max-w-7xl mx-auto px-4 py-6 space-y-6">
                  <Outlet />
                </div>
              </TooltipProvider>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
}
export default DashboardLayout;

import * as SwitchPrimitive from "@radix-ui/react-switch";
import React, { forwardRef } from "react";

const Switch = forwardRef((props, ref) => (
  <SwitchPrimitive.Root
    ref={ref}
    className="w-[42px] h-[25px] bg-muted border rounded-full relative focus:shadow-[0_0_0_2px] focus:shadow-black data-[state=checked]:bg-green-700 outline-none cursor-default"
    {...props}
  >
    <SwitchPrimitive.Thumb
      ref={ref}
      className="block w-[21px] h-[21px] bg-card rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]"
    />
  </SwitchPrimitive.Root>
));

Switch.displayName = "Switch";

export default Switch;

import { boot } from "@intercom/messenger-js-sdk";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import RequireAuth from "./components/require-auth";
import useAuth from "./hooks/useAuth";
import AdminDashboardLayout from "./layout/Admin";
import AuthLayout from "./layout/Auth";
import DashboardLayout from "./layout/Index";
import RootLayout from "./layout/Root";
import PersistLogin from "./layout/persist-login";
import AdminCampaignsPage from "./pages/admin/campaigns";
import AdminCampaignDetailPage from "./pages/admin/campaigns/[id]";
import AdminReferralPayoutsQueuePage from "./pages/admin/campaigns/payouts";
import AdminCouponsPage from "./pages/admin/coupons";
import AdminDashboardHomePage from "./pages/admin/dashboard";
import AdminInvoicesPage from "./pages/admin/invoices";
import AdminInvoiceDetailPage from "./pages/admin/invoices/[id]";
import Plans from "./pages/admin/plans";
import AdminSettingsPage from "./pages/admin/settings";
import AdminSubscriptions from "./pages/admin/subscriptions";
import AdminUsersPage from "./pages/admin/users";
import AdminUserDetailPage from "./pages/admin/users/[id]";
import Login from "./pages/auth/Login";
import RecoverPassword from "./pages/auth/RecoverPassword";
import Register from "./pages/auth/Register";
import ResetPassword from "./pages/auth/ResetPassword";
import Success from "./pages/auth/Success";
import VerifyEmailPage from "./pages/auth/Verify";
import NotFound from "./pages/not-found";
import PaymentFailed from "./pages/payment-failed";
import PaymentSuccess from "./pages/payment-success";
import TermsPage from "./pages/terms-of-service";
import ReferralProgram from "./pages/users/campaigns";
import UserCampaignDetailPage from "./pages/users/campaigns/[id]";
import HelpCenter from "./pages/users/help-center";
import Homepage from "./pages/users/homepage";
import UserInvoicesPage from "./pages/users/invoices";
import UserMyProfilePage from "./pages/users/profile";
import Store, { RotatingStorePage, StaticStorePage } from "./pages/users/store";
import UserSubscriptionPage from "./pages/users/subscriptions/[id]";
import Wallet from "./pages/users/wallet";
import TagManager from "react-gtm-module";
import PrivacyPage from "./pages/privacy";

function App() {
  // This is required for i18n to work properly - do not remove.
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  const { auth } = useAuth();
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const queryParams = new URLSearchParams(window.location.search);
    const campaignName = queryParams.get("c");
    if (campaignName) {
      // eslint-disable-next-line no-undef
      document.cookie = `campaign=${campaignName}; path=/; max-age=3600; domain=thunderproxy.com`;
    }
  }, []);

  boot({
    app_id: "vpk9gshe",
    email: auth?.user?.email,
    user_id: auth?.user?.id,
  });

  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: location.pathname,
        pageTitle: document.title,
      },
    });
  }, [location]);

  return (
    <Routes>
      <Route element={<RootLayout />}>
        <Route path="/" element={<PersistLogin />}>
          {auth?.accessToken ? (
            <Route>
              {auth?.user?.role === "Administrator" ? (
                <Route element={<RequireAuth adminOnly />}>
                  <Route element={<AdminDashboardLayout />}>
                    <Route index element={<AdminDashboardHomePage />} />
                    <Route path="plans" element={<Plans />} />
                    <Route path="users" element={<AdminUsersPage />} />
                    <Route path="users/:userId" element={<AdminUserDetailPage />} />
                    <Route path="referrals" element={<Outlet />}>
                      <Route path="campaigns" element={<Outlet />}>
                        <Route index element={<AdminCampaignsPage />} />
                        <Route path="payouts/queue" element={<AdminReferralPayoutsQueuePage />} />
                        <Route path=":campaignId" element={<AdminCampaignDetailPage />} />
                      </Route>
                    </Route>
                    <Route path="referrals/" />
                    <Route path="referrals/campaigns/:campaignId" element={<AdminCampaignDetailPage />} />
                    <Route path="referrals/campaigns/payouts/queue" element={<AdminReferralPayoutsQueuePage />} />
                    <Route path="coupons" element={<AdminCouponsPage />} />
                    <Route path="settings" element={<AdminSettingsPage />} />
                    <Route path="invoices" element={<AdminInvoicesPage />} />
                    <Route path="invoices/:invoiceId" element={<AdminInvoiceDetailPage />} />
                    <Route path="subscriptions" element={<AdminSubscriptions />} />
                    <Route path="subscriptions/:id" element={<UserSubscriptionPage />} />
                  </Route>
                </Route>
              ) : (
                <Route element={<RequireAuth />}>
                  <Route element={<DashboardLayout />}>
                    <Route index element={<Homepage />} />
                    <Route path="store" element={<Store />} />
                    <Route path="store/rotating" element={<RotatingStorePage />} />
                    <Route path="store/static" element={<StaticStorePage />} />
                    <Route path="subscriptions/:id" element={<UserSubscriptionPage />} />
                    <Route path="wallet" element={<Wallet />} />
                    <Route path="invoices" element={<UserInvoicesPage />} />
                    <Route path="help" element={<HelpCenter />} />
                    <Route path="payment-failed" element={<PaymentFailed />} />
                    <Route path="payment-success" element={<PaymentSuccess />} />
                    <Route path="profile" element={<UserMyProfilePage />} />
                    <Route path="referrals" element={<ReferralProgram />} />
                    <Route path="referrals/campaigns/:campaignId" element={<UserCampaignDetailPage />} />
                    <Route path="tos" element={<TermsPage />} />
                    <Route path="auth/verify-email/:token" element={<VerifyEmailPage />} />
                  </Route>
                </Route>
              )}
              <Route path="*" element={<NotFound />} />
            </Route>
          ) : (
            <Route>
              <Route index element={<Navigate to="/auth/login" />} />
              <Route path="auth" element={<AuthLayout />}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="reset" element={<RecoverPassword />} />
                <Route path="reset-password/:token" element={<ResetPassword />} />
                <Route path="register/success" element={<Success />} />
                <Route path="verify-email/:token" element={<VerifyEmailPage />} />
                <Route path="tos" element={<TermsPage />} />
                <Route path="privacy" element={<PrivacyPage />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          )}
        </Route>
      </Route>
    </Routes>
  );
}

export default App;

import { format, formatDistanceToNow } from "date-fns";
import { enGB } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { formatBytes } from "../../../lib/utils";
import { formatCurrency } from "../../../utils/Utils";
import { AdminInvoiceOperations } from "../../invoices/admin/invoices-view";
import { AdminSubsOperations } from "../../subscriptions/admin/subscriptions-view";
import { Badge } from "../../ui/badge";
import Breadcrumb from "../../ui/breadcrumb";
import { buttonVariants } from "../../ui/button";
import { Card, CardContent } from "../../ui/card";
import CircularProgress from "../../ui/circular-progress";
import { CopyButton } from "../../ui/copy-button";
import DataTableWithPagination from "../../ui/data-table-pagination";
import EmptyState from "../../ui/empty-state";
import Icon from "../../ui/icon";
import LoadingState from "../../ui/loading-state";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { toast } from "../../ui/use-toast";
import AddUserSubscriptionModal from "../../users/admin/modals/add-subscription-modal";
import DeleteUserModal from "../../users/admin/modals/delete-user-modal";
import EditUserBalanceModal from "../../users/admin/modals/edit-balance-modal";
import EditUserModal from "../../users/admin/modals/edit-user-details";

const createAdminCampaignsDesktopCols = () => [
  {
    accessorKey: "_id",
    header: "Campaign ID",
    cell: ({ row }) => (
      <div>
        <span className="truncate font-mono text-xs">
          ...
          {row.getValue("_id").slice(-8)}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "_id",
    header: "Name",
    cell: ({ row }) => <span>{row.original.name}</span>,
  },
  {
    accessorKey: "_id",
    header: "Code",
    cell: ({ row }) => <span>{row.original.code}</span>,
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const item = row.original;

      return (
        <div className="flex w-full justify-end">
          <Link to={`/referrals/campaigns/${item._id}`}>
            <Icon
              name="Eye"
              className="flex h-4 w-4 items-center justify-center rounded-md transition-colors hover:bg-muted"
            />
          </Link>
        </div>
      );
    },
  },
];

const createAdminSubsDesktopCols = (callbackFn) => [
  {
    accessorKey: "_id",
    header: "# ID",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div className="flex items-center">
          <div className={`mr-[12px] h-3 w-3 shrink-0 rounded-full bg-${item.deleted ? "gray" : "green"}-700`} />
          <span className="font-mono text-sm">{item._id}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "authUser",
    header: "Auth User",
    cell: ({ row }) => {
      const item = row.original;
      return <span className="font-mono text-sm text-muted-foreground">{item.authUser}</span>;
    },
  },
  {
    accessorKey: "planType",
    header: "Type",
    cell: ({ row }) => {
      const item = row.original;
      return <div className="text-sm uppercase">{item.planType}</div>;
    },
  },
  {
    id: "usage",
    header: "Add. Info",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div className="inline-flex items-center gap-2">
          {item.type === "gb" && (
            <>
              <CircularProgress progress={(item.bandwidthLeft / item.plan.bandwidthBytes) * 100} />
              <span className="text-sm whitespace-nowrap">
                {formatBytes(item.bandwidthLeft)}/{formatBytes(item.plan.bandwidthBytes)}
              </span>
            </>
          )}
          {item.type === "ip" && (
            <>
              <span className="text-sm">
                {item.quantity} IPs - {item.externalProviderCountry.displayName}
              </span>
            </>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "renewalDate",
    header: "Expiry",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <span className="text-muted-foreground text-xs">
          {item.renewalDate ? format(new Date(item.renewalDate), "dd MMM") : "N/A"}
        </span>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div className="flex w-full justify-end">
          <AdminSubsOperations data={item} callbackFn={callbackFn} referrer="user-detail" />
        </div>
      );
    },
  },
];

const createAdminInvoicesDesktopCols = (callbackFn) => [
  {
    accessorKey: "_id",
    header: "Invoice ID",
    cell: ({ row }) => (
      <div>
        <span className="truncate font-mono text-xs">
          ...
          {row.getValue("_id").slice(-8)}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "amount",
    header: "Amount",
    cell: ({ row }) => {
      const item = row.original;
      return <div className="text-right font-semibold">{formatCurrency.format(item.price)}</div>;
    },
  },
  {
    id: "status",
    header: "Status",
    cell: ({ row }) => {
      const item = row.original;
      // Paid correctly
      if (item.paid) {
        return (
          <Badge variant="secondary">
            Completed
            <Icon name="Check" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      // Underpayment, overpayment
      if (item.unresolvedStatus) {
        return (
          <Badge>
            Payment issue
            <Icon name="AlertTriangle" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      // Payment detected, awaiting confirmation
      if (item.pending) {
        return (
          <Badge variant="outline">
            Pending
            <Icon name="Hourglass" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      // No payment has been detected yet
      if (!item.paid) {
        return (
          <Badge variant="outline">
            Unpaid
            <Icon name="Clock3" className="ml-1.5 w-3 h-3" />
          </Badge>
        );
      }
      return (
        <Badge variant="outline">
          Unknown
          <Icon name="QuestionMark" className="ml-1.5 w-3 h-3" />
        </Badge>
      );
    },
  },
  {
    accessorKey: "title",
    header: "Contents",
    cell: ({ row }) => {
      const item = row.original;
      return <span>{item.title}</span>;
    },
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    cell: ({ row }) => {
      const item = row.original;
      return format(new Date(item.createdAt), "dd MMM, HH:mm");
    },
  },
  {
    accessorKey: "paymentProcessor",
    header: "Processor",
    cell: ({ row }) => {
      const item = row.original;
      if (item.paymentProcessor === "balance") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="Wallet" className="w-3 h-3" />
            Balance
          </div>
        );
      }
      if (item.paymentProcessor === "cryptocurrency") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="Bitcoin" className="w-3 h-3" />
            Crypto
          </div>
        );
      }
      if (item.paymentProcessor === "coingate") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="Bitcoin" className="w-3 h-3" />
            Coingate
          </div>
        );
      }
      if (item.paymentProcessor === "card") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="CreditCard" className="w-3 h-3" />
            Card
          </div>
        );
      }
      if (item.paymentProcessor === "trial") {
        return (
          <div className="inline-flex items-center gap-1 text-blue-200">
            <Icon name="Gift" className="w-3 h-3" />
            Trial
          </div>
        );
      }
      if (item.paymentProcessor === "admin") {
        return (
          <div className="inline-flex items-center gap-1">
            <Icon name="HelpingHand" className="w-3 h-3" />
            Admin
          </div>
        );
      }
      return (
        <div className="inline-flex items-center gap-1">
          <Icon name="QuestionMark" className="w-3 h-3" />
          Unknown
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const item = row.original;

      return (
        <div className="flex w-full justify-end">
          <AdminInvoiceOperations data={item} callbackFn={callbackFn} />
        </div>
      );
    },
  },
];

function AdminUserDetailPageViewCampaignsTab({ userId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get(`/admin/users/${userId}/campaigns`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        toast({
          title: "Could not load user profile",
          variant: "negative",
        });
        console.log(err);
        navigate("/users");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const desktopCols = createAdminCampaignsDesktopCols(fetchData);

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <LoadingState />
  ) : data ? (
    <DataTableWithPagination columns={desktopCols} data={data} />
  ) : (
    <EmptyState />
  );
}

function AdminUserDetailPageViewInvoicesTab({ userId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get(`/admin/users/${userId}/invoices`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        toast({
          title: "Could not load user profile",
          variant: "negative",
        });
        console.log(err);
        navigate("/users");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const desktopCols = createAdminInvoicesDesktopCols(fetchData);

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <LoadingState />
  ) : data ? (
    <DataTableWithPagination columns={desktopCols} data={data} />
  ) : (
    <EmptyState />
  );
}

function AdminUserDetailPageViewSubsTab({ userId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get(`/admin/users/${userId}/subscriptions`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        toast({
          title: "Could not load user profile",
          variant: "negative",
        });
        console.log(err);
        navigate("/users");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const desktopCols = createAdminSubsDesktopCols(fetchData);

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <LoadingState />
  ) : data ? (
    <DataTableWithPagination columns={desktopCols} data={data} />
  ) : (
    <EmptyState />
  );
}

function AdminUserDetailPageView({ data, callbackFn }) {
  const [showAddBalanceModal, setShowAddBalanceModal] = useState(false);
  const [showAddSubscriptionModal, setShowAddSubscriptionModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const [displaySelection, setDisplaySelection] = useState("subscriptions");

  return (
    <div className="px-4 py-6 space-y-6">
      <Breadcrumb />
      <div className="space-y-3">
        <Card className="flex items-center gap-3 p-3">
          <div className="size-16 rounded-full ring-1 ring-foreground/5 grid place-items-center p-3">
            <Icon name="User" />
          </div>
          <div className="flex-1 min-w-0">
            <span className="block text-sm font-medium">{data.username}</span>
            <span className="text-sm text-muted-foreground">
              {data.intercomInfo?.location.country ?? data.country?.country ?? null}
            </span>
          </div>
          {data.intercomInfo && (
            <a
              className={buttonVariants({ variant: "link" })}
              href={`https://app.intercom.com/a/apps/vpk9gshe/users/${data.intercomInfo.id}`}
            >
              <Icon name="ExternalLink" className="w-3 h-3" />
            </a>
          )}
        </Card>
        <div className="lg:grid lg:grid-cols-3 gap-3">
          <div className="space-y-3">
            <div className="flex items-center justify-between">
              <span className="font-medium">Details</span>
            </div>
            <Card>
              <CardContent className="space-y-6 mt-6">
                <div className="flex items-center gap-1.5">
                  <Icon name="Mail" className="w-4 h-34 text-secondary-foreground" />
                  <span className="text-sm flex-1">{data.email}</span>
                  <CopyButton value={data.email} />
                </div>
                <div className="flex items-center gap-1.5">
                  <Icon name="Wallet" className="w-4 h-4 text-secondary-foreground" />
                  <span className="text-sm">{formatCurrency.format(data.balance)}</span>
                </div>
                <div className="flex items-center gap-1.5">
                  <Icon name="Calendar" className="w-4 h-4 text-secondary-foreground" />
                  <span className="text-sm">
                    Signed up {formatDistanceToNow(new Date(data.createdAt), { addSuffix: true, locale: enGB })}
                  </span>
                </div>
                {!!data.totalSpent && (
                  <div className="flex items-center gap-1.5">
                    <Icon name="DollarSign" className="w-4 h-4 text-secondary-foreground" />
                    <span className="text-sm">
                      Spent
                      {formatCurrency.format(data.totalSpent)}
                    </span>
                  </div>
                )}
                {!!data.totalData && (
                  <div className="flex items-center gap-1.5">
                    <Icon name="Database" className="w-4 h-4 text-secondary-foreground" />
                    <span className="text-sm">
                      Consumed {data.totalData}
                      GB
                    </span>
                  </div>
                )}
                <div className="h-[1px] bg-foreground/5" />
                {data.isBusiness && (
                  <div className="flex items-center gap-1.5">
                    <Icon name="Briefcase" className="w-4 h-4 text-secondary-foreground" />
                    <span className="text-sm">Business account</span>
                  </div>
                )}
                {data.isAffiliate && (
                  <div className="flex items-center gap-1.5">
                    <Icon name="Network" className="w-4 h-4 text-secondary-foreground" />
                    <span className="text-sm">Enrolled in affiliate program</span>
                  </div>
                )}
                {data.canRefer && (
                  <div className="flex items-center gap-1.5">
                    <Icon name="UserPlus" className="w-4 h-4 text-secondary-foreground" />
                    <span className="text-sm">Can refer</span>
                  </div>
                )}
                {data.referredBy && (
                  <div className="flex items-center gap-1.5">
                    <Icon name="UserPlus" className="w-4 h-4 text-secondary-foreground" />
                    <span className="text-sm">
                      Referred by{" "}
                      <Link to={`/campaigns/${data.referredBy._id}`} className={buttonVariants({ variant: "link" })}>
                        {data.referredBy.user.username}
                      </Link>
                    </span>
                  </div>
                )}
                {data.canContributeToCommission && (
                  <div className="flex items-center gap-1.5">
                    <Icon name="Check" className="w-4 h-4 text-secondary-foreground" />
                    <span className="text-sm">Can generate commission</span>
                  </div>
                )}
                {data.isDeveloper && (
                  <div className="flex items-center gap-1.5">
                    <Icon name="Wrench" className="w-4 h-34 text-secondary-foreground" />
                    <span className="text-sm flex-1">Has API access</span>
                  </div>
                )}
                <div className="flex items-center gap-1.5">
                  <Icon name="Calendar" className="w-4 h-4 text-secondary-foreground" />
                  <span className="text-sm">
                    Signed up {formatDistanceToNow(new Date(data.createdAt), { addSuffix: true, locale: enGB })}
                  </span>
                </div>
                {data.telegram && (
                  <div className="flex items-center gap-1.5">
                    <Icon name="Send" className="w-4 h-34 text-secondary-foreground" />
                    <span className="text-sm flex-1 text-primary">{data.telegram}</span>
                  </div>
                )}
              </CardContent>
            </Card>
            <div className="flex items-center justify-between">
              <span className="font-medium">Actions</span>
            </div>
            <Card>
              <button
                type="button"
                className="w-full flex items-center focus:outline-none py-3 px-3 text-sm hover:bg-popover"
                onClick={() => setShowEditUserModal(true)}
              >
                <Icon name="User" className="w-4 h-4 mr-2" />
                Edit settings
              </button>
              <button
                type="button"
                onClick={() => setShowAddBalanceModal(true)}
                className="w-full flex items-center focus:outline-none py-3 px-3 text-sm border-t hover:bg-popover"
              >
                <Icon name="Wallet" className="w-4 h-4 mr-2" />
                Edit balance
              </button>
              <button
                type="button"
                onClick={() => setShowAddSubscriptionModal(true)}
                className="w-full flex items-center focus:outline-none py-3 px-3 text-sm border-t hover:bg-popover"
              >
                <Icon name="Plus" className="w-4 h-4 mr-2" />
                Add package to account
              </button>
              <button
                type="button"
                disabled={process.env.REACT_APP_TEST_MODE === "false"}
                className="w-full flex items-center focus:outline-nne py-3 px-3 text-sm border-t hover:bg-popover"
              >
                <Icon name="X" className="w-4 h-4 mr-2" />
                Delete user
              </button>
            </Card>
          </div>
          <div className="lg:col-span-2 space-y-3">
            <div className="mt-3 lg:mt-0 lg:flex items-center justify-between">
              <Tabs className="w-full" value={displaySelection} onValueChange={setDisplaySelection}>
                <TabsList>
                  <TabsTrigger value="subscriptions">Active subs</TabsTrigger>
                  <TabsTrigger value="invoices">Invoices</TabsTrigger>
                  {data.canRefer && <TabsTrigger value="campaigns">Campaigns</TabsTrigger>}
                </TabsList>
                <div className="mt-3">
                  <TabsContent value="subscriptions">
                    <AdminUserDetailPageViewSubsTab userId={data._id} />
                  </TabsContent>
                  <TabsContent value="invoices">
                    <AdminUserDetailPageViewInvoicesTab userId={data._id} />
                  </TabsContent>
                  <TabsContent value="campaigns">
                    <AdminUserDetailPageViewCampaignsTab userId={data._id} />
                  </TabsContent>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {showAddBalanceModal && (
        <EditUserBalanceModal
          user={data}
          open={showAddBalanceModal}
          onOpenChange={setShowAddBalanceModal}
          callbackFn={callbackFn}
        />
      )}
      {showAddSubscriptionModal && (
        <AddUserSubscriptionModal
          user={data}
          open={showAddSubscriptionModal}
          onOpenChange={setShowAddSubscriptionModal}
          callbackFn={callbackFn}
        />
      )}
      {showDeleteUserModal && (
        <DeleteUserModal
          user={data}
          open={showDeleteUserModal}
          onOpenChange={setShowDeleteUserModal}
          callbackFn={callbackFn}
        />
      )}
      {showEditUserModal && (
        <EditUserModal
          user={data}
          open={showEditUserModal}
          onOpenChange={setShowEditUserModal}
          callbackFn={callbackFn}
        />
      )}
    </div>
  );
}

export default AdminUserDetailPageView;

import classNames from "classnames";
import React, { useEffect, useState } from "react";
import DataTable from "../../../components/invoices/admin/data-table";
import Pagination from "../../../components/pagination";
import EditPlanModal from "../../../components/plans/modals/edit-plan";
import Icon from "../../../components/ui/icon";
import LoadingState from "../../../components/ui/loading-state";
import { planAliases } from "../../../config";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { formatCurrency } from "../../../utils/Utils";
import { update } from "@intercom/messenger-js-sdk";

const createColumns = () => [
  {
    id: "product",
    header: "Product",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div>
          {planAliases(item.provider)} -{item.bandwidth}
          GB
        </div>
      );
    },
  },
  {
    id: "dailySales",
    header: "Sales/Day",
    cell: ({ row }) => {
      const item = row.original;
      return <div>{item.salesPerDay}</div>;
    },
  },
  {
    id: "dailySales",
    header: "Sales/Month",
    cell: ({ row }) => {
      const item = row.original;
      return <div>{item.salesPerMonth}</div>;
    },
  },
  {
    id: "sales",
    header: "Sales",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div className="flex items-center gap-1.5">
          <Icon name="ShoppingCart" className="w-4 h-4" />
          <div>{item.totalPlans}</div>
        </div>
      );
    },
  },
  {
    id: "revenue",
    header: "Revenue",
    cell: ({ row }) => {
      const item = row.original;
      return <div className="text-slate-500">{formatCurrency.format(item.totalSold)}</div>;
    },
  },
];

function Plans() {
  const [plans, setPlans] = useState([]);
  const [stats, setStats] = useState([]);
  const [isLoadingPlans, setIsLoadingPlans] = useState(true);
  const [isLoadingStats, setIsLoadingStats] = useState(true);
  const [showEditPlanModal, setShowEditPlanModal] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);

  const handlePageChange = (newValue) => {
    setPage(newValue);
  };
  const handleCreatePlan = () => {
    setShowEditPlanModal(true);
  };

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoadingPlans(true);
    api
      .get("/admin/plans")
      .then((res) => {
        setPlans(res.data.payload.plans);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingPlans(false);
      });
  };

  const fetchStats = () => {
    setIsLoadingStats(true);
    api
      .get(`/admin/statistics/plans?page=${page}&size=10`)
      .then((res) => {
        setStats(res.data.payload);
        setTotal(res.data.pager.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingStats(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line no-undef
    update({
      hide_default_launcher: true,
    });
  }, []);

  useEffect(() => {
    fetchStats();
  }, [page]);

  const columns = createColumns();

  return (
    <div className="lg:max-w-7xl mx-auto px-4 py-6 space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold">Plans</h1>
        <button
          type="button"
          onClick={() => handleCreatePlan()}
          className="inline-flex items-center gap-1 px-2 py-1 font-medium border border-slate-700 rounded focus:outline-none text-sm hover:bg-gray-700 transition-colors"
        >
          <Icon name="Plus" className="w-4 h-4 text-muted-foreground" />
          Create new
        </button>
      </div>
      <div>
        {isLoadingPlans ? (
          <LoadingState />
        ) : (
          <div className="space-y-6">
            <div className="grid lg:grid-cols-3 gap-3">
              <div className="rounded-xl ring-1 ring-white/5 p-3 space-y-3">
                <div className="text-lg font-medium">Residential</div>
                <div>
                  {plans
                    .filter((plan) => plan.type === "thunder")
                    .map((plan) => (
                      <div key={plan._id} className="flex items-center justify-between">
                        <div className="flex items-center gap-1">
                          <div>
                            {plan.bandwidth}
                            GB - ${plan.price}
                          </div>
                          <div className="text-muted-foreground">({plan.title})</div>
                        </div>
                        <div
                          className={classNames("w-3 h-3 rounded-full", plan.enabled ? "bg-green-600" : "bg-slate-700")}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="rounded-xl ring-1 ring-white/5 p-3 space-y-3">
                <div className="text-lg font-medium">Datacenter</div>
                <div>
                  {plans
                    .filter((plan) => plan.type === "thunder_datacenter")
                    .map((plan) => (
                      <div key={plan._id} className="flex items-center justify-between">
                        <div className="flex items-center gap-1">
                          <div>
                            {plan.bandwidth}
                            GB - ${plan.price}
                          </div>
                          <div className="text-muted-foreground">({plan.title})</div>
                        </div>
                        <div
                          className={classNames("w-3 h-3 rounded-full", plan.enabled ? "bg-green-600" : "bg-slate-700")}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="rounded-xl ring-1 ring-white/5 p-3 space-y-3">
                <div className="text-lg font-medium">Mobile</div>
                <div>
                  {plans
                    .filter((plan) => plan.type === "thunder_mobile")
                    .map((plan) => (
                      <div key={plan._id} className="flex items-center justify-between">
                        <div className="flex items-center gap-1">
                          <div>
                            {plan.bandwidth}
                            GB - ${plan.price}
                          </div>
                          <div className="text-muted-foreground">({plan.title})</div>
                        </div>
                        <div
                          className={classNames("w-3 h-3 rounded-full", plan.enabled ? "bg-green-600" : "bg-slate-700")}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {isLoadingStats ? <LoadingState /> : <DataTable columns={columns} data={stats} />}
            <Pagination onPageChange={handlePageChange} totalCount={total} currentPage={page} pageSize={10} />
          </div>
        )}
      </div>
      {showEditPlanModal && (
        <EditPlanModal open={showEditPlanModal} onOpenChange={setShowEditPlanModal} callbackFn={fetchData} />
      )}
    </div>
  );
}
export default Plans;

import { ClipboardCopyIcon, HelpCircleIcon, MoreHorizontalIcon } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../ui/dropdown-menu";
import { generateSubscriptionUri } from "../../../lib/subscription";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../ui/tooltip";

export const columns = () => {
  return [
    {
      accessorKey: "user",
      header: "Username",
      cell: ({ row }) => {
        const proxy = row.original;
        return <div className="font-mono">{proxy.user}</div>;
      },
    },
    {
      accessorKey: "password",
      header: "Password",
      cell: ({ row }) => {
        const proxy = row.original;
        return <div className="font-mono">{proxy.password}</div>;
      },
    },
    {
      accessorKey: "host",
      header: "Host",
    },
    {
      accessorKey: "port",
      header: "Port",
    },
    {
      accessorKey: "externalIp",
      header: (
        <Tooltip>
          <TooltipTrigger>
            <div className="leading-3 flex items-center">
              External IP
              <HelpCircleIcon className="w-3 h-3 text-primary ml-1" />
            </div>
          </TooltipTrigger>
          <TooltipContent>The actual IP of the proxy, what the websites will see.</TooltipContent>
        </Tooltip>
      ),
      cell: ({ row }) => {
        const proxy = row.original;

        return <div className="text-muted-foreground font-mono">{proxy.externalIp}</div>;
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const proxy = row.original;

        return (
          <div className="flex justify-end">
            <DropdownMenu modal>
              <DropdownMenuTrigger asChild>
                <button
                  type="button"
                  className="px-1.5 py-0.5 rounded focus:outline-none focus:bg-muted hover:bg-muted"
                >
                  <MoreHorizontalIcon className="w-3 h-3" />
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() =>
                    navigator.clipboard.writeText(
                      generateSubscriptionUri(proxy.user, proxy.password, proxy.host, proxy.port),
                    )
                  }
                  className="leading-none"
                >
                  <ClipboardCopyIcon className="w-3 h-3 mr-2" />
                  Copy connection string
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2, Loader2Icon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import Input from "../../components/ui/input";
import LoadingState from "../../components/ui/loading-state";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import Separator from "../../components/ui/separator";
import { toast } from "../../components/ui/use-toast";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { MailInSpamAlert, UserNotVerifiedAlert } from "./homepage";

export const VerifyEmailModal = () => {
  const [open, onOpenChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { auth } = useAuth();

  const api = useAxiosPrivate();

  const onSubmit = () => {
    setIsLoading(true);
    api
      .get("/public/profile/verify-email")
      .then(() => {
        toast({ title: "E-mail sent successfully", variant: "positive" });
        onOpenChange(!open);
      })
      .catch((err) => {
        console.error(err);
        toast({ title: "Could not send verification e-mail", variant: "destructive" });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button variant="link" className="outline-none">
          Verify now
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Verify your e-mail</DialogTitle>
        </DialogHeader>
        <MailInSpamAlert />
        <p className="text-sm text-muted-foreground">
          An e-mail will be sent to {auth.user.email}, containing instructions on how to verify your e-mail address.
        </p>
        <DialogFooter>
          <Button disabled={isLoading} onClick={() => onOpenChange(!open)} variant="secondary">
            Cancel
          </Button>
          <Button disabled={isLoading} onClick={() => onSubmit()}>
            {isLoading && <Loader2Icon className="w-4 h-4 animate-spin mr-2" />}
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

function ProfileForm({ data }) {
  const [isSaving, setIsSaving] = useState(false);

  const { auth } = useAuth();
  console.log(auth);
  const api = useAxiosPrivate();

  const profileFormSchema = z
    .object({
      email: z
        .string({
          required_error: "Please enter an email address.",
        })
        .refine((value) => /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/.test(value), {
          message: "Please enter a valid email address.",
        }),
      consentToEmails: z.string(),
      newPassword: z
        .string()
        .regex(/^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\-]).{8,}$/, {
          message: "New password must contain at least one uppercase letter and one special character.",
        })
        .optional(),
      password: z
        .string()
        .min(8, {
          message: "Password must be at least 8 characters long.",
        })
        .optional(),
      confirmPassword: z
        .string()
        .min(8, {
          message: "Confirm password must be at least 8 characters long.",
        })
        .optional(),
    })
    .refine(
      (data) => {
        if (data.newPassword) {
          return data.password && data.confirmPassword;
        }
        return true;
      },
      {
        message: "Current password has to be provided when setting a new password.",
        path: ["password"],
      },
    )
    .refine(
      (data) => {
        if (data.newPassword) {
          return data.newPassword === data.confirmPassword;
        }
        return true;
      },
      {
        message: "The confirm password and new password must match.",
        path: ["confirmPassword"],
      },
    )
    .refine(
      (data) => {
        if (data.newPassword) {
          return data.newPassword !== data.password;
        }
        return true;
      },
      {
        message: "The old and new password can't be the same. (Seriously?)",
        path: ["newPassword"],
      },
    );

  const defaultValues = {
    email: data.email,
    consentToEmails: data.consentToEmails.toString(),
  };

  const form = useForm({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });

  function onSubmit(formData) {
    setIsSaving(true);
    api
      .patch("/public/profile", formData)
      .then(() => {
        toast({
          title: "Profile settings saved successfully.",
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Error while saving profile.",
          description: err.response?.status === 400 ? err.response.data.payload.error : "Something went wrong.",
          variant: "destructive",
        });
      })
      .finally(() => setIsSaving(false));
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center gap-2">
                <FormLabel>E-mail</FormLabel>
                {!auth.user.verified && <VerifyEmailModal />}
              </div>
              <FormControl>
                <Input placeholder="username@example.com" {...field} />
              </FormControl>
              <FormDescription>Use a permanent address where you can receive mail.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="consentToEmails"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Mailing preferences</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="true">Consent to all e-mails</SelectItem>
                  <SelectItem value="false">Only security and purchases e-mails</SelectItem>
                </SelectContent>
              </Select>
              <FormDescription>
                By consenting to all e-mails, you will be eligibile for discounts and special offers.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="lg:grid lg:grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Current password</FormLabel>
                <FormControl>
                  <Input type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="newPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>New password</FormLabel>
                <FormControl>
                  <Input type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Confirm password</FormLabel>
                <FormControl>
                  <Input type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button type="submit" disabled={!form.formState.isDirty}>
          {isSaving && <Loader2 className="w-3 h-3 text-muted-foreground mr-1 animate-spin animate-in:fade-in" />}
          Update profile
        </Button>
      </form>
    </Form>
  );
}

function UserMyProfilePageView({ data }) {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Profile</h3>
        <p className="text-sm text-muted-foreground">Edit your preferences here.</p>
      </div>
      <Separator />
      <ProfileForm data={data} />
    </div>
  );
}

function UserMyProfilePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  const { auth } = useAuth();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get("/public/profile")
      .then((res) => {
        setData(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error while loading profile data.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="space-y-3">
      {!auth.user.verified && <UserNotVerifiedAlert />}
      {isLoading ? <LoadingState /> : data ? <UserMyProfilePageView data={data} /> : <div>Error</div>}
    </div>
  );
}

export default UserMyProfilePage;

import { MinusIcon, PlusIcon } from "lucide-react";
import { useContext, useState } from "react";
import { RadioGroup, RadioGroupItem } from "../ui/radiogroup";
import Label from "../ui/label";
import CopyOnClickInput from "../ui/copy-on-click-input";
import { SubscriptionContext } from "../ui/subscription-provider";
import useAuth from "../../hooks/useAuth";

export function NumberStepper({ min, max, value, onValueChange }) {
  if (!min) {
    min = 0;
  }

  const handleInputValue = (e) => {
    const { value } = e.target;

    if (value) {
      if (!Number.isNaN(value)) {
        if (value <= max && value >= min) onValueChange(Number(value));
      }
    }
  };

  const handleIncrement = () => {
    if (value + 1 <= max) {
      onValueChange(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value - 1 >= min) {
      onValueChange(value - 1);
    }
  };

  return (
    <div className="flex items-stretch">
      <button
        disabled={value - 1 < min}
        onClick={() => handleDecrement()}
        type="button"
        className="border border-input bg-background hover:bg-accent hover:text-accent-foreground px-1 border-r-0"
      >
        <MinusIcon className="w-3 h-3" />
      </button>
      <input
        className="text-sm text-center w-16 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
        value={value}
        onChange={handleInputValue}
      />
      <button
        disabled={value + 1 > max}
        onClick={() => handleIncrement()}
        type="button"
        className="border border-input bg-background hover:bg-accent hover:text-accent-foreground px-1 border-l-0"
      >
        <PlusIcon className="w-3 h-3" />
      </button>
    </div>
  );
}

export function RotationConfiguratorView({ value, onValueChange, forList, ipAuth }) {
  const { subscription } = useContext(SubscriptionContext);
  const { subInfo } = subscription;
  const { auth } = useAuth();
  const { user } = auth;

  const [sessions, setSessions] = useState(value.sessions ?? 1);
  const [rotation, setRotation] = useState(value.rotation);

  const handleUpdateRotation = (value) => {
    setRotation(value);
    const update = {
      rotation: value,
    };

    if (value === "rotating") {
      update.sessions = 1;
    }

    onValueChange(update);
  };

  const handleUpdateSessions = (value) => {
    setSessions(value);
    const update = {
      sessions: value,
    };
    onValueChange(update);
  };

  const baseChangeIpUrl = `https://api.thunderproxy.com/v1/public/subscriptions/${subInfo._id}/change-ip?apiKey=${user.subscriptionApiKey}`;

  return (
    <div className="space-y-3">
      <div className="space-y-2">
        <div className="text-lg font-semibold">Rotation</div>
        <span className="text-sm text-muted-foreground">
          IP rotation is a technique used to change the IP address from which your internet requests are made.
        </span>
        <RadioGroup value={rotation} onValueChange={handleUpdateRotation} className="grid-cols-2">
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="rotating" id="r1" />
            <Label htmlFor="r1">Rotating</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="sticky" id="r1" />
            <Label htmlFor="r1">Sticky</Label>
          </div>
        </RadioGroup>
      </div>
      {rotation === "sticky" && forList && (
        <div className="space-y-2">
          <div className="text-lg font-semibold">Sticky sessions</div>
          <span className="text-sm text-muted-foreground">
            Sticky sessions make sure that even if your computer uses a rotating proxy, it sticks with one proxy for a
            while. This way, the website sees you coming from the same place for a bit longer, making it easier to keep
            track of your actions.
          </span>
          <div className="flex items-center justify-between">
            <Label>Amount of sticky sessions</Label>
            <NumberStepper min={1} max={40000} value={sessions} onValueChange={handleUpdateSessions} />
          </div>
        </div>
      )}
      {rotation === "rotating" && (
        <div className="space-y-2">
          <div className="text-lg font-semibold">Rotating mode</div>
          <span className="text-sm text-muted-foreground">
            In rotating mode, each time you make a request through the proxy, the proxy service assigns a different IP
            address for the connection.
          </span>
        </div>
      )}
      {rotation === "sticky" && ipAuth && (
        <div className="space-y-2">
          <div className="text-lg font-semibold">Sticky sessions</div>
          <span className="text-sm text-muted-foreground">
            We keep the same IP for as long as it&apos;s online, making it easier for websites to track your actions
            consistently.
          </span>
          <div className="space-y-1">
            <Label>Change IP url</Label>
            <CopyOnClickInput value={baseChangeIpUrl} />
          </div>
        </div>
      )}
    </div>
  );
}

import { RadioGroup, RadioGroupItem } from "@radix-ui/react-radio-group";
import { Slider, SliderRange, SliderThumb, SliderTrack } from "@radix-ui/react-slider";
import classNames from "classnames";
import { Loader2Icon, LockIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { generateCheckoutReference } from "../../lib/utils";
import { formatCurrency } from "../../utils/Utils";
import { pushViewItemEvent } from "../GTM";
import { Badge } from "../ui/badge";
import { Button, buttonVariants } from "../ui/button";
import { Card, CardContent, CardHeader } from "../ui/card";
import CheckBox from "../ui/checkbox";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../ui/collapsible";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "../ui/dialog";
import Icon from "../ui/icon";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { toast } from "../ui/use-toast";
import { calculateUnitPrice } from "../../lib/store";

const secondsToLabel = (durationInSeconds) => {
  if (typeof durationInSeconds !== "number") {
    durationInSeconds = parseInt(durationInSeconds);
  }

  let result = "7 days";
  switch (durationInSeconds) {
    case 604800:
      result = "7 days";
      break;
    case 1209600:
      result = "14 days";
      break;
    case 2592000:
      result = "30 days";
      break;
    case 5184000:
      result = "60 days";
      break;
    default:
      break;
  }

  return result;
};

export function StaticPurchaseModal({ purchaseData, open, onOpenChange }) {
  const {
    auth: { user },
  } = useAuth();
  const [paymentMethod, setPaymentMethod] = useState("cryptocurrency");
  const [hasAcceptedTos, setHasAcceptedTos] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentMethodChange = (newValue) => {
    setPaymentMethod(newValue);
  };

  const api = useAxiosPrivate();

  const orderTotal = purchaseData.price;

  const handleConfirmCheckout = () => {
    const checkoutReference = generateCheckoutReference();

    setIsLoading(true);
    api
      .post(
        "/public/store/static/checkout",
        {
          paymentMethod,
          duration: purchaseData.duration,
          country: purchaseData.country.id,
          quantity: purchaseData.quantity,
          checkoutReference,
        },
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        window.location.href = res.data.redirectUrl;
        onOpenChange(false);
      })
      .catch((err) => {
        if (err.response?.data) {
          toast({
            title: "An error occurred",
            description: err.response.data.error,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  console.log(purchaseData);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <Trans i18nKey="dashboard.user.modals.purchase.title" />
            </DialogTitle>
            <DialogDescription>
              <Trans i18nKey="dashboard.user.modals.purchase.caption" />
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-2 border rounded p-2 text-sm">
            <div className="flex justify-between">
              <div className="text-muted-foreground">Type of proxy</div>
              <div className="font-medium">ISP (Static) - IPv4</div>
            </div>
            <div className="flex justify-between">
              <div className="text-muted-foreground">Country of addresses</div>
              <div className="font-medium">{purchaseData.country.name} </div>
            </div>
            <div className="flex justify-between">
              <div className="text-muted-foreground">Duration</div>
              <div className="font-medium">{secondsToLabel(purchaseData.duration)} </div>
            </div>
            <div className="flex justify-between">
              <div className="text-muted-foreground">Amount</div>
              <div className="font-medium">{purchaseData.quantity} IPs</div>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <div className="text-muted-foreground">
                <Trans i18nKey="dashboard.user.modals.purchase.subtotal" />
              </div>
              <div>{formatCurrency.format((orderTotal / 1.21).toFixed(2))}</div>
            </div>
            <div className="flex justify-between items-center">
              <div className="text-muted-foreground">
                <Trans i18nKey="dashboard.user.modals.purchase.vat" />
              </div>
              <div>{formatCurrency.format((orderTotal * (1 - 1 / 1.21)).toFixed(2))}</div>
            </div>

            <div className="flex justify-between items-center border-t pt-1">
              <div className="text-muted-foreground">
                <Trans i18nKey="dashboard.user.modals.purchase.total" />
              </div>
              <div className="font-medium">${orderTotal.toFixed(2)}</div>
            </div>
          </div>
          {/* Todo: COUPONS */}
          {!user?.cardPaymentProcessorEnabled &&
          !user?.cryptoPaymentProcessorEnabled &&
          !user?.balancePaymentProcessorEnabled ? (
            <div className="flex flex-col items-center justify-center py-12">
              <span className="text-center font-medium">It looks like purchasing is currently disabled.</span>
              <p className="text-xs">Apologies for the inconvenience, it will be available again soon!</p>
            </div>
          ) : (
            <>
              <RadioGroup
                className="flex flex-col border rounded text-sm transition"
                value={paymentMethod}
                onValueChange={handlePaymentMethodChange}
              >
                {user?.cryptoPaymentProcessorEnabled && (
                  <RadioGroupItem
                    disabled={!user?.cryptoPaymentProcessorEnabled}
                    value="cryptocurrency"
                    className={classNames(
                      "first:rounded-t last:rounded-b",
                      paymentMethod === "cryptocurrency" && "text-primary ring-1 ring-primary",
                      !user?.cryptoPaymentProcessorEnabled && "text-muted-foreground cursor-not-allowed",
                    )}
                  >
                    <div className="flex flex-col p-2 gap-1">
                      <div className="inline-flex items-center font-medium gap-1">
                        <Trans i18nKey="globals.payment_methods.crypto.title" />
                        <Icon name="Bitcoin" className="w-4 h-4 text-muted-foreground" />
                      </div>
                      <div className="text-left text-xs text-muted-foreground">
                        Bitcoin (BTC), Ethereum (ETH), USD Coin (USDC), Dogecoin (DOGE), Litecoin (LTC), Bitcoin Cash
                        (BCH), Tether (USDT). Polygon: MATIC, USDC
                      </div>
                    </div>
                  </RadioGroupItem>
                )}
                <RadioGroupItem
                  disabled={user?.balance < orderTotal || !user?.balancePaymentProcessorEnabled}
                  value="balance"
                  className={classNames(
                    "first:rounded-t last:rounded-b",
                    paymentMethod === "balance" && "text-primary ring-1 ring-primary",
                    (user?.balance < orderTotal || !user?.balancePaymentProcessorEnabled) &&
                      "text-muted-foreground cursor-not-allowed",
                  )}
                >
                  <div className="flex flex-col p-2 gap-1">
                    <div className="inline-flex items-center font-medium gap-1">
                      <Trans i18nKey="globals.payment_methods.balance.title" />
                      <Icon name="Wallet" className="w-4 h-4" />
                    </div>
                    <div className="text-left text-xs text-muted-foreground">
                      {user?.balance < orderTotal ? (
                        <Trans i18nKey="globals.payment_methods.balance.insufficient_funds" />
                      ) : (
                        <>
                          <Trans i18nKey="globals.payment_methods.balance.after_purchase" />{" "}
                          {formatCurrency.format(user.balance - orderTotal)}
                        </>
                      )}
                    </div>
                  </div>
                </RadioGroupItem>
                {user.cardPaymentProcessorEnabled && (
                  <RadioGroupItem
                    disabled={orderTotal < user?.cardMinimum || orderTotal > user.cardMaximum}
                    value="card"
                    className={classNames(
                      "first:rounded-t last:rounded-b",
                      paymentMethod === "card" && "text-primary ring-1 ring-primary",
                      (orderTotal < user?.cardMinimum || orderTotal > user.cardMaximum) &&
                        "cursor-not-allowed text-muted-foreground",
                    )}
                  >
                    <div className="flex flex-col p-2 gap-1">
                      <div className="inline-flex items-center font-medium gap-1.5">
                        <Trans i18nKey="globals.payment_methods.cards.title" />
                        <Icon name="CreditCard" className="w-4 h-4" />
                      </div>
                      <div className="text-left text-xs text-muted-foreground">
                        <Trans i18nKey="dashboard.user.modals.purchase.card.description" />
                      </div>
                      <div className="text-left text-xs font-medium">
                        <Trans i18nKey="dashboard.user.modals.purchase.card.disclaimer.part1" />${user?.cardMinimum}
                        <Trans i18nKey="dashboard.user.modals.purchase.card.disclaimer.part2" />${user?.cardMaximum}
                      </div>
                    </div>
                  </RadioGroupItem>
                )}
              </RadioGroup>
              <div className="inline-flex items-center gap-2 text-sm">
                <CheckBox value={hasAcceptedTos} onValueChange={() => setHasAcceptedTos(!hasAcceptedTos)} />
                <span>
                  <Trans i18nKey="dashboard.user.modals.purchase.acceptance" />
                  <Link
                    className="underline hover:text-primary transition-colors"
                    to="/tos"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Trans i18nKey="dashboard.user.modals.purchase.tos" />
                  </Link>
                  .
                </span>
              </div>
            </>
          )}
          <DialogFooter>
            <Button disabled={isLoading} onClick={() => onOpenChange(false)} variant="outline">
              <Trans i18nKey="globals.buttons.actions.cancel" />
            </Button>
            <Button
              onClick={handleConfirmCheckout}
              disabled={
                !hasAcceptedTos ||
                (paymentMethod === "balance" && user?.balance?.toFixed(2) < orderTotal) ||
                !paymentMethod ||
                isLoading
              }
              className={buttonVariants()}
            >
              {isLoading && <Loader2Icon className="w-3 h-3 animate-spin mr-2" />}
              <Trans i18nKey="globals.buttons.actions.confirm" />
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}

function StaticStoreView({ data }) {
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [quantity, setQuantity] = useState([10]);
  const [duration, setDuration] = useState("604800");
  const [country, setCountry] = useState();

  const getDurationMultiplier = (durationInSeconds) => {
    const duration = parseInt(durationInSeconds, 10);
    if (Number.isNaN(duration)) {
      return 0;
    }
    const durationItem = data.durationPricing.find((item) => item.durationInSeconds === duration);
    return durationItem?.multiplier || 0;
  };

  const getBasePrice = () => {
    const countryItem = data.countries.find((item) => item.id === country);

    return countryItem?.unitPrice || 0;
  };

  const handleDecreaseQuantity = () => {
    if (quantity[0] > data.minQuantity) {
      setQuantity([quantity[0] - 1]);
    }
  };

  const handleIncreaseQuantity = () => {
    if (quantity[0] < data.maxQuantity) {
      setQuantity([quantity[0] + 1]);
    }
  };

  const getQuantityDiscountPercentage = (quantity) => {
    const bracket = data.quantityDiscount.find((item) => quantity >= item.min && quantity <= item.max);
    return bracket?.discount || 0;
  };

  const calculatePrice =
    getBasePrice() *
    quantity[0] *
    getDurationMultiplier(duration) *
    (1 - getQuantityDiscountPercentage(quantity[0]) / 100);

  const selectedCountry = country !== undefined ? data.countries.find((item) => item.id === country) : undefined;
  const unitPrice = calculateUnitPrice(calculatePrice, quantity[0]);

  useEffect(() => {
    if (country) {
      const product = {
        id: `static-isp`,
        name: `Static ISP IPv4 Proxies`,
        category: `static`,
        variant: "isp",
        price: unitPrice,
        currency: "USD",
        quantity: quantity[0],
      };
      pushViewItemEvent(product, calculatePrice);
    }
  }, [country]);

  return (
    <div className="grid lg:grid-cols-2 gap-6">
      <div className="space-y-6">
        <Card>
          <CardHeader>
            <div className="flex flex-col gap-3 items-center">
              <div className="p-3 rounded">
                <Icon name="Router" className="w-8 h-8" />
              </div>
              <div className="text-2xl font-medium">ISP Static Proxies (IPv4)</div>
              <div className="text-muted-foreground text-center">
                Advantageous solution for running traffic-intensive tasks
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="space-y-3">
              <div className="text-xl font-medium">
                <Trans i18nKey="dashboard.user.store.labels.features" />
              </div>
              <div className="grid lg:grid-cols-3 gap-3">
                <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
                  <Icon name="Network" className="w-5 h-5" />
                  Keep the same IP
                </div>
                <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
                  <Icon name="Globe2" className="w-5 h-5" />
                  Top tier countries
                </div>
                <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
                  <Icon name="Infinity" className="w-5 h-5" />
                  No bandwidth limit
                </div>
                <div className="flex items-center gap-1.5 text-sm text-muted-foreground font-medium">
                  <Icon name="Plug" className="w-5 h-5" />
                  NO SOCKS4/5, only HTTP
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Collapsible defaultOpen>
          <CollapsibleTrigger>
            <div>
              <Trans i18nKey="dashboard.user.store.labels.blacklist" />
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="p-3 text-sm">
            <p>
              <Trans i18nKey="dashboard.user.store.blacklist.disclaimer" />
            </p>
            <ul className="list-disc list-inside">
              <li>
                <Trans i18nKey="dashboard.user.store.blacklist.list.item1" />
              </li>
              <li>
                <Trans i18nKey="dashboard.user.store.blacklist.list.item2" />
              </li>
              <li>
                <Trans i18nKey="dashboard.user.store.blacklist.list.item3" />
              </li>
              <li>
                <Trans i18nKey="dashboard.user.store.blacklist.list.item4" />
              </li>
            </ul>
            <p>
              <Trans i18nKey="dashboard.user.store.blacklist.contact.part1" />
              <a href="#" className="text-primary hover:text-primary/95 transition-colors" id="intercomContact">
                <Trans i18nKey="dashboard.user.store.blacklist.contact.chatAction" />
              </a>
              <Trans i18nKey="dashboard.user.store.blacklist.contact.part2" />
              <a href="mailto:help@thunderproxy.com" className="text-primary hover:text-primary/95 transition-colors">
                help@thunderproxy.com
              </a>
              .
            </p>
          </CollapsibleContent>
        </Collapsible>
      </div>
      <div className="space-y-6">
        <Card>
          <CardHeader className="flex flex-col gap-3 justify-between items-center">
            <div className="text-2xl font-medium">
              <Trans i18nKey="dashboard.user.store.labels.purchaseHeading" />
              ISP
            </div>
            <div className="text-xs text-muted-foreground">
              <Trans i18nKey="dashboard.user.store.labels.purchaseCaption" />
            </div>
            {quantity[0] === data.maxQuantity && (
              <div className="text-sm h-3">
                Need more?
                <a id="intercomContact" className={buttonVariants({ variant: "link" })}>
                  Contact us
                </a>
              </div>
            )}
          </CardHeader>
          <CardContent>
            <div className="w-full gap-3 py-3">
              <div className="flex items-center justify-between px-12">
                <button
                  type="button"
                  onClick={() => handleDecreaseQuantity()}
                  disabled={quantity < data.minQuantity - 1}
                  className="rounded-full p-1 bg-muted hover:bg-muted/90 transition-colors"
                >
                  <Icon name="Minus" className="w-6 h-6" />
                </button>
                <div className="flex items-center gap-1 text-2xl">
                  <div className="font-medium text-primary">{quantity}</div>
                  <div className="text-muted-foreground">proxies</div>
                </div>
                <button
                  type="button"
                  onClick={() => handleIncreaseQuantity()}
                  disabled={quantity > data.maxQuantity - 1}
                  className="rounded-full p-1 bg-muted hover:bg-muted/90 transition-colors"
                >
                  <Icon name="Plus" className="w-6 h-6" />
                </button>
              </div>
              <div className="py-6">
                <Slider
                  className="w-full relative flex items-center select-none touch-none"
                  value={quantity}
                  min={data.minQuantity}
                  max={data.maxQuantity}
                  step={1}
                  onValueChange={setQuantity}
                >
                  <SliderTrack className="grow relative rounded-full h-1 bg-secondary">
                    <SliderRange className="absolute bg-primary rounded-full h-full" />
                  </SliderTrack>
                  <SliderThumb className="block w-5 h-5 bg-primary rounded-full" />
                </Slider>
              </div>
              <div className="flex items-start gap-3 p-3 bg-popover">
                <Icon name="Info" className="w-4 h-4 text-muted-foreground" />
                <div className="text-xs text-muted-foreground">
                  You will be always able to prolong and keep the same IPs once the plan duration ends.
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex justify-between items-center">
                  <div className="font-medium">Country</div>
                  <div>
                    <Select value={country} onValueChange={setCountry}>
                      <SelectTrigger className={!country && "border border-red-600"}>
                        <SelectValue placeholder="Select a country" />
                      </SelectTrigger>
                      <SelectContent>
                        {data.countries.map((item) => (
                          <SelectItem key={item.id} value={`${item.id}`}>
                            {item.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="font-medium">
                    <Trans i18nKey="dashboard.user.store.labels.duration" />
                  </div>
                  <div>
                    <Select value={duration} onValueChange={setDuration}>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a duration" />
                      </SelectTrigger>
                      <SelectContent>
                        {data.durationPricing.map((item) => (
                          <SelectItem key={item.id} value={`${item.durationInSeconds}`}>
                            {secondsToLabel(item.durationInSeconds)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="py-6">
                <div className="flex justify-between items-center">
                  <div className="font-medium">Price per address</div>
                  <div className="flex items-center gap-1.5">
                    {!!getQuantityDiscountPercentage(quantity[0]) && (
                      <Badge variant="outline">{getQuantityDiscountPercentage(quantity[0])}% OFF</Badge>
                    )}
                    <div className="font-medium text-green-500">{(calculatePrice / quantity).toFixed(2)}$</div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="font-medium">
                    <Trans i18nKey="dashboard.user.store.labels.packageSize" />
                  </div>
                  <div>{quantity} IPs</div>
                </div>
              </div>
            </div>
            <button
              type="button"
              onClick={() => setShowPurchaseModal(true)}
              className={classNames("w-full", buttonVariants())}
              disabled={!country}
            >
              {country || calculatePrice === 0 ? (
                <>
                  <Trans i18nKey="dashboard.user.store.labels.action" /> - ${calculatePrice.toFixed(2)}
                </>
              ) : (
                <div className="flex items-center gap-1">
                  <LockIcon className="w-3 h-3" />
                  <span className="leading-3">Select a country to purchase</span>
                </div>
              )}
            </button>
          </CardContent>
        </Card>
        {showPurchaseModal && (
          <StaticPurchaseModal
            purchaseData={{
              quantity: quantity[0],
              country: selectedCountry,
              duration,
              price: calculatePrice,
            }}
            open={showPurchaseModal}
            onOpenChange={setShowPurchaseModal}
          />
        )}
        {/* <CampaignBanner /> */}
      </div>
    </div>
  );
}

export default StaticStoreView;

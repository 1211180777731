import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "../../ui/alert-dialog";
import { buttonVariants } from "../../ui/button";
import CheckBox from "../../ui/checkbox";
import Icon from "../../ui/icon";
import Input from "../../ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import { toast } from "../../ui/use-toast";
import Label from "../../ui/label";

function EditCouponModal({ data, open, onOpenChange, callbackFn }) {
  const [isLoading, setIsLoading] = useState(false);

  const api = useAxiosPrivate();

  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      code: data?.code || "",
      discount: data?.discount || "",
      active: data ? data.active : false,
      paymentProcessor: data?.paymentProcessor || "",
    },
  });

  const onSubmit = (formData) => {
    setIsLoading(true);

    if (data) {
      api
        .patch(`/admin/coupons/${data._id}`, formData)
        .then(() => {
          toast({ description: "Coupon saved." });
        })
        .catch((err) => {
          console.log(err);
          toast({ description: "Something went wrong", variant: "destructive" });
        })
        .finally(() => {
          setIsLoading(false);
          callbackFn();
          onOpenChange(false);
        });
    } else {
      api
        .post("/admin/coupons", formData)
        .then(() => {
          toast({ description: "Coupon saved." });
        })
        .catch((err) => {
          console.log(err);
          toast({ description: "Something went wrong", variant: "destructive" });
        })
        .finally(() => {
          setIsLoading(false);
          callbackFn();
          onOpenChange(false);
        });
    }
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex items-center justify-between">
              <div>
                {data ? (
                  <>
                    Modify
                    <span className="text-primary">{data.code}</span>
                  </>
                ) : (
                  <>Create new coupon</>
                )}
              </div>
            </AlertDialogTitle>
          </AlertDialogHeader>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-4 mb-4 sm:grid-cols-2">
              <div>
                <Label>Code</Label>
                <Input {...register("code", { required: true })} />
              </div>
              <div>
                <Label>Payment processor</Label>
                <Controller
                  control={control}
                  name="category"
                  render={({ field }) => (
                    <Select onValueChange={field.onChange} {...field}>
                      <SelectTrigger className="mt-2">
                        <SelectValue placeholder="Any" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={null}>Any</SelectItem>
                        <SelectItem value="cryptocurrency">Crypto</SelectItem>
                        <SelectItem value="card">Card</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                />
              </div>
              <div>
                <Label>Discount</Label>
                <Input {...register("discount", { required: true })} />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1 text-sm">
                <Controller
                  control={control}
                  name="active"
                  render={({ field }) => <CheckBox onValueChange={field.onChange} {...field} />}
                />
                Active
              </div>
              <div className="flex items-center justify-end gap-3">
                <button
                  type="button"
                  className={buttonVariants({ variant: "link" })}
                  onClick={(event) => {
                    onOpenChange(false);
                    event.preventDefault();
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className={buttonVariants()}>
                  {isLoading && <Icon name="Loader" className="mr-2 h-4 w-4 animate-spin" />}
                  Submit
                </button>
              </div>
            </div>
          </form>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}

export default EditCouponModal;

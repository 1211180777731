import React from "react";
import useAuth from "../../../hooks/useAuth";
import { cn } from "../../../lib/utils";
import { buttonVariants } from "../../ui/button";

const Banner = () => {
  const { auth } = useAuth();
  const { banner } = auth;

  return banner ? (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-muted/50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6">
          <strong className="font-semibold">{banner.title}</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx="1" cy="1" r="1" />
          </svg>
          {banner.content}
        </p>
        {banner.link ? (
          <a href={banner.link} className={cn(buttonVariants({ variant: "link" }), "px-0")}>
            Learn more <span aria-hidden="true">&rarr;</span>
          </a>
        ) : null}
      </div>
      <div className="flex flex-1 justify-end"></div>
    </div>
  ) : null;
};

export default Banner;

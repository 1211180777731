import React from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { createToken } from "../../utils/Utils";
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle } from "../ui/alert-dialog";
import { buttonVariants } from "../ui/button";
import { CopyButton } from "../ui/copy-button";
import { toast } from "../ui/use-toast";

function CreateUserReferralCampaign({ open, onOpenChange, callbackFn }) {
  const defaultCode = createToken(8);

  const { watch, register, handleSubmit } = useForm({
    defaultValues: {
      code: defaultCode,
      name: "",
    },
  });

  const campaignCode = watch("code");

  const api = useAxiosPrivate();

  const onSubmit = (formData) => {
    api
      .post("/public/affiliate/campaigns", formData)
      .then(() => {
        toast({
          title: "Campaign created successfully.",
          variant: "positive",
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Could not create campaign.",
          variant: "negative",
        });
      })
      .finally(() => {
        onOpenChange(false);
        callbackFn();
      });
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center justify-between">
            Create new referral campaign
          </AlertDialogTitle>
        </AlertDialogHeader>
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <p className="block mb-2 text-sm font-medium text-white">Campaign Name</p>
            <input
              type="text"
              className="text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 text-white focus:ring focus:ring-slate-500 focus:border-slate-500 focus:outline-none"
              {...register("name", { min: 3, max: 25, pattern: /[A-Za-z]/ })}
            />
          </div>
          <div>
            <p className="block mb-2 text-sm font-medium text-white">Campaign code</p>
            <input
              type="text"
              className="text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 text-white focus:ring focus:ring-slate-500 focus:border-slate-500 focus:outline-none"
              {...register("code", { min: 3, max: 25, pattern: /[A-Za-z]/ })}
            />
          </div>
          <div>
            <p className="block mb-2 text-sm font-medium text-white">Referral Link</p>
            <div className="relative w-full">
              <input
                readOnly
                value={`https://thunderproxy.com/?c=${campaignCode}`}
                type="text"
                className="text-gray-200 text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 focus:ring focus:ring-slate-500 focus:border-slate-500 focus:outline-none"
              />
              <div className="absolute inset-y-0 end-0 grid place-items-center pr-2">
                <CopyButton value={`https://thunderproxy.com/?c=${campaignCode}`} />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              className={buttonVariants()}
              onClick={(event) => {
                onOpenChange(false);
                event.preventDefault();
              }}
            >
              Cancel
            </button>
            <button type="submit" className={buttonVariants({ variant: "confirm" })}>
              Submit
            </button>
          </div>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default CreateUserReferralCampaign;

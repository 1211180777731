import { format } from "date-fns";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ArrowRightIcon, Plus, RefreshCcw, User, Wrench } from "lucide-react";
import { bytesToGB } from "../../../lib/utils";
import CircularProgress from "../../ui/circular-progress";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../ui/dropdown-menu";
import Icon from "../../ui/icon";
import EditSubscriptionModal from "./modals/edit-subscription";
import DataTable from "../../ui/data-table";
import AdminExtendSubscriptionModal from "../../users/admin/modals/extend-subscription-modal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "../../ui/use-toast";

export function AdminSubsOperations({ data, callbackFn, referrer }) {
  const navigate = useNavigate();
  const [showEditSub, setShowEditSub] = useState(false);
  const [showExtendSub, setShowExtendSub] = useState(false);
  const [open, setOpen] = useState(false);

  // Fix for iOS mobile not opening
  // eslint-disable-next-line no-undef
  const touchDevice = "ontouchstart" in window;

  const api = useAxiosPrivate();

  const handleResyncIpSub = () => {
    api
      .get(`/admin/subscriptions/${data._id}/resync`)
      .then(() => {
        toast({ title: "Resynced successfully", variant: "positive" });
      })
      .catch((err) => {
        toast({ title: "Could not resync", variant: "destructive" });
        console.error(err);
      });
  };

  return (
    <>
      <DropdownMenu open={open} modal={false} onOpenChange={setOpen}>
        <DropdownMenuTrigger
          className="flex h-8 w-8 items-center justify-center rounded-md border transition-colors hover:bg-muted"
          {...(touchDevice
            ? {
                onPointerDown: (e) => e.preventDefault(),
                onClick: () => setOpen(!open),
              }
            : undefined)}
        >
          <div>
            <Icon name="MoreVertical" className="w-3 h-3" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {referrer !== "user-detail" && (
            <DropdownMenuItem onClick={() => navigate(`/users/${data.user._id}`)}>
              <User className="w-4 h-4 mr-2" />
              View user
            </DropdownMenuItem>
          )}
          <DropdownMenuItem onClick={() => navigate(`/subscriptions/${data._id}`)}>
            <ArrowRightIcon className="w-4 h-4 mr-2" />
            View generator
          </DropdownMenuItem>
          {data.type === "ip" ? (
            <DropdownMenuItem onClick={() => handleResyncIpSub()}>
              <RefreshCcw className="w-4 h-4 mr-2" />
              Resync IP list
            </DropdownMenuItem>
          ) : null}
          <DropdownMenuItem onSelect={() => setShowEditSub(true)}>
            <Wrench className="w-4 h-4 mr-2" />
            Edit this subscription
          </DropdownMenuItem>
          {data.type === "gb" && (
            <DropdownMenuItem onSelect={() => setShowExtendSub(true)}>
              <Plus className="w-4 h-4 mr-2" />
              Extend
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      {showEditSub && (
        <EditSubscriptionModal open={showEditSub} onOpenChange={setShowEditSub} data={data} callbackFn={callbackFn} />
      )}
      {showExtendSub && (
        <AdminExtendSubscriptionModal
          open={showExtendSub}
          onOpenChange={setShowExtendSub}
          data={data}
          callbackFn={callbackFn}
        />
      )}
    </>
  );
}

function SubscriptionItem({ item, callbackFn }) {
  return (
    <div className="flex flex-col items-center mx-2 py-1">
      <div className="flex w-full flex-row items-center">
        <div className={`my-[12px] h-3 w-3 shrink-0 rounded-full bg-${item.deleted ? "gray" : "green"}-700`} />
        <div className="shrink-0">
          <span className="font-mono text-xs ml-2">{item._id}</span>
        </div>
        <span className="shrink truncate min-w-[0px] text-xs ml-2 uppercase text-muted-foreground">
          {item.type === "gb" && (
            <>
              {item.planType} {bytesToGB(item.bandwidthLeft)}/{item.plan.bandwidth}
              GB
            </>
          )}
        </span>
        <div className="flex-1 shrink" />
        <div className="flex items-center gap-2 shrink-0">
          <span className="ml-2 text-muted-foreground text-xs">
            {item.renewalDate ? format(new Date(item.renewalDate), "dd MMM") : "N/A"}
          </span>
          <AdminSubsOperations data={item} callbackFn={callbackFn} />
        </div>
      </div>
    </div>
  );
}

const createAdminSubsDesktopCols = (callbackFn) => [
  {
    accessorKey: "_id",
    header: "# ID",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div className="flex items-center">
          <div className={`mr-[12px] h-3 w-3 shrink-0 rounded-full bg-${item.deleted ? "gray" : "green"}-700`} />
          <span className="font-mono text-sm">{item._id}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "user._id",
    header: "Owner",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <Link to={`/users/${item.user._id}`} class="text-primary text-sm">
          {item.user.username}
        </Link>
      );
    },
  },
  {
    accessorKey: "authUser",
    header: "Auth User",
    cell: ({ row }) => {
      const item = row.original;
      return <span className="font-mono text-sm text-muted-foreground">{item.authUser}</span>;
    },
  },
  {
    accessorKey: "planType",
    header: "Type",
    cell: ({ row }) => {
      const item = row.original;
      return <div className="text-sm uppercase">{item.planType}</div>;
    },
  },
  {
    id: "usage",
    header: "Usage",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div className="inline-flex items-center gap-2">
          {item.type === "gb" && (
            <>
              <CircularProgress progress={(item.bandwidthLeft / item.plan.bandwidthBytes) * 100} />
              <span className="text-sm">
                {bytesToGB(item.bandwidthLeft)}/{item.plan.bandwidth}
                GB
              </span>
            </>
          )}
          {item.type === "ip" && (
            <>
              <span className="text-sm">N/A</span>
            </>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "renewalDate",
    header: "Expiry",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <span className="text-muted-foreground text-xs">
          {item.renewalDate ? format(new Date(item.renewalDate), "dd MMM") : "N/A"}
        </span>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div className="flex w-full justify-end">
          <AdminSubsOperations data={item} callbackFn={callbackFn} />
        </div>
      );
    },
  },
];

const createAdminSubsMobileCols = (callbackFn) => [
  {
    accessorKey: "main",
    header: () => "Results",
    cell: ({ row }) => {
      const item = row.original;
      return <SubscriptionItem item={item} callbackFn={callbackFn} />;
    },
  },
];

function AdminSubscriptionsView({ data, callbackFn }) {
  const adminSubsDesktopCols = createAdminSubsDesktopCols(callbackFn);
  const adminSubsMobileCols = createAdminSubsMobileCols(callbackFn);

  return (
    <section className="animate-in fade-in">
      <div className="hidden lg:block">
        <DataTable columns={adminSubsDesktopCols} data={data} />
      </div>
      <div className="lg:hidden">
        <DataTable columns={adminSubsMobileCols} data={data} />
      </div>
    </section>
  );
}

export { AdminSubscriptionsView, createAdminSubsDesktopCols, createAdminSubsMobileCols };

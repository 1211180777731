import React from "react";

function DashboardHeader({ heading, text, children }) {
  return (
    <div className="flex items-center justify-between">
      <div className="grid gap-1">
        <h1 className="font-semibold text-lg md:text-2xl">{heading}</h1>
        {text && <p className="text-sm md:text-base text-muted-foreground">{text}</p>}
      </div>
      {children}
    </div>
  );
}

export default DashboardHeader;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "./icon";

function Breadcrumb() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = () => {
    navigate(-1, { state: { from: location }, replace: true });
  };

  return (
    <button
      type="button"
      className="cursor-pointer text-muted-foreground hover:text-foreground flex items-center transition"
      onClick={handleNavigation}
    >
      <Icon name="ArrowLeft" className="h-5 w-5 flex-shrink-0" />
      <span className="ml-2 text-sm font-medium">Back</span>
    </button>
  );
}

export default Breadcrumb;

import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Trans } from "react-i18next";
import { formatBytes } from "../lib/utils";
import Progress from "./ui/progress";

const DataAvailableCard = ({ remaining, total }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Trans i18nKey="dashboard.user.homepage.data.heading" />
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex items-center justify-between">
          <div className="text-xs">
            {formatBytes(remaining > 0 ? remaining : 0)} left out of {`${formatBytes(total)}`}
          </div>
          <div className="text-xs">{remaining ? ((remaining / total) * 100).toFixed(2) : 0}%</div>
        </div>
        <Progress className="mt-2" value={(remaining / total) * 100} />
      </CardContent>
    </Card>
  );
};

export default DataAvailableCard;

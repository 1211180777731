import { Trans } from "react-i18next";
import Icon from "../../ui/icon";
import SubscriptionCard from "../../ui/subscription-card";

function SubscriptionsList({ filteredSubscriptions, activeSubscriptions, expiredSubscriptions }) {
  return (
    <div className="py-3 transition">
      {activeSubscriptions.length + expiredSubscriptions.length === 0 && (
        <div className="text-center py-32">
          <Icon name="HeartHandshake" className="mx-auto h-12 w-12 text-muted-foreground" />
          <h2 className="mt-2 text-base font-semibold leading-6">
            <Trans i18nKey="dashboard.user.homepage.actions.newcustomer.heading" />
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            <a id="intercomContact" className="text-primary hover:text-primary/90 transition-colors cursor-pointer">
              <Trans i18nKey="dashboard.user.homepage.actions.newcustomer.caption.action" />
            </a>
            <Trans i18nKey="dashboard.user.homepage.actions.newcustomer.caption.text" />
          </p>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 grid-rows-[1fr]">
        {filteredSubscriptions.map((item) => (
          <SubscriptionCard key={item._id} sub={item} />
        ))}
      </div>
    </div>
  );
}

export default SubscriptionsList;

import Intercom from "@intercom/messenger-js-sdk";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { ThemeProvider } from "./components/theme-provider";
import { AuthProvider } from "./context/user-provider";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";

Intercom({
  app_id: "vpk9gshe",
});

const tagManagerArgs = {
  gtmId: "GTM-PCR9FJJW",
};
TagManager.initialize(tagManagerArgs);

// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="*" element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

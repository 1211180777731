import React, { useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { cn } from "../../../../lib/utils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "../../../ui/alert-dialog";
import { buttonVariants } from "../../../ui/button";
import { toast } from "../../../ui/use-toast";

function DeleteInvoiceModal({ invoice, open, onOpenChange, callbackFn }) {
  const [loading, setLoading] = useState(false);

  const api = useAxiosPrivate();

  const submit = () => {
    setLoading(true);
    api
      .delete(`/admin/invoices/${invoice?._id}`)
      .then(() => {
        toast({
          title: "Invoice deleted successfully.",
          variant: "positive",
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        callbackFn();
      });
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Delete invoice &quot;
              {invoice._id}
              &quot;?
            </AlertDialogTitle>
            <AlertDialogDescription className="text-muted-foreground">
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              disabled={loading}
              onClick={submit}
              className={cn(buttonVariants({ variant: "confirm" }))}
            >
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}

export default DeleteInvoiceModal;

"use client";
import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import classNames from "classnames";
import { Button } from "../ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

export function GeotargetingCountryCombobox({ items, value, onValueChange }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="link" role="combobox" aria-expanded={open}>
          {value === "ANY" ? "Random" : items.find((item) => item.code === value)?.name}

          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search" />
          <CommandList>
            <CommandEmpty>No country found.</CommandEmpty>
            <CommandItem
              key="ANY"
              value="ANY"
              onSelect={(currentValue) => {
                onValueChange(currentValue);
                setOpen(false);
              }}
            >
              <Check className={classNames("mr-2 h-4 w-4", value === "ANY" ? "opacity-100" : "opacity-0")} />
              Random (Worldwide)
            </CommandItem>
            <CommandGroup>
              {items.map((item) => (
                <CommandItem
                  key={item.code}
                  value={item.code}
                  keywords={[item.name]}
                  onSelect={(currentValue) => {
                    onValueChange(currentValue === value ? "ANY" : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check className={classNames("mr-2 h-4 w-4", value === item.code ? "opacity-100" : "opacity-0")} />
                  {item.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export function GeotargetingStateCombobox({ items, value, onValueChange }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="link" role="combobox" aria-expanded={open}>
          {value ? items.find((item) => item.state === value)?.state : "Any"}

          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search" />
          <CommandList>
            <CommandEmpty>No country found.</CommandEmpty>
            <CommandGroup>
              {items.map((item) => (
                <CommandItem
                  key={item.state}
                  value={item.state}
                  keywords={[item.state]}
                  onSelect={(currentValue) => {
                    onValueChange(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check className={classNames("mr-2 h-4 w-4", value === item.state ? "opacity-100" : "opacity-0")} />
                  {item.state}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export function GeotargetingCityCombobox({ items, value, onValueChange }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="link" role="combobox" aria-expanded={open}>
          {value ? items.find((item) => item.city === value)?.city : "Any"}

          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search" />
          <CommandList>
            <CommandEmpty>No country found.</CommandEmpty>
            <CommandGroup>
              {items.map((item) => (
                <CommandItem
                  key={item.city}
                  value={item.city}
                  keywords={[item.city]}
                  onSelect={(currentValue) => {
                    onValueChange(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check className={classNames("mr-2 h-4 w-4", value === item.city ? "opacity-100" : "opacity-0")} />
                  {item.city}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useAuth from "../../../../hooks/useAuth";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { Alert, AlertDescription, AlertTitle } from "../../../ui/alert";
import { Button } from "../../../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../ui/form";
import Input from "../../../ui/input";
import { toast } from "../../../ui/use-toast";

const SidebarBannerEditor = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const form = useForm({
    defaultValues: data,
  });

  const api = useAxiosPrivate();

  const onSubmit = (values) => {
    setIsLoading(false);
    api
      .patch("/admin/settings/sidebar-banner", values)
      .then(() => {
        setSuccess("Settings saved with success");
        toast({ title: "Settings saved with success" });
      })
      .catch((err) => {
        setError(err.response?.data?.message ? err.response.data.message : "Server Error while saving settings");
        console.error(err);
        toast({
          title: "Error while saving settings",
          ...(err.response?.data?.message && { description: err.response.data.message }),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Form {...form}>
      <form disabled={isLoading} onSubmit={form.handleSubmit(onSubmit)} className="space-y-3 max-w-sm">
        {error || success ? (
          <Alert variant={error ? "error" : "success"}>
            <AlertTitle>{error ? "Error" : "Success"}</AlertTitle>
            <AlertDescription>{error || success}</AlertDescription>
          </Alert>
        ) : null}
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder="Title" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Input placeholder="Description" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">Save</Button>
      </form>
    </Form>
  );
};

const SidebarBannerSettings = () => {
  const { auth } = useAuth();

  const { sidebarBanner } = auth;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Sidebar Banner</CardTitle>
      </CardHeader>
      <CardContent>
        <SidebarBannerEditor data={sidebarBanner} />
      </CardContent>
    </Card>
  );
};

export default SidebarBannerSettings;

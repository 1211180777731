import { update } from "@intercom/messenger-js-sdk";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Turnstile, { useTurnstile } from "react-turnstile";
import Logo from "../../assets/images/logo_v2.svg";
import { Button, buttonVariants } from "../../components/ui/button";
import Icon from "../../components/ui/icon";
import Input from "../../components/ui/input";
import Label from "../../components/ui/label";
import { toast } from "../../components/ui/use-toast";
import axios from "../../config/axios";
import useAuth from "../../hooks/useAuth";
import { cn } from "../../lib/utils";

function UserAuthForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();
  const turnstile = useTurnstile();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm();

  const { setAuth } = useAuth();

  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    setIsLoading(true);

    const api = axios;

    try {
      const res = await api.post("/auth/login", {
        ...formData,
        captchaToken,
      });

      if (res.data.message === "Logged in Successfully" && res.data.payload.accessToken !== "FAGGOT") {
        localStorage.setItem("accessToken", res.data.payload.accessToken);
        setAuth({ user: res.data.payload.user, accessToken: res.data.payload.accessToken });

        // Push successful login event to GTM data layer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "login_success",
          user_id: res.data.payload.user.id,
        });

        navigate("/");
      } else if (res.data.message === "2FA is enabled") {
        // Push 2FA event to GTM data layer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "2fa_redirect",
          user_id: res.data.payload.user.id,
          twoFactorToken: res.data.payload.twoFactorToken,
        });

        navigate(`/auth/2fa/${res.data.payload.twoFactorToken}`);
      }
    } catch (err) {
      // Handle error and push error event to GTM data layer
      if (err.response) {
        toast({ title: err.response?.data.message, variant: "destructive" });

        // Push login error to GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "login_error",
          error_message: err.response.data.message,
        });
      } else {
        toast({ title: "We couldn't connect to the server. Try again later.", variant: "destructive" });

        // Push general server error to GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "server_connection_error",
        });
      }
    } finally {
      turnstile.reset();
      setIsLoading(false);
    }
  };

  return (
    <div className="grid gap-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-3">
          <div className="grid gap-2">
            <Label htmlFor="email">
              <Trans i18nKey="auth.login.label" />
            </Label>
            <Input
              {...register("username", { required: true })}
              placeholder="name@example.com"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
            />
          </div>
          <div className="grid gap-2">
            <div className="flex items-center justify-between">
              <Label htmlFor="password">
                <Trans i18nKey="auth.password.label" />
              </Label>
              <Link className={cn(buttonVariants({ variant: "link" }))} to="/auth/reset">
                Forgot?
              </Link>
            </div>

            <Input {...register("password", { required: true })} type="password" disabled={isLoading} />
          </div>
          <Turnstile
            className="animate-in fade-in"
            autoResetOnExpire
            fixedSize
            sitekey={process.env.REACT_APP_CLOUDFLARE_CAPTCHA_KEY}
            onVerify={(token) => {
              setCaptchaToken(token);
            }}
          />
          <Button disabled={isLoading || !captchaToken || !isValid}>
            {isLoading && <Icon name="spinner" className="mr-2 h-4 w-4 animate-spin" />}
            <Trans i18nKey="auth.login.action" />
          </Button>
        </div>
      </form>
      <p className="text-sm text-muted-foreground">
        <Trans i18nKey="auth.login.footer.caption" />{" "}
        <Link to="/auth/register" className="font-medium text-primary hover:text-primary/95 transition-colors">
          <Trans i18nKey="auth.login.footer.action" />
        </Link>
      </p>
    </div>
  );
}

function Login() {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    update({
      hide_default_launcher: true,
    });
  }, []);

  return (
    <div className="container relative flex-1 flex-col items-center justify-center lg:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <Link
        to="/auth/register"
        className={classNames(
          buttonVariants({ variant: "ghost" }),
          "hidden lg:inline-block absolute right-4 top-4 md:right-8 md:top-8",
        )}
      >
        Sign up
      </Link>
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
        <div className="absolute inset-0" />
        <div className="relative z-20 flex items-center text-lg font-medium">
          <Link to="/" className="hidden md:flex justify-center gap-1 items-center border-bottom border-white p-4">
            <img src={Logo} className="object-contain h-7" alt="" />
            <span className="leading-6 text-foreground tracking-tight font-semibold text-2xl">thunderproxy</span>
          </Link>
        </div>
        <div className="my-auto">
          <h2 className="text-xl font-semibold">Login to your account to access and manage our products.</h2>
        </div>
      </div>
      <div className="h-screen grid place-items-center lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <div className="flex lg:hidden justify-center gap-1 items-center border-bottom border-white p-4">
              <img src={Logo} className="object-contain h-7" alt="" />
              <span className="leading-6 text-foreground tracking-tight font-semibold text-2xl">thunderproxy</span>
            </div>
            <h1 className="text-2xl font-semibold tracking-tight">
              <Trans i18nKey="auth.login.heading" />
            </h1>
          </div>
          <UserAuthForm />
        </div>
      </div>
    </div>
  );
}
export default Login;

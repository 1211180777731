import React from "react";
import { Link } from "react-router-dom";
import { Button } from "./button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./card";

const SidebarBanner = ({ title, description, href }) => {
  return (
    <div className="p-2">
      <Card className="w-full shadow-none">
        <CardHeader className="p-4 pb-0">
          <CardTitle className="text-sm">{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardHeader>
        {href ? (
          <CardContent className="p-4">
            (
            <Link to={href} className="w-full">
              <Button className="w-full shadow-none" size="sm">
                View
              </Button>
            </Link>
            )
          </CardContent>
        ) : (
          <CardFooter />
        )}
      </Card>
    </div>
  );
};

export default SidebarBanner;

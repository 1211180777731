import { useParams } from "react-router-dom";
import { SubscriptionProvider } from "../../../../components/ui/subscription-provider";
import { SubscriptionDetails } from "../../../../components/subscription/subscription-details";

function UserSubscriptionPage() {
  const { id } = useParams();

  return (
    <SubscriptionProvider subscriptionId={id}>
      <SubscriptionDetails />
    </SubscriptionProvider>
  );
}

export default UserSubscriptionPage;

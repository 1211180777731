import { Trans, useTranslation } from "react-i18next";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";

function HelpCenter() {
  const { t } = useTranslation();

  return (
    <div className="max-w-7xl space-y-6">
      <div>
        <div className="mx-auto max-w-7xl py-16">
          <div className="text-center">
            <p className="mt-1 text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
              <Trans i18nKey="support.heading" />
            </p>
            <p className="mx-auto mt-5 max-w-xl text-xl text-gray-500">
              <Trans i18nKey="support.caption" />
            </p>
          </div>
        </div>
      </div>
      <div className="py-16">
        <h2 className="text-xl lg:text-2xl font-bold text-center">
          <Trans i18nKey="support.contact.heading" />
        </h2>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div className="flex flex-col justify-between ring-1 rounded-3xl p-8 xl:p-10">
            <div>
              <div className="flex items-center justify-between gap-x-4">
                <h2 className="text-lg font-semibold leading-8">
                  <Trans i18nKey="support.contact.options.email.time" />
                </h2>
              </div>
              <p className="mt-4 text-sm leading-6 text-muted-foreground">
                <Trans i18nKey="support.contact.options.email.description" />
              </p>
            </div>
            <div>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight">
                  <Trans i18nKey="support.contact.options.email.heading" />
                </span>
              </p>
              <a
                href="mailto:help@thunderproxy.com"
                className="cursor-pointer bg-white/10 hover:bg-white/20 focus-visible:outline-white mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                <Trans i18nKey="support.contact.options.email.actionLabel" />
              </a>
            </div>
          </div>
          <div className="flex flex-col justify-between  ring-1 rounded-3xl p-8 xl:p-10">
            <div>
              <div className="flex items-center justify-between gap-x-4">
                <h2 className="text-lg font-semibold leading-8">
                  <Trans i18nKey="support.contact.options.discord.time" />
                </h2>
              </div>
              <p className="mt-4 text-sm leading-6 text-muted-foreground">
                <Trans i18nKey="support.contact.options.discord.description" />
              </p>
            </div>
            <div>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight">
                  <Trans i18nKey="support.contact.options.discord.heading" />
                </span>
              </p>
              <a
                href="https://discord.com/invite/cuDrwQAVY9"
                rel="noopener noreferrer"
                target="_blank"
                className="cursor-pointer bg-white/10 hover:bg-white/20 focus-visible:outline-white mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                <Trans i18nKey="support.contact.options.discord.actionLabel" />
              </a>
            </div>
          </div>
          <div className="flex flex-col justify-between ring-1 rounded-3xl p-8 xl:p-10">
            <div>
              <div className="flex items-center justify-between gap-x-4">
                <h2 className="text-lg font-semibold leading-8">
                  <Trans i18nKey="support.contact.options.intercom.time" />
                </h2>
              </div>
              <p className="mt-4 text-sm leading-6 text-muted-foreground">
                <Trans i18nKey="support.contact.options.intercom.description" />
              </p>
            </div>
            <div>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight">
                  <Trans i18nKey="support.contact.options.intercom.heading" />
                </span>
              </p>
              <a
                id="intercomContact"
                className="cursor-pointer bg-white/10 hover:bg-white/20 focus-visible:outline-white mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                <Trans i18nKey="support.contact.options.intercom.actionLabel" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <h2 className="font-medium text-2xl text-blue-500 text-center">
        <Trans i18nKey="support.faq.heading" />
      </h2>
      <div>
        <Accordion type="single" defaultValue="item-0" collapsible>
          {t("support.faq.items", { returnObjects: true }).map((item, index) => (
            <AccordionItem key={item} value={`item-${index}`}>
              <AccordionTrigger className="flex flex-1 font-medium flex-row justify-between items-center">
                {item.question}
              </AccordionTrigger>
              <AccordionContent className="flex text-muted-foreground">{item.answer}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default HelpCenter;

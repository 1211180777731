import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import classNames from "classnames";
import React from "react";

const DrawerDialog = AlertDialogPrimitive.Root;

const DrawerDialogTrigger = AlertDialogPrimitive.Trigger;

const DrawerDialogPortal = React.forwardRef(({ className, children, ...props }, forwardedRef) => (
  <AlertDialogPrimitive.Portal className={className} ref={forwardedRef} {...props}>
    <div className="overflow-y-auto fixed top-0 left-0 z-50 w-full h-screen">{children}</div>
  </AlertDialogPrimitive.Portal>
));

const DrawerDialogOverlay = React.forwardRef(({ className, children, ...props }, forwardedRef) => (
  <AlertDialogPrimitive.Overlay
    className={classNames("fixed inset-0 z-40 bg-background/25 backdrop-blur-sm", className)}
    ref={forwardedRef}
    {...props}
  >
    {children}
  </AlertDialogPrimitive.Overlay>
));

const DrawerDialogContent = React.forwardRef(({ className, children, ...props }, forwardedRef) => (
  <AlertDialogPrimitive.Content
    className={classNames(
      "overflow-y-auto fixed top-0 right-0 z-40 p-4 w-full max-w-xs h-screen bg-popover text-popover-foreground border",
      className,
    )}
    ref={forwardedRef}
    {...props}
  >
    {children}
  </AlertDialogPrimitive.Content>
));

const DrawerDialogHeader = React.forwardRef(({ className, children, ...props }, forwardedRef) => (
  <div
    className={classNames("flex justify-between items-center space-y-2 text-center sm:text-left", className)}
    ref={forwardedRef}
    {...props}
  >
    {children}
  </div>
));

const DrawerDialogFooter = React.forwardRef(({ className, children, ...props }, forwardedRef) => (
  <div
    className={classNames("flex flex-col-reverse sm:flex-row sm:justify-end gap-2", className)}
    ref={forwardedRef}
    {...props}
  >
    {children}
  </div>
));

const DrawerDialogTitle = React.forwardRef(({ className, children, ...props }, forwardedRef) => (
  <AlertDialogPrimitive.Title className={classNames("text-lg font-semibold", className)} ref={forwardedRef} {...props}>
    {children}
  </AlertDialogPrimitive.Title>
));

const DrawerDialogDescription = React.forwardRef(({ className, children, ...props }, forwardedRef) => (
  <AlertDialogPrimitive.Description className={classNames("text-sm", className)} ref={forwardedRef} {...props}>
    {children}
  </AlertDialogPrimitive.Description>
));

const DrawerDialogAction = React.forwardRef(({ className, children, ...props }, forwardedRef) => (
  <AlertDialogPrimitive.Cancel className={className} ref={forwardedRef} {...props}>
    {children}
  </AlertDialogPrimitive.Cancel>
));

const DrawerDialogCancel = React.forwardRef(({ className, children, ...props }, forwardedRef) => (
  <AlertDialogPrimitive.Cancel className={className} ref={forwardedRef} {...props}>
    {children}
  </AlertDialogPrimitive.Cancel>
));

DrawerDialog.displayName = "DrawerDialog";
DrawerDialogTrigger.displayName = "DrawerDialogTrigger";
DrawerDialogPortal.displayName = "DrawerDialogPortal";
DrawerDialogOverlay.displayName = "DrawerDialogOverlay";
DrawerDialogContent.displayName = "DrawerDialogContent";
DrawerDialogHeader.displayName = "DrawerDialogHeader";
DrawerDialogFooter.displayName = "DrawerDialoFooter";
DrawerDialogTitle.displayName = "DrawerDialTitle";
DrawerDialogDescription.displayName = "DrawerDialDescription";
DrawerDialogAction.displayName = "DrawerAction";
DrawerDialogCancel.displayName = "DrawerCancel";

export {
  DrawerDialog,
  DrawerDialogTrigger,
  DrawerDialogPortal,
  DrawerDialogOverlay,
  DrawerDialogContent,
  DrawerDialogHeader,
  DrawerDialogFooter,
  DrawerDialogTitle,
  DrawerDialogDescription,
  DrawerDialogAction,
  DrawerDialogCancel,
};

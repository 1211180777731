import { format, formatDistanceToNow, isFuture } from "date-fns";
import { ArrowRight, Clipboard, Home, MoreHorizontal, RefreshCcw, Server } from "lucide-react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { cn, formatBytes } from "../../lib/utils";
import { buttonVariants } from "./button";
import { Card, CardContent, CardFooter, CardTitle } from "./card";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./dropdown-menu";
import Progress from "./progress";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

function SubscriptionCardActions({ sub }) {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button type="button" className="px-1.5 py-0.5 rounded focus:outline-none hover:bg-muted">
            <MoreHorizontal className="w-3 h-3" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" side="right">
          <DropdownMenuItem onClick={() => navigator.clipboard.writeText(sub._id)} className="leading-none">
            <Clipboard className="w-3 h-3 mr-2" />
            Copy ID
          </DropdownMenuItem>
          <DropdownMenuItem className={cn(sub.isAutoTopUp && "text-destructive", "leading-none")}>
            <RefreshCcw className={cn("w-3 h-3 mr-2", sub.isAutoTopUp && "text-green-600")} />
            {sub.isAutoTopUp ? "Disable" : "Enable"} auto renewal
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}

function SubscriptionCard({ sub, noMotion = false }) {
  let percentUsage;
  if (sub.type === "gb") {
    percentUsage = ((100 * sub.bandwidthLeft) / sub.quantity).toFixed(0);
  }

  const now = new Date();
  const expirationDate = new Date(sub.renewalDate);
  const diffTime = Math.abs(expirationDate - now);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const isExpiringSoon = diffDays <= 7 && isFuture(expirationDate) && !sub.deleted;

  const expirationLabel = isExpiringSoon
    ? formatDistanceToNow(new Date(expirationDate), { addSuffix: true })
    : format(expirationDate, "dd MMM yyyy");

  const isRunningOutOfBandwidth = sub.type === "gb" && !sub.deleted && percentUsage <= 10;

  return (
    <Card className={cn("ring-primary ease-in-out duration-200", !noMotion && "hover:scale-105 ")}>
      <div className="p-3">
        <div className="flex items-center justify-between">
          <Link
            to={`/subscriptions/${sub._id}`}
            className="rounded p-3 hover:bg-muted hover:text-primary flex items-center transition-colors truncate"
          >
            <Tooltip>
              <TooltipTrigger>
                <div
                  className={cn(
                    "w-3 h-3 rounded-full mr-2",
                    sub.deleted
                      ? "bg-muted/75"
                      : isExpiringSoon || isRunningOutOfBandwidth
                        ? "bg-yellow-600"
                        : "bg-green-600",
                  )}
                ></div>
              </TooltipTrigger>
              <TooltipContent>
                {sub.deleted
                  ? "This subscription is expired"
                  : `This subscription is active${isExpiringSoon ? " and expiring soon." : ""}`}
              </TooltipContent>
            </Tooltip>
            <div>
              {sub.planType === "residential" && <Home className="w-5 h-5 text-muted-foreground mr-2" />}
              {sub.planType === "datacenter" && <Server className="w-5 h-5 text-muted-foreground mr-2" />}
              {sub.planType === "datacenter_premium" && <Server className="w-5 h-5 text-yellow-600/50 mr-2" />}
              {sub.type === "ip" && <Server className="w-5 h-5 text-muted-foreground mr-2" />}
            </div>
            <CardTitle className="truncate flex-shrink">{sub.friendlyName ?? sub.planType.toUpperCase()}</CardTitle>
          </Link>
          <SubscriptionCardActions sub={sub} />
        </div>
      </div>

      <CardContent>
        <div className="space-y-1">
          <div className="flex justify-between items-center">
            <div className="text-sm text-muted-foreground">Type</div>
            <div className="text-sm font-medium text-muted-foreground">
              {sub.planType === "residential" && "Residential"}
              {sub.planType === "datacenter" && "Datacenter"}
              {sub.planType === "datacenter_premium" && "Datacenter Premium"}
              {sub.type === "ip" && "ISP"} ({sub.type === "ip" && "Static"}
              {sub.type === "gb" && "Rotating"})
            </div>
          </div>
          {sub.type === "gb" && (
            <div className="flex justify-between items-center">
              <div className="text-sm text-muted-foreground">Bandwidth left</div>
              <div
                className={cn(
                  "text-sm font-medium text-muted-foreground",
                  isRunningOutOfBandwidth && "text-yellow-600",
                )}
              >
                {formatBytes(sub.bandwidthLeft)}/{formatBytes(sub.quantity)}
              </div>
            </div>
          )}
          {sub.type === "ip" && (
            <div className="flex justify-between items-center">
              <div className="text-sm text-muted-foreground">IPs</div>
              <div className="text-sm font-medium text-muted-foreground">{sub.quantity} IPs</div>
            </div>
          )}
          {sub.type === "ip" && (
            <div className="flex justify-between">
              <div className="text-sm text-muted-foreground">Country</div>
              <div className="text-sm font-medium text-muted-foreground">
                {sub.externalProviderCountry ? sub.externalProviderCountry.displayName : "N/A"}
              </div>
            </div>
          )}
          {sub.type === "gb" && (
            <div className="py-1.5">
              <Progress value={percentUsage} />
            </div>
          )}
          <div className="flex justify-between">
            <div className="text-sm text-muted-foreground">
              <Trans i18nKey="dashboard.user.homepage.subscriptions.card.expiry" />
            </div>
            <div className={cn("text-sm font-medium text-muted-foreground", isExpiringSoon && "text-yellow-600")}>
              {expirationLabel}
            </div>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <div className="flex-1">
          <Link
            to={`/subscriptions/${sub._id}`}
            className={cn(buttonVariants({ variant: "outline", size: "sm" }), "w-full")}
          >
            View
            {sub.deleted ? " & Renew/Extend" : null}
            <ArrowRight className="w-4 h-4 ml-2" />
          </Link>
        </div>
      </CardFooter>
    </Card>
  );
}

SubscriptionCard.Skeleton = function SubscriptionCardSkeleton() {
  return (
    <div className="col-span-1 flex flex-col justify-between rounded-lg bg-popover p-4 gap-y-2">
      <div className="text-lg font-medium py-0.5">
        <div className="h-5 bg-gray-200 rounded dark:bg-gray-700 w-1/4 animate-pulse" />
      </div>
      <div className="flex justify-between">
        <div className="text-sm text-muted-foreground">Proxy type</div>
        <div className="text-sm font-medium text-muted-foreground">
          <div className="h-3.5 bg-gray-200 rounded dark:bg-gray-700 w-12 animate-pulse" />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-sm text-muted-foreground">Usage</div>
        <div className="inline-flex items-center text-sm font-medium text-muted-foreground">
          <div className="h-3.5 bg-gray-200 rounded dark:bg-gray-700 w-12 animate-pulse" />
        </div>
      </div>
      <div className="bg-gray-700 rounded-full h-2.5">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4" />
      </div>
      <div className="flex justify-between">
        <div className="text-sm text-muted-foreground">Expiration</div>
        <div className="text-sm text-muted-foreground">
          <div className="h-3.5 bg-gray-200 rounded dark:bg-gray-700 w-12 animate-pulse" />
        </div>
      </div>
      <button
        type="button"
        className="inline-flex items-center justify-center rounded font-medium border border-slate-700 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background text-white hover:bg-slate-700 px-4 py-2 gap-2 w-full"
      >
        ...
      </button>
    </div>
  );
};

SubscriptionCard.EmptyState = function SubscriptionCardSkeleton() {
  return (
    <div className="flex flex-col justify-between items-center rounded bg-popover p-4 gap-y-2">
      <div>Add a new plan</div>
    </div>
  );
};

export default SubscriptionCard;

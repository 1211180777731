import React from "react";
import { Link } from "react-router-dom";

function PaymentFailed() {
  return (
    <div className="flex justify-center items-center">
      <div className="text-center space-y-3 py-32">
        <h2 className="text-2xl font-medium">Your payment didn&apos;t go through.</h2>
        <div>You were not charged for this transaction.</div>
        <div className="flex items-center justify-center gap-x-6">
          <Link
            to="/"
            className="rounded-lg bg-blue-700 px-3.5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 transition-colors"
          >
            Go back home
          </Link>
          <Link to="/help" className="text-sm font-semibold text-muted-foreground hover:text-white transition-colors">
            Contact support <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailed;

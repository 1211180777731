/* eslint-disable no-undef */
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

export const ThemeContext = createContext({
  theme: "dark",
  toggleTheme: () => console.warn("no theme provider"),
});

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = useCallback(() => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);

    // Push the new theme to GTM data layer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "theme_change",
      appearance: newTheme,
    });

    // Persist theme in local storage
    window.localStorage.setItem("theme", newTheme);
  }, [theme]);

  useEffect(() => {
    // Set theme from local storage if available
    const localTheme = window.localStorage.getItem("theme");
    if (localTheme) setTheme(localTheme);
  }, []);

  useEffect(() => {
    // Apply theme to the document's root element
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
}

export const useTheme = () => useContext(ThemeContext);

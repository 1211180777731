import { useContext } from "react";
import useAuth from "../../hooks/useAuth";
import { CardDescription, CardTitle } from "../ui/card";
import CopyOnClickInput from "../ui/copy-on-click-input";
import { SubscriptionContext } from "../ui/subscription-provider";
import { FormatConfiguratorView } from "./format-configurator-view";
import { GeotargetingConfiguratorView } from "./geotargeting-configurator-view";
import { RotationConfiguratorView } from "./rotation-configurator-view";
import { CopyButton } from "../ui/copy-button";

const translateFormatFromOldToNew = {
  format1: "host:port:user:pass",
  format2: "user:pass@host:port",
};

const translateFormatFromNewToOld = {
  "host:port:user:pass": "format1",
  "user:pass@host:port": "format2",
};

function SubscriptionExportApiView() {
  const { subscription, updateSubConfig } = useContext(SubscriptionContext);
  const { subInfo, subConfig, geoTargeting } = subscription;

  const { auth } = useAuth();
  const { user } = auth;

  const baseApiLink = `${process.env.REACT_APP_BASE_API_URL}/public/subscriptions/export/${subInfo._id}/txt?apiKey=${user.subscriptionApiKey}`;

  return (
    <div className="space-y-3">
      <div className="space-y-2">
        <CardTitle>API</CardTitle>
        <CardDescription>Paste this link in your software to load proxies from our API.</CardDescription>
      </div>
      {geoTargeting && <GeotargetingConfiguratorView value={subConfig} onValueChange={updateSubConfig} />}
      {!!subConfig.rotation && <RotationConfiguratorView value={subConfig} onValueChange={updateSubConfig} forList />}
      <FormatConfiguratorView
        format={translateFormatFromOldToNew[subConfig.proxyFormat]}
        onValueChange={(value) => updateSubConfig({ proxyFormat: translateFormatFromNewToOld[value] })}
      />
      <div className="space-y-2">
        <div className="text-lg font-semibold">API Link</div>
        <div className="flex items-center gap-1">
          <CopyOnClickInput className="w-full" value={baseApiLink} />
          <CopyButton value={baseApiLink} />
        </div>
      </div>
      <div className="text-xs text-center text-muted-foreground">
        Your changes are automatically synchronized with our servers every few moments.
      </div>
    </div>
  );
}

export default SubscriptionExportApiView;

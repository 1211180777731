import classNames from "classnames";
import { format } from "date-fns";
import { Check, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../../components/ui/alert-dialog";
import { buttonVariants } from "../../../../components/ui/button";
import DataTable from "../../../../components/ui/data-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import Icon from "../../../../components/ui/icon";
import LoadingState from "../../../../components/ui/loading-state";
import DashboardHeader from "../../../../components/ui/page-header";
import { toast } from "../../../../components/ui/use-toast";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { formatCurrency } from "../../../../utils/Utils";

function AdminCancelReferralPayoutModal({ open, onOpenChange, data, callbackFn }) {
  const api = useAxiosPrivate();

  const onSubmit = () => {
    api
      .post(`/admin/campaigns/payouts/${data._id}/cancel`)
      .then(() => {
        callbackFn();
      })
      .catch((err) => {
        toast({
          title: "Could not cancel the payout.",
          description: "More information in the console",
        });
        console.error(err);
      })
      .finally(() => {});
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Cancel payout for {data.campaign.name}</AlertDialogTitle>
          <AlertDialogDescription className="text-muted-foreground">
            <p>This will enable the user to request a new payout.</p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => onSubmit()} className={buttonVariants({ variant: "confirm" })}>
            Confirm
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function AdminAcceptReferralPayoutModal({ open, onOpenChange, data, callbackFn }) {
  const api = useAxiosPrivate();

  const onSubmit = () => {
    api
      .post(`/admin/campaigns/payouts/${data._id}/process`)
      .then(() => {
        callbackFn();
      })
      .catch((err) => {
        toast({
          title: "Could not process the payout.",
          description: "More information in the console",
        });
        console.error(err);
      })
      .finally(() => {});
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Process payout for {data.campaign.name}</AlertDialogTitle>
          <AlertDialogDescription className="text-muted-foreground">
            <p>This will mark as &quot;paid out&quot; the following commissions:</p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => onSubmit()} className={buttonVariants({ variant: "confirm" })}>
            Confirm
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function AdminReferralPayoutActions({ data, callbackFn }) {
  const [open, setOpen] = useState(false);
  const [showAdminAcceptReferralPayoutModal, setShowAdminAcceptReferralPayoutModal] = useState(false);
  const [showAdminCancelReferralPayoutModal, setShowAdminCancelReferralPayoutModal] = useState(false);

  // eslint-disable-next-line no-undef
  const touchDevice = "ontouchstart" in window;

  return (
    <>
      <DropdownMenu open={open} modal={false} onOpenChange={setOpen}>
        <DropdownMenuTrigger
          className="flex h-8 w-8 items-center justify-center rounded-md border transition-colors hover:bg-muted"
          {...(touchDevice
            ? {
                onPointerDown: (e) => e.preventDefault(),
                onClick: () => setOpen(!open),
              }
            : undefined)}
        >
          <div>
            <Icon name="MoreVertical" className="w-3 h-3" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => setShowAdminAcceptReferralPayoutModal(true)}>
            <Check className="w-4 h-4 mr-2" />
            Accept
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setShowAdminCancelReferralPayoutModal(true)}>
            <X className="w-4 h-4 mr-2" />
            Cancel payout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      {showAdminAcceptReferralPayoutModal && (
        <AdminAcceptReferralPayoutModal
          open={showAdminAcceptReferralPayoutModal}
          onOpenChange={setShowAdminAcceptReferralPayoutModal}
          data={data}
          callbackFn={callbackFn}
        />
      )}
      {showAdminCancelReferralPayoutModal && (
        <AdminCancelReferralPayoutModal
          open={showAdminCancelReferralPayoutModal}
          onOpenChange={setShowAdminCancelReferralPayoutModal}
          data={data}
          callbackFn={callbackFn}
        />
      )}
    </>
  );
}

const createAdminReferralPayoutsQueueDesktopCols = (callbackFn) => [
  {
    accessorKey: "createdAt",
    header: "Created at",
    cell: ({ row }) => <span>{format(new Date(row.original.createdAt), "pppp")}</span>,
  },
  {
    accessorKey: "user._id",
    header: "User",
    cell: ({ row }) => (
      <div>
        <Link
          to={`/users/${row.original.user._id}`}
          className={classNames(buttonVariants({ variant: "link" }), "px-0")}
        >
          {row.original.user.username}
        </Link>
      </div>
    ),
  },
  {
    accessorKey: "amount",
    header: "Amount",
    cell: ({ row }) => <span>{formatCurrency.format(row.original.amount)}</span>,
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const item = row.original;
      return (
        <div className="flex w-full justify-end">
          <AdminReferralPayoutActions data={item} callbackFn={callbackFn} />
        </div>
      );
    },
  },
];

function AdminReferralPayoutsQueuePageView({ data, callbackFn }) {
  const desktopCols = createAdminReferralPayoutsQueueDesktopCols(callbackFn);

  return (
    <div className="space-y-6">
      <DashboardHeader heading="Payouts queue" />
      <DataTable columns={desktopCols} data={data} />
    </div>
  );
}

function AdminReferralPayoutsQueuePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const api = useAxiosPrivate();

  const fetchData = () => {
    setIsLoading(true);
    api
      .get("/admin/campaigns/payouts/queue")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .then(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <LoadingState />;
  }

  return data ? <AdminReferralPayoutsQueuePageView data={data} callbackFn={fetchData} /> : <div>Error</div>;
}

export default AdminReferralPayoutsQueuePage;
